<script>
  import Icon from "components/elements/Icon";

  export let displayThresholdPx = 600;
  export let onAfterScroll = null;
  let hidden = true;

  const scrollToTop = () => {
    document.body.scrollIntoView();
    onAfterScroll?.();
  };

  const getScrollContainer = () => document.documentElement || document.body;

  const handleOnScroll = () => {
    hidden =
      !getScrollContainer() ||
      getScrollContainer().scrollTop <= displayThresholdPx;
  };
</script>

<svelte:window on:scroll={handleOnScroll} />

<div class="back-to-top" on:click={scrollToTop} class:hidden>
  <Icon name="chevron-up" colorName="white" />
</div>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .back-to-top {
    opacity: 1;
    transition: opacity 1s, visibility 0.5s;
    position: fixed;
    padding: 14px 16px 10px;
    border-radius: 50%;
    z-index: 99;
    right: 24px;
    bottom: 24px;
    color: $white;
    background-color: $I70;
    cursor: pointer;
  }

  .back-to-top.hidden {
    opacity: 0;
    visibility: hidden;
  }
</style>
