<script>
  import { onMount, tick } from "svelte";
  import Nav from "components/Nav.svelte";
  import Footer from "components/widgets/Footer.svelte";
  export let dark = false;
  export let paddedHeader = false;

  onMount(async () => {
    // Finish rendering
    await tick();
    // Scroll to the top
    window.scrollTo(0, 0);
  });
</script>

<Nav {dark} {paddedHeader} />
<div class="page-content">
  <slot />
</div>
<Footer />
