<script>
  import {
    THEME_LIGHT,
    THEME_DARK,
    COLOR_PRIMARY,
    BUTTON_COLORS,
    BUTTON_THEMES,
    BUTTON_STATES,
    LINK_SIZES,
    STATE_ACTIVE,
    STATE_DISABLED,
    SIZE_SMALL,
    SIZE_MEDIUM,
    SIZE_LARGE,
  } from "buttonStyles";
  import Anchor from "components/elements/Anchor.svelte";

  // Props
  export let dark = false;
  export let color = COLOR_PRIMARY;
  export let small = false;
  export let large = false;
  export let active = false;
  export let disabled = false;
  export let href = undefined;
  export let scrollTop = false;
  export let onClick = undefined;

  let theme = dark ? THEME_DARK : THEME_LIGHT;
  let size = SIZE_MEDIUM;
  if (large) {
    size = SIZE_LARGE;
  } else if (small) {
    size = SIZE_SMALL;
  }

  const colorMeta = BUTTON_COLORS[color];
  const themeMeta = BUTTON_THEMES[theme];
  const sizeMeta = LINK_SIZES[size];
  let stateMeta = null;

  if (active) {
    stateMeta = BUTTON_STATES[STATE_ACTIVE];
  }
  if (disabled) {
    stateMeta = BUTTON_STATES[STATE_DISABLED];
  }
  let stateClass = stateMeta?.class || "";
</script>

<Anchor {href} {scrollTop} {onClick}>
  <div
    class="link {sizeMeta.class} {themeMeta.class} {colorMeta.class} {stateClass} {$$props.class ||
      ''}"
  >
    <slot />
  </div>
</Anchor>

<style lang="scss">
  @import "../../styles/global.scss";
  .link {
    color: $B65;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;

    &.color-primary {
      color: $B65;
      &.theme-dark {
        color: $B40;
      }
    }
    &.color-secondary {
      color: $N95;
      &.theme-dark {
        color: $B05;
      }
    }
    &.color-subtle {
      color: $N75;
      &.theme-dark {
        color: $N40;
      }
    }
    &.color-attention {
      color: $Y55;
      &.theme-dark {
        color: $Y30;
      }
    }
    &.size-small {
      font-size: 14px;
      line-height: 21px;
    }
    &.size-medium {
      font-size: 16px;
      line-height: 24px;
    }
    &.size-large {
      font-size: 18px;
      line-height: 27px;
    }
    &.color-primary {
      &.active {
        color: $B70;
        &.theme-dark {
          color: $B35;
        }
      }
    }
    &.color-secondary {
      &.active {
        color: $B00;
        &.theme-dark {
          color: $white;
        }
      }
    }
    &.color-subtle {
      &.active {
        color: $N90;
        &.theme-dark {
          color: $N30;
        }
      }
    }
    &.color-attention {
      &.active {
        color: $Y60;
        &.theme-dark {
          color: $Y25;
        }
      }
    }
    &:hover {
      text-decoration: underline;
    }
    &.disabled {
      color: $N45;
      text-decoration: none;
      cursor: default;
      &.theme-dark {
        color: $N60;
      }
    }
  }
</style>
