<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4796_41279)">
    <path
      d="M46.998 24.5364C46.998 37.2388 36.7005 47.5354 23.999 47.5354C11.2966 47.5354 1 37.2388 1 24.5364C1 11.8339 11.2966 1.53735 23.999 1.53735C36.7005 1.53735 46.998 11.8339 46.998 24.5364Z"
      fill="#EEF0F2"
    />
    <path
      d="M36.9987 31.5361L21.9994 37.5358L7 31.5361V13.5368L21.9994 7.53709L36.9987 13.5368V31.5361Z"
      fill="white"
    />
    <path
      d="M62.9973 42.5356L47.9979 48.5354L32.9985 42.5356V24.5364L47.9979 18.5367L62.9973 24.5364V42.5356Z"
      fill="#EEF0F2"
    />
    <path
      d="M39.9982 57.535L24.9989 63.5347L9.99951 57.535V39.5357L24.9989 33.536L39.9982 39.5357V57.535Z"
      fill="#B2D8FA"
    />
    <path
      d="M36.9987 13.5368L21.9994 19.5366L7 13.5368"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9507 34.316L7 31.5361V13.5368L21.9994 7.53709L36.9987 13.5368V18.5366M21.9994 19.5366V30.5361M62.9976 24.5364L47.9983 30.5361L32.9989 24.5364"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9985 32.5361V24.5364L47.9979 18.5367L62.9973 24.5364V42.5356L47.9979 48.5354M47.9979 48.5354L43.0131 46.5415M47.9979 48.5354V30.5362"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39.9982 39.5358L24.9989 45.5355L9.99951 39.5358"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.9989 45.5355V63.5347M39.9982 57.535L24.9989 63.5347L9.99951 57.535V39.5357L24.9989 33.536L39.9982 39.5357V57.535Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_4796_41279">
      <rect
        width="64"
        height="64"
        fill="white"
        transform="translate(0 0.537354)"
      />
    </clipPath>
  </defs>
</svg>
