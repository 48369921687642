<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Icon from "components/elements/Icon";
  import { INSTRUMENTS } from "utils/instruments";
  import { formatDecimal } from "utils/format";

  let openSections = [];
  const toggleOpenSection = (idx) => (openSections[idx] = !openSections[idx]);
</script>

<PageSection long>
  <div class="smart-contracts">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Smart Contracts Pricing</Eyebrow>
        <Heading3
          >Transparent pricing with a trusted infrastructure partner
        </Heading3>
        <div class="pricing-details">
          <div class="pricing-part">
            <span class="subtitle">Issuer operations fee</span>
            <p>
              This flat fee varies by instrument type and covers smart contract
              activation and all supporting administration tools and
              infrastructure provided by the platform.
            </p>
          </div>
          <div class="pricing-part">
            <span class="subtitle">Units outstanding fee</span>
            <p>
              In addition to the flat operations fee, issuers are also charged a
              metered usage fee based on the maximum number of issued
              instruments outstanding each month. For high volume issuers,
              significant discounts apply. Expired and redeemed instruments will
              no longer be subject to the units outstanding fee.
            </p>
          </div>
          <div class="pricing-part">
            <span class="subtitle">Contract action fee</span>
            <p>
              For unparalleled data integrity, all smart contract interactions
              involve the creation of a record on the Bitcoin SV blockchain. For
              each of these actions, a small micropayment fee in BSV is
              required, typically paid for by the initiating party.
            </p>
            <p>
              For example, contract creation and issuance action fees are
              typically paid for by the issuer, whereas the fee for transfers
              and redemptions would be paid by instrument holders. Alternative
              arrangements are also possible, such as passing on the issuance
              action fee to recipients at the point of purchase.
            </p>
          </div>
        </div>
      </div>
      <div class="column left fees">
        <div class="title">
          Fees by instrument type
          <span>Prices in USD</span>
        </div>
        {#each INSTRUMENTS as instrument, idx}
          <div
            class="accordion"
            on:click={() => toggleOpenSection(idx)}
            class:isOpen={openSections[idx]}
          >
            <span class="fee-title"
              ><Icon
                name="chevron-right"
                colorName="N65 "
              />{instrument.label}</span
            >
            {#if !openSections[idx]}
              <span class="fee-price"
                >Starts at ${formatDecimal(instrument.pricing.issuerFee)} / mo.</span
              >{/if}
          </div>
          {#if openSections[idx]}
            <div class="data">
              <div class="instrument-description">
                {instrument.description}
              </div>
              <div class="data-row">
                <span> Issuer operations fee</span>
                <span>
                  ${formatDecimal(instrument.pricing.issuerFee)} / mo.
                </span>
              </div>
              <div class="data-row">
                <span> Units outstanding fee</span>
                <span />
              </div>
              {#each instrument.pricing.unitFees as unit}
                {#if unit.label}
                  <div class="data-subrow">
                    <span> {unit.label}</span>
                    <span>
                      {unit.feeLabel}
                      {instrument.namePlural} / mo.
                    </span>
                  </div>
                {/if}
              {/each}

              <div class="data-row">
                <span> Contract action fee</span>

                <span>
                  ~${instrument.pricing.actionFee} in BSV / action
                </span>
              </div>
            </div>
          {/if}
        {/each}
        <div class="coming-soon">
          <span class="badge">Coming Soon</span>
          <p>
            Copyright licenses, Patent licenses, Patents, Equipment leases,
            Carbon credits, Debt securities, Equity securities, Equity options,
            Warrants, Futures, Swaps, and more
          </p>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .smart-contracts {
    @extend .generic-section;
    .pricing-details {
      margin-left: 18px;
      margin-right: 16px;
      margin-top: 7px;
      color: $N90;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .title {
        display: block;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 28px;
      }
      .pricing-part {
        .subtitle {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
          color: $N95;
        }
        p {
          font-size: 16px;
          color: $N85;
          margin-bottom: 0px;
        }
      }
    }
    .fees {
      display: flex;
      flex-direction: column;
      border: solid 1px $TN20;
      border-radius: 8px;
      box-shadow: 0px 13px 27px -5px #3f495933, 0px 8px 16px -8px #00000026;

      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $N65;
        font-size: 15px;
        padding: 12px 16px;
        span {
          color: $N55;
        }
      }
      .accordion {
        border-top: 1px solid $TN20;
        border-bottom: 1px solid $TN20;
        border-right: none;
        border-left: none;
        background: $white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $N90;
        font-size: 17px;
        font-weight: 700;
        cursor: pointer;
        margin: 0;
        padding: 14px 16px;
        &.isOpen {
          border-bottom: none;
          :global(span:first-child svg) {
            transform: rotate(90deg);
          }
        }
        .fee-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
        }
        .fee-price {
          font-size: 15px;
          font-weight: 600;
        }
      }
      .data {
        display: flex;
        flex-direction: column;
        padding-left: 32px;
        color: $N65;
        font-size: 15px;
        margin-bottom: 16px;
        margin-right: 16px;
        .instrument-description {
          display: flex;
          color: $N65;
          margin-top: -5px;
          padding-bottom: 16px;
        }
        .data-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: $N85;
          height: 32px;
          span:last-child {
            color: $N95;
            font-weight: 600;
          }
        }
        .data-subrow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: $N65;
          font-size: 15px;
          margin-left: 12px;
          height: 32px;
          span:last-child {
            color: $B95;
            font-weight: 600;
          }
        }
      }
      .coming-soon {
        font-size: 15px;
        color: $N85;
        padding: 16px 16px 8px 16px;
        border-top: solid 1px $TN20;
        .badge {
          display: flex;
          width: fit-content;
          background-color: $TB15;
          color: $B75;
          font-size: 13px;
          line-height: 16px;
          font-weight: 600;
          border-radius: 4px;
          padding: 1px 6px;
          text-transform: uppercase;
          font-family: "Source Sans 3", sans-serif;
          margin-bottom: 13px;
        }
        p {
          margin-top: -4px;
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-min) {
    .data {
      gap: 12px;
      padding-left: 46px !important;
      margin-bottom: 28px !important;
      .data-row,
      .data-subrow {
        align-items: normal !important;
        flex-direction: column !important;
        span:last-child {
          text-align: end;
        }
      }
      .data-subrow {
        margin-bottom: 16px;
      }
    }
  }
</style>
