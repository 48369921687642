<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import { COLOR_ATTENTION, COLOR_PRIMARY } from "buttonStyles";
  import Switch from "../../../elements/Switch.svelte";
  import FeatureList from "components/widgets/FeatureList.svelte";
  import { DOWNLOADS_URL, CONTACT_URL } from "utils/menu";

  let switchValue;

  // let pricingList = [
  //   {
  //     id: 1,
  //     title: "Free ",
  //     price: 0,
  //     perPeriod: "always",
  //     monthlyPrice: "",
  //     pros: "Bitcoin SV holdings up to $1K",
  //   },
  //   {
  //     id: 2,
  //     title: "Basic",
  //     price: 1,
  //     perPeriod: "per month",
  //     monthlyPrice: "billed annually or $1.50 monthly",
  //     pros: "Bitcoin SV holdings up to $20K",
  //   },
  //   {
  //     id: 3,
  //     title: "Premium",
  //     price: 3,
  //     perPeriod: "per month",
  //     monthlyPrice: "billed annually or $4.20 monthly",
  //     pros: "Bitcoin SV holdings up to $100K",
  //   },
  //   {
  //     id: 4,
  //     title: "Ultimate",
  //     price: 10,
  //     perPeriod: "per month",
  //     monthlyPrice: "billed annually or $12.50 monthly",
  //     pros: "Unlimited Bitcoin SV holdings",
  //   },
  // ];

  // let orgPricingList = [
  //   {
  //     id: 1,
  //     title: "Standard",
  //     price: 5,
  //     perPeriod: "per month",
  //     monthlyPrice: "when billed annually or $6.50 monthly",
  //     pros: [
  //       "1–2 administrators",
  //       "Bitcoin SV holdings up to $100K",
  //       "Multi-signature control for transactions and contract activity",
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Business ",
  //     price: 20,
  //     perPeriod: "per month",
  //     monthlyPrice: "when billed annually or $25 monthly",
  //     pros: [
  //       "1–3 administrators",
  //       "Bitcoin SV holdings up to $1M",
  //       "Multi-signature control for transactions and contract activity",
  //     ],
  //   },
  //   {
  //     id: 3,
  //     title: "Enterprise",
  //     price: "Variable pricing",
  //     perPeriod: "",
  //     monthlyPrice: "based on hosting requirements",
  //     pros: [
  //       "1–15 administrators",
  //       "Unlimited Bitcoin SV holdings ",
  //       "Multi-signature control for transactions and contract activity",
  //       "Required for credit note contracts",
  //     ],
  //     ultimate: true,
  //   },
  //   {
  //     id: 4,
  //     title: "Institutional",
  //     price: "Variable pricing",
  //     perPeriod: "",
  //     monthlyPrice: "based on hosting requirements",
  //     pros: [
  //       "1–15 administrators",
  //       "Unlimited Bitcoin SV holdings ",
  //       "Multi-signature control ",
  //       "Required for on-premises and hybrid hosting arrangements",
  //       "Required for digital currency and digital bank note issuers",
  //     ],
  //     ultimate: true,
  //   },
  // ];

  let shortPricingList = [
    {
      id: 1,
      title: "Standard",
      subtitle:
        "for individuals and organizations that want to manage and secure their tokenized instruments",
      price: "Free",
      perPeriod: "",
      monthlyPrice: "",
      pros: [
        "Smart contract-enabled wallet",
        "1-15 workspace administrators",
        "Unlimited treasury holdings",
        "Unlimited workspaces",
        "Hold Bitcoin (BSV) and tokenized instruments",
        "Multi-signature control for transactions and contract actions",
      ],
      showUsdLabel: false,
    },

    {
      id: 2,
      title: "Issuer",
      subtitle:
        "for individuals and organizations that want to issue smart contracts and tokenized instruments",
      price: "Variable pricing",
      perPeriod: "",
      monthlyPrice: "based on customer requirements",
      pros: [
        "All the features available in a standard account",
        "Required for issuing contracts and tokenized instruments",
        "Required for on-premises and hybrid hosting arrangements",
      ],
      ultimate: true,
      showUsdLabel: true,
    },
  ];

  const formatPrice = (price) => {
    if (typeof price === "number") {
      return `$${price}`;
    }
    return price;
  };
</script>

<PageSection shaded>
  <div class="pricing">
    <div class="columns top">
      <div class="heading">
        <Eyebrow>Pricing</Eyebrow>
        <Heading2>Accessible plans for everyone</Heading2>

        <!-- <div class="switch">
          <Switch bind:value={switchValue} />
        </div> -->
      </div>
    </div>
    <div class="columns cards-price">
      <div class="columns bottom pricing-row">
        {#each shortPricingList as pricing}
          <div class="pricing-card">
            <div class="title">
              <span>{pricing.title}</span>
              <span class="subtitle">{pricing.subtitle}</span>
            </div>
            <div class="price">
              {formatPrice(pricing.price)}
              {#if pricing.perPeriod}
                <span class="perPeriod>">{pricing.perPeriod}</span>
              {/if}
              {#if pricing.monthlyPrice}
                <p>{pricing.monthlyPrice}</p>
              {/if}
            </div>
            <div class="pros org">
              <ul>
                {#each pricing.pros as prosItem}
                  <li>{prosItem}</li>
                {/each}
              </ul>
            </div>
            {#if pricing.ultimate}
              <TextButton href={CONTACT_URL} medium center color={COLOR_PRIMARY}
                >Contact Sales</TextButton
              >
            {:else}
              <TextButton
                href={DOWNLOADS_URL}
                medium
                center
                color={COLOR_ATTENTION}>Get started</TextButton
              >{/if}

            {#if pricing.showUsdLabel}
              <div class="notice"><p>Prices in USD</p></div>
            {/if}
          </div>
        {/each}
      </div>
      <!-- <div class="columns bottom pricing-row">
        {#if switchValue === "Organizations"}
          {#each orgPricingList as pricing}
            <div class="pricing-card">
              <div class="title">
                <span>{pricing.title}</span>
                <span class="subtitle">for {switchValue}</span>
              </div>
              <div class="price">
                {formatPrice(pricing.price)}
                <span class="perPeriod>">{pricing.perPeriod}</span>
                <p>{pricing.monthlyPrice}</p>
              </div>
              <div class="pros org">
                <ul>
                  {#each pricing.pros as prosItem}
                    <li>{prosItem}</li>
                  {/each}
                </ul>
              </div>
              {#if pricing.ultimate}
                <TextButton
                  href={CONTACT_URL}
                  medium
                  center
                  color={COLOR_PRIMARY}>Contact Sales</TextButton
                >
              {:else}
                <TextButton
                  href={DOWNLOADS_URL}
                  medium
                  center
                  color={COLOR_ATTENTION}>Get started</TextButton
                >{/if}
            </div>
          {/each}
        {:else}
          {#each pricingList as pricing}
            <div class="pricing-card">
              <div class="title">
                <span>{pricing.title}</span>
                <span class="subtitle">for {switchValue}</span>
              </div>
              <div class="price">
                {formatPrice(pricing.price)}
                <span class="perPeriod>">{pricing.perPeriod}</span>
                <p>{pricing.monthlyPrice}</p>
              </div>
              <span class="pros">
                <ul>
                  <li>{pricing.pros}</li>
                </ul>
              </span>
              <TextButton
                href={DOWNLOADS_URL}
                medium
                center
                color={COLOR_ATTENTION}>Get started</TextButton
              >
            </div>
          {/each}
        {/if}
      </div>
      <div cass="notice"><p>Prices in USD</p></div>-->
    </div>

    <div class="columns features">
      <div class="title">Included features</div>
      <div class="feature-columns">
        <div class="column left">
          <FeatureList
            >Send, receive, or swap Tokenized instruments and Bitcoin SV
            instantly</FeatureList
          >
          <FeatureList
            >Self-custodial wallet with exclusive access to a 24-word recovery
            phrase</FeatureList
          >
          <FeatureList
            >Optional encrypted backups to iCloud and Google Drive</FeatureList
          >
          <FeatureList
            >Approve transactions and smart contract activity with the Mobile
            Authenticator app for iOS and Android</FeatureList
          >
        </div>
        <div class="column right">
          <FeatureList
            >Platform access via the desktop app for Windows, Mac, and Linux</FeatureList
          >
          <FeatureList
            >Detailed transaction history with filtering by instrument type</FeatureList
          >
          <FeatureList
            >View codified contractual details for any tokenized instrument</FeatureList
          >
          <FeatureList
            >Free personalized paymail handle to interoperate with external
            wallets</FeatureList
          >
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .pricing {
    @extend .generic-section;
    .top {
      padding-top: 0px;
    }
    gap: 48px;
    .heading {
      max-width: 888px;
      .subtitle {
        margin-left: 16px;
      }
    }
    .switch {
      display: flex;
      flex-direction: row;
      color: $N60;
      font-size: 14px;
      align-items: center;
      gap: 20px;
    }
    .cards-price {
      flex-direction: column;
      gap: 12px;
      padding: 0px;
      .pricing-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        .pricing-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: $white;
          border-radius: 12px;
          border: solid 1px $N15;
          padding: 20px 16px 16px 16px;
          min-height: 260px;
          width: 45%;
          min-width: 240px;
          box-shadow:
            0px 13px 27px -5px #3f495920,
            0px 8px 16px -8px #00000020;
          .title {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            font-weight: 700;
            color: $B95;
            .subtitle {
              color: $N60;
              font-size: 15px;
              font-weight: 400;
              text-transform: lowercase;
              margin-bottom: 16px;
            }
          }
          .price {
            color: $N95;
            font-size: 29px;
            font-weight: 600;
            margin-top: 4px;
            margin-bottom: 16px;
            span {
              font-size: 15px;
              color: $N85;
            }
            p {
              font-size: 14px;
              color: $N55;
              white-space: pre-wrap;
              margin-top: 4px;
              margin-bottom: 0px;
              font-weight: 400;
            }
          }
          .pros {
            color: $N85;
            display: flex;
            flex-direction: column;
            margin-bottom: 4px;
            margin-left: -18px;
            ul {
              margin-top: 2px;
              margin-bottom: 20px;
            }
          }
          :global(.button) {
            width: 100% !important;
          }
        }
      }
      .notice {
        height: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        padding-right: 36px;
        font-size: 14px;
        color: $N60;
        p {
          position: relative;
          left: 32px;
          padding: 0px;
          margin-top: 32px;
        }
      }
    }
    .features {
      gap: 0px;
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      .title {
        color: $N90;
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 28px;
        margin-left: 24px;
      }
      .feature-columns {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        padding: 0px 24px;
        .right {
          margin-left: 48px;
        }
      }
    }

    @media only screen and (min-width: $size-laptop-min) and (max-width: $size-laptop-max) {
      .pricing-row {
        gap: 24px;
      }
    }

    @media only screen and (max-width: $size-laptop-min) {
      .pricing-row {
        flex-wrap: wrap !important;
        .pricing-card {
          width: 45% !important;
        }
      }
    }

    @media only screen and (max-width: $size-tablet-min) {
      .heading .subtitle,
      .switch p {
        display: none;
      }
      .pricing-row {
        gap: 48px;
      }
    }

    @media only screen and (max-width: $size-phablet-min) {
      gap: 64px !important ;

      .feature-columns {
        flex-direction: column !important ;
        .right {
          margin-left: 0px !important ;
        }
      }
      .features {
        gap: 28px !important ;
      }
      .pricing-row {
        padding: 0px 12px;
        .pricing-card {
          width: 100% !important;
        }
      }
    }
  }
</style>
