<script>
  export let width = 20;
  export let height = 16;
  export let color = '#7a869c';
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.09363 6.57786C7.01494 4.43373 10.2966 3.02018 11.9386 2.33723C16.6267 0.387253 17.6009 0.0485222 18.2358 0.0372178C18.3755 0.034877 18.6877 0.0694863 18.89 0.233607C19.0608 0.372188 19.1078 0.55939 19.1303 0.69078C19.1528 0.822169 19.1808 1.12148 19.1585 1.35535C18.9044 4.02472 17.8052 10.5026 17.2459 13.4923C17.0093 14.7574 16.5433 15.1815 16.0922 15.2231C15.1119 15.3133 14.3674 14.5752 13.4179 13.9528C11.9321 12.9788 11.0928 12.3725 9.65053 11.4221C7.9838 10.3238 9.06427 9.72009 10.0141 8.73352C10.2627 8.47532 14.5821 4.54649 14.6657 4.19008C14.6762 4.14551 14.6859 3.97936 14.5872 3.89162C14.4885 3.80389 14.3428 3.83389 14.2377 3.85775C14.0886 3.89157 11.7151 5.46042 7.11694 8.56428C6.44321 9.02692 5.83296 9.25233 5.2862 9.24052C4.68345 9.2275 3.52398 8.89971 2.66204 8.61953C1.60483 8.27587 0.764588 8.09418 0.837754 7.51054C0.875864 7.20655 1.29449 6.89566 2.09363 6.57786Z"
    fill={color}
  />
</svg>
