<script>
  import Link from "components/elements/Link.svelte";
  import { COLOR_SUBTLE } from "buttonStyles";

  export let href = undefined;

  // Footer links scroll the window back up by default
  export let scrollTop = true;
</script>

<Link dark {scrollTop} color={COLOR_SUBTLE} {href}>
  <slot />
</Link>
