<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M61.9978 33.282C61.8178 49.0221 48.9126 61.7818 33.174 61.7818C17.4353 61.7818 4.8219 49.0221 5.0019 33.282C5.1819 17.5425 18.0871 4.7821 33.8257 4.7821C49.5652 4.7821 62.1778 17.5425 61.9978 33.282Z"
    fill="#EEF0F2"
  />
  <path
    d="M59.7809 12.7821H7.50697C5.84123 12.7821 4 14.1711 4 15.8361V51.4946C4 53.1596 5.84123 54.7818 7.50697 54.7818H59.7809C61.4466 54.7818 62.9999 53.1596 62.9999 51.4946V15.8361C62.9999 14.1711 61.4474 12.7821 59.7809 12.7821Z"
    fill="white"
  />
  <path
    d="M59.0002 16.7821H8.00045M57.9997 47.7824V19.7821M8.00045 50.7824H59.0002M9.0002 32.7825V37.782M9.0002 44.7824V47.7824M9.0002 19.7821V21.7823M9.99995 57.7821L11.0972 58.7819H18.9999L20.0004 57.7821M47.0002 57.7821L48.0967 58.7819H56.0002L56.9999 57.7821M12.9999 25.7821V29.7825H2.00048C1.73513 29.7825 1.48066 29.6771 1.29304 29.4895C1.10541 29.3019 1 29.0474 1 28.782V23.7826C1 23.2306 1.44849 22.7821 2.00048 22.7821H14.0004M12.9999 40.782V44.7824H2.00048C1.73513 44.7824 1.48066 44.677 1.29304 44.4894C1.10541 44.3018 1 44.0473 1 43.7819V38.7825C1 38.2305 1.44849 37.782 2.00048 37.782H14.0004"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M44.9996 33.7823C44.9996 39.8573 40.0744 44.7825 33.9995 44.7825C27.9245 44.7825 23 39.8573 23 33.7823C23 27.7073 27.9245 22.7821 33.9995 22.7821C40.0744 22.7821 44.9996 27.7073 44.9996 33.7823Z"
    fill="#B2D8FA"
  />
  <path
    d="M44.9996 33.7823C44.9996 39.8573 40.0744 44.7825 33.9995 44.7825C27.9245 44.7825 23 39.8573 23 33.7823C23 27.7073 27.9245 22.7821 33.9995 22.7821C40.0744 22.7821 44.9996 27.7073 44.9996 33.7823Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M38.999 33.7838C38.999 35.1099 38.4722 36.3818 37.5344 37.3195C36.5967 38.2572 35.3249 38.784 33.9987 38.784C32.6726 38.784 31.4008 38.2572 30.463 37.3195C29.5253 36.3818 28.9985 35.1099 28.9985 33.7838C28.9985 32.4576 29.5253 31.1858 30.463 30.2481C31.4008 29.3104 32.6726 28.7836 33.9987 28.7836C35.3249 28.7836 36.5967 29.3104 37.5344 30.2481C38.4722 31.1858 38.999 32.4576 38.999 33.7838Z"
    fill="white"
  />
  <path
    d="M4 29.7826V37.782M38.9993 33.7838C38.9993 35.1099 38.4725 36.3818 37.5348 37.3195C36.597 38.2572 35.3252 38.784 33.9991 38.784C32.6729 38.784 31.4011 38.2572 30.4634 37.3195C29.5256 36.3818 28.9989 35.1099 28.9989 33.7838C28.9989 32.4577 29.5256 31.1858 30.4634 30.2481C31.4011 29.3104 32.6729 28.7836 33.9991 28.7836C35.3252 28.7836 36.597 29.3104 37.5348 30.2481C38.4725 31.1858 38.9993 32.4577 38.9993 33.7838V33.7838ZM33.9998 22.7821V26.7826V22.7821ZM33.9998 40.782V44.7825V40.782ZM45 33.7823H41.0003H45ZM27.0001 33.7823H23.0004H27.0001ZM42 41.7825L39 38.7825L42 41.7825ZM29.0003 28.7821L26.0004 25.7821L29.0003 28.7821ZM26.0004 41.7825L29.0003 38.7825L26.0004 41.7825ZM39 28.7821L42 25.7821L39 28.7821Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M4 44.7821V51.4946C4 53.1596 5.84123 54.7818 7.50697 54.7818H59.7809C61.4466 54.7818 62.9999 53.1596 62.9999 51.4946V15.8361C62.9999 14.1711 61.4474 12.7821 59.7809 12.7821H7.50697C5.84123 12.7821 4 14.1711 4 15.8361V22.7818"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
