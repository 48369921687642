<script>
  import BoxIcon from "components/elements/BoxIcon.svelte";
  import BoxText from "components/elements/headings/BoxText.svelte";
  import BoxTitle from "components/elements/headings/BoxTitle.svelte";
  import Anchor from "components/elements/Anchor.svelte";
  import Icon from "components/elements/Icon";

  export let icon = "";
  export let title = "";
  export let dark = false;
  export let href = "";
  export let colorName = "";
  export let chevron = true;
</script>

<div
  class="box-item {chevron ? 'chevron' : ''} {dark
    ? 'dark'
    : ''} {$$props.class || ''}"
>
  <Anchor {href}>
    <div class="column-icon">
      <BoxIcon {icon} {colorName}/>
    </div>
    <div class="column-text">
      <div class="title">
        <BoxTitle {dark}>
          {title}
        </BoxTitle>
        <div class="chevron-icon">
          <Icon name="chevron-right" colorName="N60" />
        </div>
        <div class="arrow-icon">
          <Icon name="arrow-right" colorName="B40" />
        </div>
      </div>
      <div class="description">
        <BoxText {dark}>
          <slot />
        </BoxText>
      </div>
    </div>
  </Anchor>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";
  .box-item {
    display: flex;
    width: 100%;
    .column-icon {
      width: 40px;
      padding: 16px 0px 0px 16px;
    }
    .column-text {
      padding: 16px 16px 16px 24px;
      width: 100%;
    }
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
    }
    &.chevron {
      .chevron-icon,
      .arrow-icon {
        height: 18px;
        margin-left: 6px;
      }
      .arrow-icon {
        display: none;
      }
      &:hover,
      &.active {
        background-color: $N10;
        border-radius: 6px;
        .chevron-icon {
          display: none;
        }
        .arrow-icon {
          display: initial;
          margin-left: 8px;
        }
      }
    }
    &.dark.chevron {
      &:hover,
      &.active {
        background-color: $I80;
      }
    }
  }
</style>
