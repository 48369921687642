<script>
  import TokenizedLogo from "components/elements/TokenizedLogo.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import Menu from "components/widgets/Menu.svelte";
  import MenuContents from "components/widgets/MenuContents.svelte";
  import MobileMenuButton from "components/widgets/MenuButton";
  import { COLOR_PRIMARY, COLOR_SECONDARY } from "buttonStyles";
  import { BUTTON_DATA, DOWNLOADS_URL } from "utils/menu";

  export let dark = false;
  export let padded = false;

  // References to TextButton objects
  let buttons = [];
  let activeButtonIdx = null;

  function onMenuButtonClick(idx) {
    if (activeButtonIdx === idx) {
      activeButtonIdx = null;
    } else {
      activeButtonIdx = idx;
    }
  }

  // Callback for individual menu options
  function onMenuItemClick() {
    activeButtonIdx = null;
  }
</script>

<div class="header {dark ? 'dark' : ''} {padded ? 'padded' : ''}">
  <div class="inner-header columns">
    <div class="logo">
      <TokenizedLogo {dark} />
    </div>
    <div class="button-block">
      {#each BUTTON_DATA as buttonData, i}
        <TextButton
          {dark}
          noFill
          color={COLOR_SECONDARY}
          onClick={() => onMenuButtonClick(i)}
          bind:this={buttons[i]}
        >
          {buttonData.label}
        </TextButton>
      {/each}
      <TextButton dark color={COLOR_PRIMARY} href={DOWNLOADS_URL}>
        Download
      </TextButton>
      <MobileMenuButton {dark} />
      <Menu
        {buttons}
        {activeButtonIdx}
        onBackdropClick={() => (activeButtonIdx = null)}
      >
        <MenuContents {activeButtonIdx} afterRedirect={onMenuItemClick} />
      </Menu>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .header {
    @extend .generic-section;
    justify-content: center;
    background-color: $I05;
    &.dark {
      background-color: $I90;
    }
    &.padded {
      padding-bottom: 28px;
      @media only screen and (max-width: $size-tablet-max) {
        padding-bottom: 0px;
      }
    }

    .inner-header {
      height: 64px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;

      @media only screen and (max-width: $size-tablet-max) {
        align-items: center;
      }
    }

    .logo {
      padding-left: 16px;
    }

    .button-block {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      :global(.button:not(:first-child)) {
        margin-left: 32px;
      }

      @media only screen and (max-width: $size-tablet-max) {
        :global(> div.button) {
          display: none;
        }
      }
    }

    :global(.button) {
      z-index: 2;
    }
  }
</style>
