/** Instrument data (used by the fee calculator) */

export const INSTRUMENTS = [
  {
    id: "COU",
    label: "Discount coupons",
    namePlural: "coupons",
    nameSingular: "coupon",
    variablePrecision: false,
    description:
      "Share a percentage or fixed-currency discount with customers.",
    pricing: {
      issuerFee: 12.0,
      actionFee: 0.001,
      unitFees: [
        {
          label: "First million coupons",
          fee: 0.000008,
          feeLabel: "$0.008 per thousand",
        },
        {
          label: "1 million – 1 billion",
          fee: 0.00000025,
          feeLabel: "$0.25 per million",
        },
        {
          label: "1 billion – 1 trillion",
          fee: 0.000000045,
          feeLabel: "$45.00 per billion",
        },
        {
          label: "1 trillion +",
          fee: 0.00000000023,
          feeLabel: "$230.00 per trillion",
        },
        {
          // Quadrillion + has no label as it isn't listed in the pricing table
          label: "",
          fee: 0.00000000023,
          feeLabel: "$230.00 per trillion",
          hidden: true,
        },
      ],
    },
  },
  {
    id: "TIC",
    label: "Event tickets",
    namePlural: "tickets",
    nameSingular: "ticket",
    variablePrecision: false,
    description:
      "Create digital tickets for any event with support for secondary markets.",
    pricing: {
      issuerFee: 10.0,
      actionFee: 0.01,
      unitFees: [
        {
          label: "First million tickets",
          fee: 0.00001,
          feeLabel: "$0.01 per thousand",
        },
        {
          label: "1 million – 1 billion",
          fee: 0.00000045,
          feeLabel: "$0.45 per million",
        },
        {
          label: "1 billion – 1 trillion",
          fee: 0.000000085,
          feeLabel: "$85.00 per billion",
        },
        {
          label: "1 trillion +",
          fee: 0.00000000035,
          feeLabel: "$350.00 per trillion",
        },
        {
          label: "",
          fee: 0.00000000035,
          feeLabel: "$350.00 per trillion",
          hidden: true,
        },
      ],
    },
  },
  {
    id: "LOY",
    label: "Reward points",
    namePlural: "points",
    nameSingular: "point",
    variablePrecision: false,
    description: "Incentivize customer loyalty with redeemable reward points.",
    pricing: {
      issuerFee: 58.0,
      actionFee: 0.001,
      unitFees: [
        {
          label: "First million points",
          fee: 0.000004,
          feeLabel: "$0.004 per thousand",
        },
        {
          label: "1 million – 1 billion",
          fee: 0.00000015,
          feeLabel: "$0.15 per million",
        },
        {
          label: "1 billion – 1 trillion",
          fee: 0.000000035,
          feeLabel: "$35.00 per billion",
        },
        {
          label: "1 trillion +",
          fee: 0.00000000019,
          feeLabel: "$190.00 per trillion",
        },
        {
          label: "",
          fee: 0.00000000019,
          feeLabel: "$190.00 per trillion",
          hidden: true,
        },
      ],
    },
  },
  {
    id: "CRN",
    label: "Credit notes",
    namePlural: "units",
    nameSingular: "unit",
    variablePrecision: true,
    description:
      "Offer a currency-denominated balance to customers in the form of gift vouchers or store credit as compensation for returned merchandise.",
    pricing: {
      issuerFee: 58.0,
      actionFee: 0.001,
      unitFees: [
        {
          label: "First million points",
          fee: 0.00003,
          feeLabel: "$0.03 per thousand",
        },
        {
          label: "1 million – 1 billion",
          fee: 0.000003,
          feeLabel: "$3.00 per million",
        },
        {
          label: "1 billion – 1 trillion",
          fee: 0.0000024,
          feeLabel: "$2400.00 per billion",
        },
        {
          label: "1 trillion +",
          fee: 0.0000024,
          feeLabel: "$2400.00 per billion",
        },
        {
          label: "",
          fee: 0.0000024,
          feeLabel: "$2400.00 per billion",
          hidden: true,
        },
      ],
    },
  },
  {
    id: "CUR",
    label: "Currency",
    namePlural: "units",
    nameSingular: "unit",
    variablePrecision: true,
    description:
      "Reserved for central banks, monetary authorities, or associated entities.",
    pricing: {
      issuerFee: 10000.0,
      actionFee: 0.001,
      unitFees: [
        {
          label: "First million points",
          fee: 0.00003,
          feeLabel: "$0.03 per thousand",
        },
        {
          label: "1 million – 1 billion",
          fee: 0.000003,
          feeLabel: "$3.00 per million",
        },
        {
          label: "1 billion – 1 trillion",
          fee: 0.0000024,
          feeLabel: "$2400.00 per billion",
        },
        {
          label: "1 trillion +",
          fee: 0.0000024,
          feeLabel: "$2400.00 per billion",
        },
        {
          label: "",
          fee: 0.0000024,
          feeLabel: "$2400.00 per billion",
          hidden: true,
        },
      ],
    },
  },
];

export const CONTRACT_DURATION_OPTIONS = [
  {
    value: 1,
    label: "1 month",
  },
  {
    value: 2,
    label: "2 months",
  },
  {
    value: 3,
    label: "3 months",
  },
  {
    value: 4,
    label: "4 months",
  },
  {
    value: 5,
    label: "5 months",
  },
  {
    value: 6,
    label: "6 months",
  },
  {
    value: 7,
    label: "7 months",
  },
  {
    value: 8,
    label: "8 months",
  },
  {
    value: 9,
    label: "9 months",
  },
  {
    value: 10,
    label: "10 months",
  },
  {
    value: 11,
    label: "11 months",
  },
  {
    value: 12,
    label: "12 months+",
  },
];

// Decimal precision options: from 2 to 9 decimal places
export const DECIMAL_PRECISION_OPTIONS = [
  {
    value: 2,
    label: "2 decimal places",
  },
  {
    value: 3,
    label: "3 decimal places",
  },
  {
    value: 4,
    label: "4 decimal places",
  },
  {
    value: 5,
    label: "5 decimal places",
  },
  {
    value: 6,
    label: "6 decimal places",
  },
  {
    value: 7,
    label: "7 decimal places",
  },
  {
    value: 8,
    label: "8 decimal places",
  },
  {
    value: 9,
    label: "9 decimal places",
  },
];
