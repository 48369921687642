<script>
  import TokenizedLogo from "components/elements/TokenizedLogo.svelte";
  import FooterLink from "components/elements/aliases/FooterLink.svelte";
  import SocialLinks from "components/widgets/SocialLinks.svelte";
  import LegalLinks from "../elements/LegalLinks.svelte";
  import {
    PROTOCOL_DOCS_URL,
    SDK_DOCS_URL,
    API_DOCS_URL,
    BLOG_URL,
    CONTACT_URL,
    DOWNLOADS_URL,
  } from "utils/menu";
</script>

<div class="footer">
  <div class="columns">
    <div class="column logo-and-social">
      <TokenizedLogo dark />
      <SocialLinks />
      <span class="download-link"
        ><FooterLink href={DOWNLOADS_URL}>Download the app</FooterLink></span
      >
    </div>
    <div class="column">
      <span class="header">Platform</span>
      <FooterLink href="/platform/wallet/">Digital Wallet</FooterLink>
      <FooterLink href="/platform/contracts/">Smart Contract Engine</FooterLink>
      <FooterLink href="/platform/tokenization/">Tokenization</FooterLink>
      <FooterLink href="/platform/compliance/">Compliance</FooterLink>
      <FooterLink href="/platform/pricing/">Pricing</FooterLink>
    </div>
    <div class="column">
      <span class="header">Developers</span>
      <FooterLink href={PROTOCOL_DOCS_URL}>Tokenized Protocol</FooterLink>
      <FooterLink href={SDK_DOCS_URL}>SDK Documentation</FooterLink>
      <FooterLink href={API_DOCS_URL}>API Reference</FooterLink>
    </div>
    <div class="column">
      <span class="header">Company</span>
      <FooterLink href="/platform/about/">About Us</FooterLink>
      <FooterLink scrollTop={false} href={BLOG_URL}>Blog</FooterLink>
      <FooterLink scrollTop={false} href={CONTACT_URL}>Contact</FooterLink>
      <!-- <FooterLink>Downloads</FooterLink> -->
    </div>
  </div>
  <div class="columns">
    <div class="legal-bottom">
      <span class="tablet-visible">
        <LegalLinks />
      </span>

      <span class="mobile-visible">
        <LegalLinks />
      </span>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .footer {
    @extend .generic-section;
    background-color: $I90;
    padding: 96px 0px 12px;
    gap: 64px;

    .columns {
      display: flex;
      flex-wrap: wrap;
      gap: 64px 48px;
    }

    .column {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      gap: 12px;
      width: 280px;

      &.logo-and-social {
        gap: 20px;
      }
      :global(.download-link > a > div) {
        color: $B35 !important ;
      }

      .header {
        color: $N15;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 8px;
        text-transform: uppercase;
      }
    }
    .legal-bottom {
      display: flex;
      justify-content: flex-start;
      color: $N55;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      gap: 16px;
      padding: 0px 16px;
    }

    @media only screen and (max-width: $size-laptop-max) {
      .column {
        width: calc(25% - 36px);
      }
    }
    @media only screen and (max-width: $size-tablet-max) {
      .column.logo-and-social {
        width: 100%;
      }
    }
    @media only screen and (max-width: $size-phablet-max) {
      .column {
        width: 100%;
      }
    }
  }
</style>
