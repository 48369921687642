<script>
  export let width = 20;
  export let height = 21;
  export let color = '#7a869c';
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10 1.78711C5.16833 1.78711 1.25 5.80628 1.25 10.7596C1.25 14.7246 3.75833 18.0846 7.23417 19.2713C7.28289 19.2819 7.33263 19.2872 7.3825 19.2871C7.7075 19.2871 7.8325 19.0488 7.8325 18.8421C7.8325 18.6271 7.82417 18.0646 7.82 17.3146C7.4925 17.3888 7.19917 17.4196 6.9375 17.4196C5.25417 17.4196 4.87083 16.1113 4.87083 16.1113C4.4725 15.0763 3.89833 14.7988 3.89833 14.7988C3.13667 14.2638 3.89417 14.2488 3.95333 14.2488H3.95667C4.83583 14.3263 5.29667 15.1779 5.29667 15.1779C5.73417 15.9438 6.32 16.1579 6.84417 16.1579C7.25417 16.1579 7.625 16.0246 7.84417 15.9246C7.92167 15.3454 8.14833 14.9513 8.39833 14.7246C6.45667 14.4979 4.41417 13.7288 4.41417 10.2913C4.41417 9.31044 4.75417 8.50961 5.3125 7.88461C5.2225 7.65794 4.92167 6.74378 5.39833 5.50961C5.39833 5.50961 5.46083 5.49044 5.59417 5.49044C5.91083 5.49044 6.625 5.61128 7.805 6.43211C8.5186 6.23325 9.25587 6.13205 9.99667 6.13128C10.7383 6.13461 11.4883 6.23211 12.1875 6.43128C13.3675 5.61128 14.0817 5.48961 14.3983 5.48961C14.5317 5.48961 14.5942 5.50961 14.5942 5.50961C15.07 6.74378 14.7692 7.65794 14.68 7.88461C15.2383 8.51378 15.5783 9.31461 15.5783 10.2913C15.5783 13.7363 13.5317 14.4938 11.5817 14.7163C11.8942 14.9938 12.1758 15.5413 12.1758 16.3771C12.1758 17.5763 12.1642 18.5446 12.1642 18.8379C12.1642 19.0488 12.285 19.2871 12.6092 19.2871C12.6618 19.2873 12.7143 19.282 12.7658 19.2713C16.2458 18.0838 18.75 14.7213 18.75 10.7596C18.75 5.80628 14.8317 1.78711 10 1.78711Z"
    fill={color}
  />
</svg>
