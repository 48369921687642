<script>
  export let width = 20;
  export let height = 16;
  export let color = '#7a869c';
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.2183 1.81502C18.5276 2.12038 17.7955 2.32173 17.0458 2.41252C17.8366 1.93973 18.4284 1.19538 18.7108 0.318352C17.9808 0.751685 17.1716 1.06835 16.3083 1.23669C15.7895 0.683348 15.1164 0.298722 14.3763 0.132727C13.6362 -0.0332675 12.8632 0.027033 12.1578 0.305803C11.4524 0.584573 10.8471 1.06894 10.4204 1.69604C9.99369 2.32314 9.76534 3.06402 9.76497 3.82252C9.76581 4.11919 9.79664 4.40835 9.86331 4.68585C8.35864 4.61135 6.88658 4.22048 5.54316 3.53871C4.19974 2.85695 3.01514 1.89963 2.06664 0.729185C1.58315 1.56325 1.43553 2.55017 1.6538 3.48921C1.87207 4.42824 2.43985 5.24887 3.24164 5.78419C2.63881 5.76902 2.04887 5.60651 1.52331 5.31085V5.35752C1.52295 6.23158 1.82511 7.07883 2.3785 7.75541C2.93188 8.43198 3.70237 8.89618 4.55914 9.06919C4.23423 9.15721 3.89909 9.20176 3.56247 9.20168C3.32081 9.20168 3.08247 9.17835 2.85164 9.13085C3.09309 9.88301 3.56369 10.5408 4.19755 11.0123C4.83141 11.4837 5.59682 11.7452 6.38664 11.76C5.60198 12.377 4.70325 12.8329 3.74199 13.1018C2.78072 13.3706 1.77584 13.4471 0.784973 13.3267C2.50714 14.4474 4.5186 15.0419 6.57331 15.0375C13.5383 15.0375 17.3433 9.26835 17.3433 4.26419C17.3433 4.10002 17.3391 3.93585 17.3316 3.77585C18.0719 3.23943 18.7108 2.57545 19.2183 1.81502Z"
    fill={color}
  />
</svg>
