<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection shaded>
  <div class="onchain-privacy">
    <div class="columns">
      <div class="column left">
        <Eyebrow>On-chain Privacy</Eyebrow>
        <Heading3 large>Transparent and private</Heading3>
        <Body>
          All actions are recorded to a distributed ledger in a publicly
          verifiable and immutable manner. However, the parties involved are
          always pseudonymous, represented by a public key(s), and the sensitive
          details are always private, as they are encrypted or stored off-chain
          depending on the customer’s needs.
          <br /><br />Transferred values are masked using an open-source
          algorithm to break up sent and received amounts across different
          inputs, outputs and transactions, and public keys are never reused.
          <br /><br />Ownership can be on a bearer (aka pseudonymous), or
          ‘registered’, basis. For bearer tokens, the token is used as a digital
          bearer certificate. For ‘registered’ tokens, the owners’ details can
          be stored at a central depository/registry, or can be distributed
          across multiple registries. In both cases, the on-chain records are
          always pseudonymous to maintain privacy.
        </Body>
      </div>
      <div class="column right image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/public-vs-private.webp" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .onchain-privacy {
    @extend .generic-section;
  }
</style>
