<script>
  import UAParser from "ua-parser-js";
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Anchor from "../../../elements/Anchor.svelte";

  const parser = new UAParser();
  const operatingSystem = parser.getOS() || { name: "Windows" };
</script>

<PageSection long>
  <div class="mobile-app">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Mobile app</Eyebrow>
        <Heading3 large
          >Safeguard your account with the Tokenized authenticator
        </Heading3>
        <Body>
          With a single tap, securely approve logins and other important
          platform activity using your iOS or Android mobile device.
        </Body>
        <div class="download-apps">
          {#if operatingSystem.name !== "iOS"}
            <Anchor
              href="https://play.google.com/store/apps/details?id=com.tokenized"
              ><img
                src="/images/google_play.svg"
                alt="tokenized google play link"
              />
            </Anchor>{/if}
          {#if operatingSystem.name !== "Android"}
            <Anchor
              href="https://apps.apple.com/us/app/tokenized-authenticator/id1499017570"
              ><img
                src="/images/app_store.svg"
                alt="tokenized app store link"
              />
            </Anchor>{/if}
        </div>
        {#if operatingSystem.name !== "iOS" && operatingSystem.name !== "Android"}
          <div class="qr">
            <ImageFrame width={128} shaded={false} src="/images/QR.svg" />
          </div>
        {/if}
      </div>
      <div class="column left image">
        <ImageFrame width={560} shaded={false} src="/images/mobile-app.webp" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .mobile-app {
    @extend .generic-section;
    .columns {
      flex-direction: row-reverse;
      align-items: top;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }

    .download-apps {
      margin-left: 16px;
      display: flex;
      flex-direction: row;
      gap: 24px;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }
    .qr {
      margin-top: 48px;
      @media only screen and (max-width: $size-phablet-max) {
        margin-top: 96px;
      }
      :global(.image-frame) {
        margin-left: 16px;
      }
    }
  }
</style>
