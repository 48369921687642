<script>
  export let width = 20;
  export let height = 21;
  export let color = '#7a869c';
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.2967 3.03711H3.78083C3.0975 3.03711 2.5 3.52878 2.5 4.20544V16.7479C2.5 17.4279 3.0975 18.0329 3.78167 18.0329H16.2933C16.9808 18.0329 17.4958 17.4246 17.4958 16.7479V4.20544C17.5 3.52878 16.9808 3.03711 16.2967 3.03711ZM7.14833 15.5371H5V8.85711H7.14833V15.5371ZM6.14833 7.84211H6.13333C5.445 7.84211 5 7.33044 5 6.68961C5 6.03711 5.45667 5.53711 6.16 5.53711C6.86333 5.53711 7.29333 6.03294 7.30833 6.68961C7.30833 7.33044 6.86333 7.84211 6.14833 7.84211ZM15 15.5371H12.8517V11.8846C12.8517 11.0096 12.5392 10.4121 11.7617 10.4121C11.1683 10.4121 10.8167 10.8146 10.66 11.2054C10.6017 11.3454 10.5858 11.5371 10.5858 11.7321V15.5371H8.4375V8.85711H10.5858V9.78711C10.8983 9.34211 11.3867 8.70128 12.5233 8.70128C13.9333 8.70128 15 9.63044 15 11.6346V15.5371Z"
    fill={color}
  />
</svg>
