<script>
  import PageLayout from "components/PageLayout.svelte";
  import TakeStep from "../Sections/TakeStep.svelte";
  import Team from "./Sections/Team.svelte";
  import AboutHero from "./Sections/AboutHero.svelte";
</script>

<PageLayout dark={true}>
  <AboutHero />
  <Team />
  <TakeStep shaded />
</PageLayout>
