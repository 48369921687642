/** Text formatting utilities */

export const formatDecimal = (value, decimalPlaces = 2) => {
  if (decimalPlaces == 2 && value < 0.01 && value > 0) {
    decimalPlaces = value <= 0.000000005 ? 12 : 9;
  }

  // Positive numbers, use comma as thousands separator
  // Negative numbers, use space as thousands separator
  return value
    .toFixed(decimalPlaces)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, decimalPlaces <= 2 ? "$1," : "$1 ");
};

export const formatDecimalCompound = (value, decimalPlaces = 2) => {
  const [left, right] = value.toFixed(decimalPlaces).toString().split(".");
  return `${left.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.${
    right ? right.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") : ""
  }`;
};

export const formatInteger = (value) => formatDecimal(value, 0);
