<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import { COLOR_PRIMARY } from "buttonStyles";
  import ImageFrame from "../../../elements/ImageFrame.svelte";
</script>

<PageSection shaded>
  <div class="smart-contracts">
    <div class="columns top">
      <div class="column left">
        <Eyebrow>SMART CONTRACT Engine</Eyebrow>
        <Heading2>A digital transformation of legal agreements</Heading2>
        <Body>
          Our smart contract engine enables anyone to easily draft, form, and
          manage smart contracts that define and control all types of digital
          assets. No technical expertise required.
        </Body>
      </div>
      <div class="column right">
        <ImageFrame
          shaded={false}
          width={560}
          src="/images/contract-editor.webp"
          alt="smart contracts"
        />
      </div>
    </div>
    <div class="columns bottom icon-row">
      <div class="icon-column">
        <IconColumn icon="content" heading="Master agreement">
          The protocol forms a master agreement to specify the mechanics, the
          legalities, and the risks, in a similar fashion to the ISDA Master
          Agreement.
        </IconColumn>
        <div class="buttons">
          <TextButton
            noFill
            color={COLOR_PRIMARY}
            iconRight="chevron-right"
            href="https://en.wikipedia.org/wiki/ISDA_Master_Agreement"
          >
            Learn more
          </TextButton>
        </div>
      </div>
      <div class="icon-column">
        <IconColumn icon="contract" heading="Ricardian contract">
          Everything is human-readable, but some of it is only interpretable and
          enforceable by humans, while other parts are machine-readable and
          enforceable.
        </IconColumn>
        <div class="buttons">
          <TextButton
            noFill
            color={COLOR_PRIMARY}
            iconRight="chevron-right"
            href="https://en.wikipedia.org/wiki/Ricardian_contract"
          >
            Learn more
          </TextButton>
        </div>
      </div>
      <div class="icon-column">
        <IconColumn icon="microscheme" heading="Digital and structured">
          Business has gone digital, but contracts got left behind. Our solution
          enables a digital transformation of contracts that enables software to
          enhance the contracting experience.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="unit" heading="Entities and contracts">
          Entity contracts, aka DAOs, are the founding/operating agreements of
          an entity turned into a smart contract. Instrument contracts utilize
          tokens to represent the legal /financial instruments.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .smart-contracts {
    @extend .generic-section;

    .column {
      :global(.image-frame) {
        margin-bottom: -48px;
      }
    }
  }
</style>
