<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection shaded>
  <div class="transfer-restrictions">
    <div class="columns">
      <div class="column left">
        <Eyebrow>Transfer restrictions</Eyebrow>
        <Heading3>Smart contract-enforced transfer restrictions</Heading3>
        <Body>
          The codified terms and conditions specified by the digital asset’s
          smart contract allows for software-enforced compliance with the terms
          and conditions.
        </Body>
      </div>
      <div class="column right image">
        <ImageFrame width={560} src="/images/transfer-restriction.webp" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .transfer-restrictions {
    @extend .generic-section;
  }
</style>
