<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import { COLOR_ATTENTION } from "buttonStyles";
</script>

<PageSection>
  <div class="automate-tasks">
    <div class="columns">
      <div class="column left image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/automation.webp"
        />
      </div>
      <div class="column right">
        <Eyebrow>A technology-first approach</Eyebrow>
        <Heading3>
          Automate administrative and performance-related tasks
        </Heading3>
        <Body>
          Smart contracts are legal agreements that are stored on a distributed
          ledger in a structured and digital manner, such that software can be
          leveraged to automate administrative functions, enforce terms and
          conditions, and automatically execute certain rights and obligations.
          <br /><br />A smart contract can automate most of the functions that
          is required of a transfer agent or paying agent, and can automate and
          reduce a number of the routine tasks to help lawyers deliver a better
          client experience.
        </Body>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .automate-tasks {
    @extend .generic-section;
    flex-direction: column;
    align-items: center;
    
    .buttons {
      margin-left: 16px;
    }
  }
</style>
