<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M58.8391 22.5342C60.2313 25.9271 60.9993 29.642 60.9993 33.5369C60.9993 38.6138 59.6942 43.3868 57.4021 47.5367M6.59723 47.5367C4.30467 43.3858 3 38.6138 3 33.5369C3 27.1871 5.04096 21.3142 8.50259 16.5373"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M47.9998 52.5365H44.9999M54.9997 52.5365H51.9997M49.9998 54.5365V57.5364M49.9998 47.5366V50.5366M14.0004 52.5365L12.0005 54.5365M19.0004 47.5366L17.0004 49.5366M17.0004 52.5365L19.0004 54.5365M12.0005 47.5366L14.0004 49.5366M33.9997 16.6532C42.446 17.6432 48.9998 24.8251 48.9998 33.5369C48.9998 42.9257 41.3886 50.5366 32.0001 50.5366M16.0004 39.2958C15.3531 37.4968 15.0004 35.5579 15.0004 33.5369C15.0004 28.853 16.8945 24.6121 19.9589 21.5371M50.252 19.5412C53.2298 23.4181 54.9997 28.271 54.9997 33.5369C54.9997 36.7308 54.3485 39.7728 53.172 42.5367"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M9 33.5369C9 26.566 12.1015 20.3191 16.9998 16.1012C21.0268 12.6333 26.2685 10.5373 32 10.5373M40.9998 54.7095C38.2353 55.8855 35.1936 56.5364 32 56.5364C30.2824 56.5364 28.6094 56.3484 26.9997 55.9915C25.6295 55.6877 24.2902 55.2582 22.9989 54.7085M46.9997 7.53735V10.5373M46.9997 14.5372V17.5372M44.9997 12.5373H41.9998M51.9996 12.5373H48.9996"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M32.1932 22.537L35.5325 29.3038L43 30.3888L37.5966 35.6557L38.872 43.0926L32.1932 39.5807L25.5144 43.0926L26.7901 35.6557L21.3867 30.3888L28.8538 29.3038L32.1932 22.537Z"
    fill="#B2D8FA"
  />
  <path
    d="M32.1932 22.537L35.5325 29.3038L43 30.3888L37.5966 35.6557L38.872 43.0926L32.1932 39.5807L25.5144 43.0926L26.7901 35.6557L21.3867 30.3888L28.8538 29.3038L32.1932 22.537Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
