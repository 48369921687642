<script>
  import PageLayout from 'components/PageLayout.svelte';
  import Link from 'components/elements/Link.svelte';
  import {
    COLOR_ATTENTION,
    COLOR_PRIMARY,
    COLOR_SECONDARY,
    COLOR_SUBTLE,
  } from 'buttonStyles';
</script>

<PageLayout>
  <div class="container">
    <div class="light">
      <!-- SMALL LIGHT -->
      <div class="row">
        <Link small color={COLOR_PRIMARY}>Link</Link>
        <Link small active color={COLOR_PRIMARY}>Active</Link>
        <Link small disabled color={COLOR_PRIMARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link small color={COLOR_SECONDARY}>Link</Link>
        <Link small active color={COLOR_SECONDARY}>Active</Link>
        <Link small disabled color={COLOR_SECONDARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link small color={COLOR_SUBTLE}>Link</Link>
        <Link small active color={COLOR_SUBTLE}>Active</Link>
        <Link small disabled color={COLOR_SUBTLE}>Disabled</Link>
      </div>
      <div class="row">
        <Link small color={COLOR_ATTENTION}>Link</Link>
        <Link small active color={COLOR_ATTENTION}>Active</Link>
        <Link small disabled color={COLOR_ATTENTION}>Disabled</Link>
      </div>

      <div class="size-separator" />
      <!-- MEDIUM LIGHT -->
      <div class="row">
        <Link color={COLOR_PRIMARY}>Link</Link>
        <Link active color={COLOR_PRIMARY}>Active</Link>
        <Link disabled color={COLOR_PRIMARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link color={COLOR_SECONDARY}>Link</Link>
        <Link active color={COLOR_SECONDARY}>Active</Link>
        <Link disabled color={COLOR_SECONDARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link color={COLOR_SUBTLE}>Link</Link>
        <Link active color={COLOR_SUBTLE}>Active</Link>
        <Link disabled color={COLOR_SUBTLE}>Disabled</Link>
      </div>
      <div class="row">
        <Link color={COLOR_ATTENTION}>Link</Link>
        <Link active color={COLOR_ATTENTION}>Active</Link>
        <Link disabled color={COLOR_ATTENTION}>Disabled</Link>
      </div>

      <div class="size-separator" />
      <!-- LARGE LIGHT -->
      <div class="row">
        <Link large color={COLOR_PRIMARY}>Link</Link>
        <Link large active color={COLOR_PRIMARY}>Active</Link>
        <Link large disabled color={COLOR_PRIMARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link large color={COLOR_SECONDARY}>Link</Link>
        <Link large active color={COLOR_SECONDARY}>Active</Link>
        <Link large disabled color={COLOR_SECONDARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link large color={COLOR_SUBTLE}>Link</Link>
        <Link large active color={COLOR_SUBTLE}>Active</Link>
        <Link large disabled color={COLOR_SUBTLE}>Disabled</Link>
      </div>
      <div class="row">
        <Link large color={COLOR_ATTENTION}>Link</Link>
        <Link large active color={COLOR_ATTENTION}>Active</Link>
        <Link large disabled color={COLOR_ATTENTION}>Disabled</Link>
      </div>
    </div>
    <div class="dark">
      <!-- SMALL DARK -->
      <div class="row">
        <Link small dark color={COLOR_PRIMARY}>Link</Link>
        <Link small dark active color={COLOR_PRIMARY}>Active</Link>
        <Link small dark disabled color={COLOR_PRIMARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link small dark color={COLOR_SECONDARY}>Link</Link>
        <Link small dark active color={COLOR_SECONDARY}>Active</Link>
        <Link small dark disabled color={COLOR_SECONDARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link small dark color={COLOR_SUBTLE}>Link</Link>
        <Link small dark active color={COLOR_SUBTLE}>Active</Link>
        <Link small dark disabled color={COLOR_SUBTLE}>Disabled</Link>
      </div>
      <div class="row">
        <Link small dark color={COLOR_ATTENTION}>Link</Link>
        <Link small dark active color={COLOR_ATTENTION}>Active</Link>
        <Link small dark disabled color={COLOR_ATTENTION}>Disabled</Link>
      </div>

      <div class="size-separator" />
      <!-- MEDIUM DARK -->
      <div class="row">
        <Link dark color={COLOR_PRIMARY}>Link</Link>
        <Link dark active color={COLOR_PRIMARY}>Active</Link>
        <Link dark disabled color={COLOR_PRIMARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link dark color={COLOR_SECONDARY}>Link</Link>
        <Link dark active color={COLOR_SECONDARY}>Active</Link>
        <Link dark disabled color={COLOR_SECONDARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link dark color={COLOR_SUBTLE}>Link</Link>
        <Link dark active color={COLOR_SUBTLE}>Active</Link>
        <Link dark disabled color={COLOR_SUBTLE}>Disabled</Link>
      </div>
      <div class="row">
        <Link dark color={COLOR_ATTENTION}>Link</Link>
        <Link dark active color={COLOR_ATTENTION}>Active</Link>
        <Link dark disabled color={COLOR_ATTENTION}>Disabled</Link>
      </div>
      <div class="size-separator" />
      <!-- LARGE DARK -->
      <div class="row">
        <Link large dark color={COLOR_PRIMARY}>Link</Link>
        <Link large dark active color={COLOR_PRIMARY}>Active</Link>
        <Link large dark disabled color={COLOR_PRIMARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link large dark color={COLOR_SECONDARY}>Link</Link>
        <Link large dark active color={COLOR_SECONDARY}>Active</Link>
        <Link large dark disabled color={COLOR_SECONDARY}>Disabled</Link>
      </div>
      <div class="row">
        <Link large dark color={COLOR_SUBTLE}>Link</Link>
        <Link large dark active color={COLOR_SUBTLE}>Active</Link>
        <Link large dark disabled color={COLOR_SUBTLE}>Disabled</Link>
      </div>
      <div class="row">
        <Link large dark color={COLOR_ATTENTION}>Link</Link>
        <Link large dark active color={COLOR_ATTENTION}>Active</Link>
        <Link large dark disabled color={COLOR_ATTENTION}>Disabled</Link>
      </div>
    </div>
  </div>
</PageLayout>

<style lang="scss">
  @import '../../../styles/global.scss';

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .size-separator {
      width: 100%;
      height: 100px;
    }

    .light,
    .dark {
      padding: 20px;
      width: 600px;
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .dark {
      background-color: $B90;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
</style>
