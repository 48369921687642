<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M23.0012 31.9973C23.0012 39.7283 29.2682 45.9961 37 45.9961C44.7325 45.9961 51.0002 39.7283 51.0002 31.9958C51.0002 24.2648 44.7317 17.9971 37 17.9971C29.269 17.9971 23.0012 24.2648 23.0012 31.9973Z"
    fill="#24375B"
  />
  <path
    d="M4 20.9971H11.3208L15.4053 26.9971H18.0003M4 31.9973H22"
    stroke="white"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M7.00003 20.9971C7.00003 21.7927 6.68396 22.5558 6.12135 23.1184C5.55874 23.681 4.79567 23.9971 4.00002 23.9971C3.20436 23.9971 2.44129 23.681 1.87868 23.1184C1.31607 22.5558 1 21.7927 1 20.9971C1 20.2014 1.31607 19.4384 1.87868 18.8757C2.44129 18.3131 3.20436 17.9971 4.00002 17.9971C4.79567 17.9971 5.55874 18.3131 6.12135 18.8757C6.68396 19.4384 7.00003 20.2014 7.00003 20.9971Z"
    fill="#24375B"
  />
  <path
    d="M4 42.9971H11.3208L15.28 36.9971H22"
    stroke="white"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M6.12133 23.1184C6.68394 22.5558 7.00001 21.7927 7.00001 20.9971C7.00001 20.2014 6.68394 19.4384 6.12133 18.8757C5.55872 18.3131 4.79566 17.9971 4 17.9971C3.20435 17.9971 2.44129 18.3131 1.87868 18.8757C1.31607 19.4384 1 20.2014 1 20.9971C1 21.7927 1.31607 22.5558 1.87868 23.1184C2.44129 23.681 3.20435 23.9971 4 23.9971C4.79566 23.9971 5.55872 23.681 6.12133 23.1184Z"
    stroke="#B2D8FA"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M7.00003 42.9966C7.00003 42.2009 6.68396 41.4379 6.12135 40.8753C5.55874 40.3127 4.79567 39.9966 4.00002 39.9966C3.20436 39.9966 2.44129 40.3127 1.87868 40.8753C1.31607 41.4379 1 42.2009 1 42.9966C1 43.7922 1.31607 44.5553 1.87868 45.1179C2.44129 45.6805 3.20436 45.9966 4.00002 45.9966C4.79567 45.9966 5.55874 45.6805 6.12135 45.1179C6.68396 44.5553 7.00003 43.7922 7.00003 42.9966Z"
    fill="#24375B"
  />
  <path
    d="M7.00003 42.9966C7.00003 42.2009 6.68396 41.4379 6.12135 40.8753C5.55874 40.3127 4.79567 39.9966 4.00002 39.9966C3.20436 39.9966 2.44129 40.3127 1.87868 40.8753C1.31607 41.4379 1 42.2009 1 42.9966C1 43.7922 1.31607 44.5553 1.87868 45.1179C2.44129 45.6805 3.20436 45.9966 4.00002 45.9966C4.79567 45.9966 5.55874 45.6805 6.12135 45.1179C6.68396 44.5553 7.00003 43.7922 7.00003 42.9966Z"
    stroke="#B2D8FA"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M7.00003 31.9971C7.00003 32.7927 6.68396 33.5558 6.12135 34.1184C5.55874 34.681 4.79567 34.9971 4.00002 34.9971C3.20436 34.9971 2.44129 34.681 1.87868 34.1184C1.31607 33.5558 1 32.7927 1 31.9971C1 31.2014 1.31607 30.4384 1.87868 29.8757C2.44129 29.3131 3.20436 28.9971 4.00002 28.9971C4.79567 28.9971 5.55874 29.3131 6.12135 29.8757C6.68396 30.4384 7.00003 31.2014 7.00003 31.9971Z"
    fill="#24375B"
  />
  <path
    d="M13.3653 51.9968L20.3208 41.9971H28"
    stroke="white"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M6.12133 34.1184C6.68394 33.5558 7.00001 32.7927 7.00001 31.9971C7.00001 31.2014 6.68394 30.4384 6.12133 29.8757C5.55872 29.3131 4.79565 28.9971 4 28.9971C3.20435 28.9971 2.44129 29.3131 1.87868 29.8757C1.31607 30.4384 1 31.2014 1 31.9971C1 32.7927 1.31607 33.5558 1.87868 34.1184C2.44129 34.681 3.20435 34.9971 4 34.9971C4.79565 34.9971 5.55872 34.681 6.12133 34.1184Z"
    stroke="#B2D8FA"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M16 51.9966C16 51.2009 15.6839 50.4379 15.1213 49.8753C14.5587 49.3127 13.7956 48.9966 13 48.9966C12.2043 48.9966 11.4413 49.3127 10.8787 49.8753C10.3161 50.4379 10 51.2009 10 51.9966C10 52.7922 10.3161 53.5553 10.8787 54.1179C11.4413 54.6805 12.2043 54.9966 13 54.9966C13.7956 54.9966 14.5587 54.6805 15.1213 54.1179C15.6839 53.5553 16 52.7922 16 51.9966Z"
    fill="#24375B"
  />
  <path
    d="M13.3652 13.9976L20.3207 21.9971H28"
    stroke="white"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M15.1213 49.8757C15.6839 50.4384 16 51.2014 16 51.9971C16 52.7927 15.6839 53.5558 15.1213 54.1184C14.5587 54.681 13.7956 54.9971 13 54.9971C12.2044 54.9971 11.4413 54.681 10.8787 54.1184C10.3161 53.5558 10 52.7927 10 51.9971C10 51.2014 10.3161 50.4384 10.8787 49.8757C11.4413 49.3131 12.2044 48.9971 13 48.9971C13.7956 48.9971 14.5587 49.3131 15.1213 49.8757Z"
    stroke="#B2D8FA"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M16 13.9976C16 14.7932 15.6839 15.5563 15.1213 16.1189C14.5587 16.6815 13.7956 16.9976 13 16.9976C12.2043 16.9976 11.4413 16.6815 10.8787 16.1189C10.3161 15.5563 10 14.7932 10 13.9976C10 13.2019 10.3161 12.4388 10.8787 11.8762C11.4413 11.3136 12.2043 10.9976 13 10.9976C13.7956 10.9976 14.5587 11.3136 15.1213 11.8762C15.6839 12.4388 16 13.2019 16 13.9976Z"
    fill="#24375B"
  />
  <path
    d="M28 31.9976H22M22 36.9971H39.0002V39.9971L50.4647 32.2293L39.001 23.9973V26.9973H24.001M34 31.9976H36.0002M30.0002 31.9976H31.9997M30.0002 21.9971H31.9997M30.0002 41.9973H31.9997"
    stroke="white"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M15.1213 16.1194C15.6839 15.5568 16 14.7937 16 13.998C16 13.2024 15.6839 12.4393 15.1213 11.8767C14.5587 11.3141 13.7956 10.998 13 10.998C12.2043 10.998 11.4413 11.3141 10.8787 11.8767C10.3161 12.4393 10 13.2024 10 13.998C10 14.7937 10.3161 15.5568 10.8787 16.1194C11.4413 16.682 12.2043 16.998 13 16.998C13.7956 16.998 14.5587 16.682 15.1213 16.1194Z"
    stroke="#B2D8FA"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M38.0268 56.6339C31.8949 56.6426 25.9757 54.3866 21.4053 50.2986M42.6273 7.10986C54.2223 9.26911 63.0003 19.4399 63.0003 31.6596M61.7298 40.7961C59.6315 46.2202 55.7409 50.7632 50.704 53.6706"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M23.4004 17.3498C27.1795 13.8498 32.1425 11.9087 37.2934 11.916C48.6019 11.916 57.7714 21.0848 57.7714 32.394C57.7714 41.2065 52.2049 48.7178 44.3974 51.6068"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M22 26.9971H19.9998"
    stroke="white"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
