<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import FeeCalculator from "components/elements/FeeCalculator.svelte";
</script>

<PageSection shaded long>
  <div class="fee-calculator">
    <div class="columns">
      <div class="column image calculator-widget">
        <FeeCalculator />
      </div>
      <div class="column left">
        <Eyebrow>Smart Contracts Fee Calculator</Eyebrow>
        <Heading3>Estimate the costs of your use case</Heading3>
        <div class="calculator-details">
          <div class="calculator-part">
            <span class="subtitle">1. Select the instrument type</span>
          </div>
          <div class="calculator-part">
            <span class="subtitle">2. Select the duration of the contract</span>
          </div>
          <div class="calculator-part">
            <span class="subtitle"
              >3. Set an average size for issuance actions</span
            >
            <p>
              Contract action fees are fixed regardless of how many instruments
              are transferred in each issuance action. To get a sense of the
              total cost for these one-time action fees, estimate the average
              number of units issued to each counterparty.
            </p>
          </div>
          <div class="calculator-part">
            <span class="subtitle">4. Estimate total units outstanding</span>
            <p>
              In addition to the flat monthly fee for issuer operations, a
              metered fee is charged for the maximum number instruments
              outstanding in any given month. At higher volumes, discounted
              rates may apply.
              <br /><br />
              Note that this total includes all variations of the same instrument
              type issued under one contract. For issuing instruments with fractional
              subdivisions, multiply the total issued units outstanding by the number
              of decimal places supported.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .fee-calculator {
    @extend .generic-section;
    .calculator-details {
      margin-left: 18px;
      color: $N90;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-right: 24px;
      .title {
        display: block;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 28px;
      }
      .calculator-part {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .subtitle {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        p {
          font-size: 16px;
          color: $N80;
          margin-bottom: 16px;
          margin-top: 0px;
        }
      }
    }
    .calculator-widget {
      padding: 0px;
      min-width: 288px;
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .columns {
      flex-direction: column-reverse !important ;
    }
  }
</style>
