<script>
  export let width = 64;
  export let height = 64;

  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M32.0004 11.0002H46.137C46.6943 15 49.9995 17.6377 54 18.1942V38.3488C54 49.1308 34.7589 58.4089 32.0004 58.4089C29.2419 58.4089 10 49.1315 10 38.3488V18.1942C14.0005 17.6369 17.3057 15 17.8637 11.0002H32.0004Z"
    fill="white"
  />
  <path
    d="M32.0004 11.0002H46.137C46.6943 15 49.9995 17.6377 54 18.1942V38.3488C54 49.1308 34.7589 58.4089 32.0004 58.4089C29.2419 58.4089 10 49.1315 10 38.3488V18.1942C14.0005 17.6369 17.3057 15 17.8637 11.0002H32.0004Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M59.9999 29.0001V38.3496C59.9999 45.146 55.5825 51.6515 47.2238 57.5795M39.0001 6H47.0003M17.0004 6H24.9999M16.7769 57.5802C8.41823 51.6515 4 45.1453 4 38.3503V29.0001"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M45.6937 33.3912C45.6937 41.4649 39.1477 48.0109 31.074 48.0109C22.9996 48.0109 16.4536 41.4649 16.4536 33.3912C16.4536 25.316 22.9996 18.7708 31.074 18.7708C32.913 18.7708 34.6733 19.1113 36.294 19.7301"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M45.1806 18.8693L32.0002 32.049L26.5087 26.5583L22.1152 30.951L32.0002 40.8359L49.5726 23.2635L45.1799 18.8693"
    fill="#B2D8FA"
  />
  <path
    d="M45.1806 18.8693L32.0002 32.049L26.5087 26.5583L22.1152 30.951L32.0002 40.8359L49.5726 23.2635L45.1806 18.8693Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M35.0003 6H36.9998M59.9999 17.0002V18.9997V17.0002ZM59.9999 20.9999V23.0001V20.9999ZM59.9999 24.9996V26.9999V24.9996ZM4 17.0002V18.9997V17.0002ZM4 20.9999V23.0001V20.9999ZM4 24.9996V26.9999V24.9996ZM30.9998 6H33.0001H30.9998ZM27.0001 6H29.0004H27.0001Z"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
