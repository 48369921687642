<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection shaded>
  <div class="multi-approvals">
    <div class="columns">
      <div class="column left">
        <Eyebrow>Multi-Party Approvals</Eyebrow>
        <Heading3>Customizable authorization schemes</Heading3>
        <Body>
          Our wallet facilitates the easy creation of multi-party, and
          customizable, authorization schemes that are managed and validated by
          the operators of the distributed ledger.
          <br /><br />These authorization schemes can be used to control digital
          assets, and also to perform other types of commercial actions,
          depending on the role of the user, such as voting on a proposed
          resolution, or freezing tokens to comply with a court order.
          <br /><br />Our platform also leverages users’ mobile devices for 2FA,
          as well as the generation, storage, and management of their private
          keys. This ‘non-custodial’ approach ensures no one ever has access to
          any of the users’ private keys, and important action approvals require
          users’ desktop and mobile devices.
          <br /><br />Different risk management methods can be used including a
          mix of cold and hot storage techniques, varying custodial and escrow
          arrangements, and software agents for automatic authorizations.
        </Body>
        <div class="column right image mobile-visible">
          <ImageFrame
            width={560}
            shaded={false}
            src="/images/authorization-diagram.svg"
          />
        </div>
        <div class="sub-icon-row">
          <IconColumn icon="signature">
            All authorization schemes utilize on-chain scripts to allow for an
            immutable record of authorized actions.
          </IconColumn>
          <IconColumn icon="access-key">
            An improved security architecture where a distributed ledger is used
            for distributed authentication for each user.
          </IconColumn>
        </div>
      </div>
      <div class="column right image tablet-visible">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/authorization-diagram.svg"
        />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .multi-approvals {
    @extend .generic-section;
    background-color: $I05;
  }
</style>
