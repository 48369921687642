<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M53.2163 55.2742C45.8003 61.5884 36.096 63.8122 27.0668 61.9485M14.4735 8.97969C22.0388 3.09969 31.7198 1.24493 40.6208 3.41468M15.3173 56.775C14.4814 56.1702 13.6764 55.5238 12.9053 54.8384L15.3173 56.775ZM20.2463 59.6797C19.2648 59.2197 18.3087 58.7074 17.382 58.1452L20.2463 59.6797ZM25.1303 61.482C24.146 61.2122 23.176 60.8927 22.224 60.525L25.1303 61.482ZM52.2435 9.05618C53.2286 9.82772 54.1659 10.6585 55.05 11.5439L52.2435 9.05618ZM47.4 5.94669C48.3993 6.46314 49.3695 7.03397 50.3063 7.65666L47.4 5.94669ZM42.558 3.95468C43.5405 4.26443 44.5103 4.62292 45.4628 5.03317L42.558 3.95468Z"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M58 32.1965C58 46.28 46.5835 57.6965 32.5 57.6965C18.4172 57.6965 7 46.28 7 32.1965C7 18.1138 18.4172 6.69653 32.5 6.69653C46.5835 6.69653 58 18.1138 58 32.1965Z"
    fill="#EEF0F2"
  />
  <path
    d="M61.2898 25.1958L34.007 12.5658C33.6031 12.3776 33.163 12.28 32.7174 12.28C32.2718 12.28 31.8316 12.3776 31.4277 12.5658L3.95 25.1958C2.85275 25.7028 2 26.8016 2 28.0106V30.6565C2 31.8655 2.86401 32.9636 3.96051 33.4706L31.37 46.102C32.195 46.483 33.1865 46.483 34.0115 46.102L61.2695 33.4706C62.3668 32.9636 62.9998 31.8655 62.9998 30.6565V28.0106C62.9998 26.8016 62.387 25.7028 61.2898 25.1958Z"
    fill="#B2D8FA"
  />
  <path
    d="M29.9998 38.6966L8.99976 27.6963M61.2898 25.1958L34.007 12.5658C33.6031 12.3776 33.163 12.28 32.7174 12.28C32.2718 12.28 31.8316 12.3776 31.4277 12.5658L3.95 25.1958C2.85275 25.7028 2 26.8016 2 28.0106V30.6565C2 31.8655 2.86401 32.9636 3.96051 33.4706L31.37 46.102C32.195 46.483 33.1865 46.483 34.0115 46.102L61.2695 33.4706C62.3668 32.9636 62.9998 31.8655 62.9998 30.6565V28.0106C62.9998 26.8016 62.387 25.7028 61.2898 25.1958V25.1958ZM35 38.6966L56 27.6963L35 38.6966Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M32.799 21.0798L24 25.0324V25.3369L33.123 29.4394L42 25.4493V25.2168L32.799 21.0798Z"
    fill="white"
  />
  <path
    d="M32.799 21.0798L24 25.0324V25.3369L33.123 29.4394L42 25.4493V25.2168L32.799 21.0798Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M44.0001 40.6968C43.729 40.1766 43.2644 39.7837 42.7064 39.6029C42.1484 39.422 41.5416 39.4676 41.0169 39.7299C40.4922 39.9921 40.0915 40.45 39.9012 41.0049C39.7109 41.5598 39.7463 42.1672 39.9996 42.6963L41.9999 45.6963V48.6963C41.9999 49.2267 42.2106 49.7353 42.5856 50.1104C42.9607 50.4854 43.4693 50.6961 43.9997 50.6961C44.5301 50.6961 45.0388 50.4854 45.4139 50.1104C45.7889 49.7353 45.9996 49.2267 45.9996 48.6963V43.6968L44.0001 40.6968Z"
    fill="white"
  />
  <path
    d="M44.0001 40.6968C43.729 40.1766 43.2644 39.7837 42.7064 39.6029C42.1484 39.422 41.5416 39.4676 41.0169 39.7299C40.4922 39.9921 40.0915 40.45 39.9012 41.0049C39.7109 41.5598 39.7463 42.1672 39.9996 42.6963L41.9999 45.6963V48.6963C41.9999 49.2267 42.2106 49.7353 42.5856 50.1104C42.9607 50.4854 43.4693 50.6961 43.9997 50.6961C44.5301 50.6961 45.0388 50.4854 45.4139 50.1104C45.7889 49.7353 45.9996 49.2267 45.9996 48.6963V43.6968L44.0001 40.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M48.0001 38.6968C47.7276 38.1791 47.263 37.7888 46.706 37.6097C46.1491 37.4305 45.5441 37.4767 45.0208 37.7384C44.4975 38.0001 44.0977 38.4564 43.9069 39.0095C43.7161 39.5626 43.7497 40.1684 44.0004 40.697L45.9999 43.697V46.697C45.9999 47.2275 46.2106 47.7363 46.5857 48.1114C46.9609 48.4866 47.4696 48.6973 48.0001 48.6973C48.5306 48.6973 49.0394 48.4866 49.4145 48.1114C49.7896 47.7363 50.0004 47.2275 50.0004 46.697V41.6968L48.0001 38.6968Z"
    fill="white"
  />
  <path
    d="M48.0001 38.6968C47.7276 38.1791 47.263 37.7888 46.706 37.6097C46.1491 37.4305 45.5441 37.4767 45.0208 37.7384C44.4975 38.0001 44.0977 38.4564 43.9069 39.0095C43.7161 39.5626 43.7497 40.1684 44.0004 40.697L45.9999 43.697V46.697C45.9999 47.2275 46.2106 47.7363 46.5857 48.1114C46.9609 48.4866 47.4696 48.6973 48.0001 48.6973C48.5306 48.6973 49.0394 48.4866 49.4145 48.1114C49.7896 47.7363 50.0004 47.2275 50.0004 46.697V41.6968L48.0001 38.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M51.9997 36.6963C51.7271 36.1786 51.2625 35.7883 50.7056 35.6092C50.1486 35.43 49.5436 35.4762 49.0203 35.7379C48.4971 35.9996 48.0972 36.4559 47.9064 37.009C47.7156 37.5621 47.7492 38.1679 47.9999 38.6966L50.0002 41.6966V44.6966C50.0002 45.227 50.2109 45.7357 50.5859 46.1107C50.961 46.4858 51.4696 46.6965 52 46.6965C52.5304 46.6965 53.0391 46.4858 53.4142 46.1107C53.7892 45.7357 53.9999 45.227 53.9999 44.6966V39.6963L51.9997 36.6963Z"
    fill="white"
  />
  <path
    d="M51.9997 36.6963C51.7271 36.1786 51.2625 35.7883 50.7056 35.6092C50.1486 35.43 49.5436 35.4762 49.0203 35.7379C48.4971 35.9996 48.0972 36.4559 47.9064 37.009C47.7156 37.5621 47.7492 38.1679 47.9999 38.6966L50.0002 41.6966V44.6966C50.0002 45.227 50.2109 45.7357 50.5859 46.1107C50.961 46.4858 51.4696 46.6965 52 46.6965C52.5304 46.6965 53.0391 46.4858 53.4142 46.1107C53.7892 45.7357 53.9999 45.227 53.9999 44.6966V39.6963L51.9997 36.6963Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M56.0001 34.6968C55.729 34.1766 55.2644 33.7837 54.7064 33.6029C54.1483 33.422 53.5416 33.4676 53.0169 33.7299C52.4922 33.9921 52.0915 34.45 51.9012 35.0049C51.7109 35.5598 51.7463 36.1672 51.9996 36.6963L53.9999 39.6963V42.6963C53.9999 43.2267 54.2106 43.7353 54.5856 44.1104C54.9607 44.4854 55.4693 44.6961 55.9997 44.6961C56.5301 44.6961 57.0388 44.4854 57.4138 44.1104C57.7889 43.7353 57.9996 43.2267 57.9996 42.6963V37.6968L56.0001 34.6968Z"
    fill="white"
  />
  <path
    d="M56.0001 34.6968C55.729 34.1766 55.2644 33.7837 54.7064 33.6029C54.1483 33.422 53.5416 33.4676 53.0169 33.7299C52.4922 33.9921 52.0915 34.45 51.9012 35.0049C51.7109 35.5598 51.7463 36.1672 51.9996 36.6963L53.9999 39.6963V42.6963C53.9999 43.2267 54.2106 43.7353 54.5856 44.1104C54.9607 44.4854 55.4693 44.6961 55.9997 44.6961C56.5301 44.6961 57.0388 44.4854 57.4138 44.1104C57.7889 43.7353 57.9996 43.2267 57.9996 42.6963V37.6968L56.0001 34.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M60.0001 32.6968C59.7276 32.1791 59.263 31.7888 58.706 31.6097C58.1491 31.4305 57.5441 31.4767 57.0208 31.7384C56.4975 32.0001 56.0977 32.4564 55.9069 33.0095C55.7161 33.5626 55.7497 34.1684 56.0004 34.697L57.9999 37.697V40.697C57.9999 41.2275 58.2106 41.7363 58.5857 42.1114C58.9609 42.4866 59.4696 42.6973 60.0001 42.6973C60.5306 42.6973 61.0394 42.4866 61.4145 42.1114C61.7896 41.7363 62.0004 41.2275 62.0004 40.697V35.6968L60.0001 32.6968Z"
    fill="white"
  />
  <path
    d="M60.0001 32.6968C59.7276 32.1791 59.263 31.7888 58.706 31.6097C58.1491 31.4305 57.5441 31.4767 57.0208 31.7384C56.4975 32.0001 56.0977 32.4564 55.9069 33.0095C55.7161 33.5626 55.7497 34.1684 56.0004 34.697L57.9999 37.697V40.697C57.9999 41.2275 58.2106 41.7363 58.5857 42.1114C58.9609 42.4866 59.4696 42.6973 60.0001 42.6973C60.5306 42.6973 61.0394 42.4866 61.4145 42.1114C61.7896 41.7363 62.0004 41.2275 62.0004 40.697V35.6968L60.0001 32.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M21.8818 40.6971C22.4338 39.5923 23.7178 39.1446 24.8218 39.6966C25.9265 40.2493 26.4628 41.5926 25.9108 42.6966L23.9998 45.6966V48.6966C23.9896 49.2203 23.7745 49.7191 23.4005 50.0859C23.0266 50.4526 22.5237 50.6581 21.9999 50.6581C21.4761 50.6581 20.9732 50.4526 20.5993 50.0859C20.2253 49.7191 20.0101 49.2203 20 48.6966V43.6971L21.8818 40.6971Z"
    fill="white"
  />
  <path
    d="M21.8818 40.6971C22.4338 39.5923 23.7178 39.1446 24.8218 39.6966C25.9265 40.2493 26.4628 41.5926 25.9108 42.6966L23.9998 45.6966V48.6966C23.9896 49.2203 23.7745 49.7191 23.4005 50.0859C23.0266 50.4526 22.5237 50.6581 21.9999 50.6581C21.4761 50.6581 20.9732 50.4526 20.5993 50.0859C20.2253 49.7191 20.0101 49.2203 20 48.6966V43.6971L21.8818 40.6971Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M17.8825 38.6968C18.4345 37.5921 19.7185 37.1443 20.8225 37.6971C21.9272 38.2491 22.4635 39.5923 21.9115 40.6971L20.0005 43.6971V46.6971C20.0005 47.2276 19.7898 47.7364 19.4146 48.1115C19.0395 48.4866 18.5307 48.6973 18.0002 48.6973C17.4697 48.6973 16.961 48.4866 16.5858 48.1115C16.2107 47.7364 16 47.2276 16 46.6971V41.6968L17.8825 38.6968Z"
    fill="white"
  />
  <path
    d="M17.8825 38.6968C18.4345 37.5921 19.7185 37.1443 20.8225 37.6971C21.9272 38.2491 22.4635 39.5923 21.9115 40.6971L20.0005 43.6971V46.6971C20.0005 47.2276 19.7898 47.7364 19.4146 48.1115C19.0395 48.4866 18.5307 48.6973 18.0002 48.6973C17.4697 48.6973 16.961 48.4866 16.5858 48.1115C16.2107 47.7364 16 47.2276 16 46.6971V41.6968L17.8825 38.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M13.8818 36.6968C14.4338 35.5928 15.7178 35.1451 16.8218 35.6971C17.9273 36.2498 18.4628 37.5923 17.9108 38.6971L15.9998 41.6971V44.6971C15.9896 45.2208 15.7745 45.7196 15.4005 46.0863C15.0266 46.4531 14.5237 46.6586 13.9999 46.6586C13.4761 46.6586 12.9732 46.4531 12.5992 46.0863C12.2253 45.7196 12.0101 45.2208 12 44.6971V39.6968L13.8825 36.6968"
    fill="white"
  />
  <path
    d="M13.8818 36.6968C14.4338 35.5928 15.7178 35.1451 16.8218 35.6971C17.9273 36.2498 18.4628 37.5923 17.9108 38.6971L15.9998 41.6971V44.6971C15.9896 45.2208 15.7745 45.7196 15.4005 46.0863C15.0266 46.4531 14.5237 46.6586 13.9999 46.6586C13.4761 46.6586 12.9732 46.4531 12.5992 46.0863C12.2253 45.7196 12.0101 45.2208 12 44.6971V39.6968L13.8818 36.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M9.88176 34.6971C10.4338 33.5923 11.7178 33.1446 12.8218 33.6966C13.9265 34.2493 14.4628 35.5926 13.9108 36.6966L11.9998 39.6966V42.6966C11.9896 43.2203 11.7745 43.7191 11.4005 44.0859C11.0266 44.4526 10.5237 44.6581 9.99989 44.6581C9.47609 44.6581 8.97321 44.4526 8.59926 44.0859C8.22531 43.7191 8.01014 43.2203 8 42.6966V37.6971L9.88176 34.6971Z"
    fill="white"
  />
  <path
    d="M9.88176 34.6971C10.4338 33.5923 11.7178 33.1446 12.8218 33.6966C13.9265 34.2493 14.4628 35.5926 13.9108 36.6966L11.9998 39.6966V42.6966C11.9896 43.2203 11.7745 43.7191 11.4005 44.0859C11.0266 44.4526 10.5237 44.6581 9.99989 44.6581C9.47609 44.6581 8.97321 44.4526 8.59926 44.0859C8.22531 43.7191 8.01014 43.2203 8 42.6966V37.6971L9.88176 34.6971Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M5.88248 32.6968C6.43448 31.5921 7.71848 31.1443 8.82248 31.6971C9.92723 32.2491 10.4635 33.5923 9.91148 34.6971L8.00048 37.6971V40.6971C8.00048 41.2276 7.78975 41.7364 7.41463 42.1115C7.03951 42.4866 6.53074 42.6973 6.00024 42.6973C5.46974 42.6973 4.96096 42.4866 4.58584 42.1115C4.21073 41.7364 4 41.2276 4 40.6971V35.6968L5.88248 32.6968Z"
    fill="white"
  />
  <path
    d="M5.88248 32.6968C6.43448 31.5921 7.71848 31.1443 8.82248 31.6971C9.92723 32.2491 10.4635 33.5923 9.91147 34.6971L8.00048 37.6971V40.6971C8.00048 41.2276 7.78975 41.7364 7.41463 42.1115C7.03951 42.4866 6.53074 42.6973 6.00024 42.6973C5.46974 42.6973 4.96096 42.4866 4.58584 42.1115C4.21073 41.7364 4 41.2276 4 40.6971V35.6968L5.88248 32.6968Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
