<script>
  import MenuItem from "components/elements/MenuItem.svelte";
  import { MENU_CONTENTS } from "utils/menu";

  export let activeButtonIdx;
  export let afterRedirect = () => {};

  let buttonOptions;
  $: buttonOptions = MENU_CONTENTS[activeButtonIdx] || [];
</script>

<div class="menu-contents">
  {#each buttonOptions as item}
    <MenuItem
      icon={item.icon}
      title={item.title}
      link={item.link}
      {afterRedirect}
    >
      {item.description}
    </MenuItem>
  {/each}
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .menu-contents {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  :global(.menu-item:not(:last-child)) {
    margin-bottom: 4px;
  }
</style>
