<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading4 from "components/elements/aliases/Heading4.svelte";
  import IconBox from "components/widgets/IconBox.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection short>
  <div class="platform-tour">
    <div class="container">
      <div class="columns top">
        <div class="column">
          <div>
            <Eyebrow dark>Platform Tour</Eyebrow>
            <Heading4 dark>Dig deeper into what we do</Heading4>
          </div>
        </div>
      </div>
      <div class="columns bottom">
        <div class="column left">
          <IconBox
            dark
            icon="wallet"
            colorName="B40"
            href="/platform/wallet/"
            title="Digital Wallet"
            class="link-hover"
          >
            Manage digital assets, liabilities, and equity
          </IconBox>
          <IconBox
            dark
            icon="settings"
            colorName="B40"
            href="/platform/contracts/"
            title="Smart Contract Engine"
            class="link-hover"
          >
            Draft, issue, and manage smart contracts
          </IconBox>
        </div>
        <div class="column right">
          <IconBox
            dark
            icon="items"
            colorName="B40"
            href="/platform/tokenization/"
            title="Tokenization"
            class="link-hover"
          >
            Tokenize any security or utility instrument
          </IconBox>

          <IconBox
            dark
            icon="application"
            colorName="B40"
            href="/platform/compliance/"
            title="Compliance"
            class="link-hover"
          >
            Ensure strict compliance with laws and regulations
          </IconBox>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .platform-tour {
    @extend .generic-section;
    @media only screen and (min-width: $size-desktop-min) {
      padding: 32px 0px;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 128px 0px;
      background-color: $I85;
      box-shadow:
        0px 30px 60px -12px rgba(63, 73, 89, 0.2),
        0px 18px 36px -18px rgba(0, 0, 0, 0.15);

      .column {
        gap: 16px;
      }

      @media only screen and (min-width: $size-desktop-min) {
        padding: 64px 96px;
        border-radius: 16px;
      }
      @media only screen and (max-width: $size-laptop-max) {
        width: 100%;
        box-shadow: none;
      }
      @media only screen and (max-width: $size-tablet-max) {
        .columns {
          flex-direction: row;
          gap: 48px;
        }
      }
      @media only screen and (max-width: $size-phone-max) {
        .columns {
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }
</style>
