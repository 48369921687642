<script>
  import Anchor from "./Anchor.svelte";
  import {
    LEGAL_DOCS_URL,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
  } from "utils/menu";

  let year = new Date().getFullYear();
</script>

<div class="legal">
  <div class="copyrights">
    <span>Copyright © {year} Tokenized. All rights reserved.</span>
  </div>
  <div class="legal-links">
    <Anchor href={TERMS_AND_CONDITIONS}>Legal Documents</Anchor>
    <Anchor href={PRIVACY_POLICY}>Privacy Policy</Anchor>
  </div>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .legal {
    display: flex;
    flex-direction: row;
    gap: 18px;
    .legal-links {
      display: flex;
      flex-direction: row;
      gap: 18px;
      :global(a) {
        color: $N40;
      }
    }
    @media only screen and (max-width: $size-phablet-max) {
      flex-direction: column;
    }
  }
</style>
