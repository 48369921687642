<script>
  import PageLayout from 'components/PageLayout.svelte';
  import Icon, { ICONS } from 'components/elements/Icon';
</script>

<PageLayout>
  <div class="container">
    <div class="light">
      {#each Object.keys(ICONS) as name}
        <div class="row">
          <Icon {name} />
        </div>
      {/each}
    </div>
  </div>
</PageLayout>

<style lang="scss">
  @import '../../../styles/global.scss';

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $N15;

    .light {
      padding: 20px;
      width: 600px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
</style>
