<script>
  import { PROTOCOL_DOCS_URL } from "utils/menu";
  import { PROTOCOL_VERSION, PROTOCOL_DOCS_SOURCE } from "utils/document";
  import Document from "components/pages/Documents/Document.svelte";

  // Retrieve active page or default to intro
  export let params = { wild: "intro/preface" };
  export let defaultSubPage = "intro/preface";
</script>

<Document
  documentSourceUrl={PROTOCOL_DOCS_SOURCE}
  documentVersion={PROTOCOL_VERSION}
  baseDocumentUrl={PROTOCOL_DOCS_URL}
  menuTitle="Tokenized Protocol"
  {params}
  {defaultSubPage}
/>

<style lang="scss">
  @import "../../../../styles/global.scss";
</style>
