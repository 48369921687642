<script>
  export let width = 155;
  export let height = 32;
  export let color = "";
  color;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM22.75 7V11.75H18.375V27H13.625V11.75L9.25 11.75V7L22.75 7Z"
    fill="#1B3B6F"
  />
  <path
    d="M59.7115 11.1154V8H45V11.1154H50.5385V25.6044H54.1731V11.1154H59.7115Z"
    fill="#1B3B6F"
  />
  <path
    d="M64.0539 12.6484C60.1226 12.6484 57.6253 15.2445 57.6253 19.3242C57.6253 23.4038 60.1226 26 64.0539 26C67.9852 26 70.4825 23.4038 70.4825 19.3242C70.4825 15.2445 67.9852 12.6484 64.0539 12.6484ZM64.0539 15.3187C65.76 15.3187 66.8479 16.8764 66.8479 19.3242C66.8479 21.772 65.76 23.3297 64.0539 23.3297C62.3479 23.3297 61.26 21.772 61.26 19.3242C61.26 16.8764 62.3479 15.3187 64.0539 15.3187Z"
    fill="#1B3B6F"
  />
  <path
    d="M84.2753 25.6044L78.9841 18.8297L83.7561 13.044H79.8495L75.7946 18.1126H75.7451V8H72.2836V25.6044H75.7451V19.7198H75.7946L79.9978 25.6044H84.2753Z"
    fill="#1B3B6F"
  />
  <path
    d="M89.7545 23.5522C88.271 23.5522 87.01 22.6374 86.9111 20.0412H95.9358C95.9358 18.6566 95.8122 17.6923 95.5402 16.8269C94.749 14.2802 92.6226 12.6484 89.7298 12.6484C85.7984 12.6484 83.499 15.5165 83.499 19.2253C83.499 23.2308 85.7984 26 89.7545 26C93.0924 26 95.2682 24.0962 95.7627 21.7967H92.227C92.0539 22.8104 91.1391 23.5522 89.7545 23.5522ZM89.7298 15.0962C91.1144 15.0962 92.2517 15.9615 92.4743 17.8654H86.9853C87.2572 15.9615 88.3204 15.0962 89.7298 15.0962Z"
    fill="#1B3B6F"
  />
  <path
    d="M104.719 12.7473C103.112 12.7473 101.851 13.5137 101.035 14.6264H100.986V13.044H97.7467V25.6044H101.208V18.8791C101.208 16.6044 102.247 15.467 103.681 15.467C105.041 15.467 105.881 16.3324 105.881 18.2363V25.6044H109.343V17.8901C109.343 14.478 107.538 12.7473 104.719 12.7473Z"
    fill="#1B3B6F"
  />
  <path
    d="M115.292 10.967V8H111.83V10.967H115.292ZM115.292 25.6044V13.044H111.83V25.6044H115.292Z"
    fill="#1B3B6F"
  />
  <path
    d="M127.268 15.8626V13.044H117.18V15.7637H123.09V15.8132L116.933 22.7857V25.6044H127.515V22.8846H121.112V22.8352L127.268 15.8626Z"
    fill="#1B3B6F"
  />
  <path
    d="M134.403 23.5522C132.919 23.5522 131.658 22.6374 131.559 20.0412H140.584C140.584 18.6566 140.461 17.6923 140.189 16.8269C139.397 14.2802 137.271 12.6484 134.378 12.6484C130.447 12.6484 128.147 15.5165 128.147 19.2253C128.147 23.2308 130.447 26 134.403 26C137.741 26 139.917 24.0962 140.411 21.7967H136.875C136.702 22.8104 135.788 23.5522 134.403 23.5522ZM134.378 15.0962C135.763 15.0962 136.9 15.9615 137.123 17.8654H131.634C131.906 15.9615 132.969 15.0962 134.378 15.0962Z"
    fill="#1B3B6F"
  />
  <path
    d="M154.06 25.6044V8H150.598V14.5522H150.549C149.782 13.3654 148.422 12.7225 146.988 12.7225C143.848 12.7225 141.821 15.3187 141.821 19.3242C141.821 23.3297 143.873 25.9258 147.038 25.9258C148.497 25.9258 149.881 25.2335 150.648 23.9478H150.697V25.6044H154.06ZM148.15 15.3681C149.708 15.3681 150.771 16.8269 150.771 19.3242C150.771 21.8214 149.708 23.2802 148.15 23.2802C146.494 23.2802 145.431 21.7225 145.431 19.3242C145.431 16.9258 146.494 15.3681 148.15 15.3681Z"
    fill="#1B3B6F"
  />
</svg>
