<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection>
  <div class="automate-tasks">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Lifecycle Management</Eyebrow>
        <Heading3>An enhanced contracting experience</Heading3>
        <Body>
          Contracts are structured and digital such that software can be
          leveraged to improve the collaborative processes required to draft,
          review, execute, amend, and manage different types of contracts over
          the lifecycle.
          <br /><br />Scattered emails and messages are a major hindrance in
          our increasingly interconnected world. Decisions involving multiple
          stakeholders need to be made quickly and with the latest shared
          information.
          <br /><br />Our Smart Contracting Platform makes collaboration and
          negotiation more effective by reducing friction and improving
          communication with better insights.
        </Body>
      </div>
      <div class="column left image">
        <ImageFrame width={560} src="/images/contract-timeline.webp" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .automate-tasks {
    @extend .generic-section;
    
    .columns {
      flex-direction: row-reverse;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }
  }
</style>
