<script>
  export let src = false;
  export let alt = "";
</script>

<div class="team-image">
  <img {src} {alt} />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .team-image {
    display: block;
    img {
      width: 264px;
      height: auto;
      border-radius: 8px;
      margin-bottom: 24px;
    }
  }
</style>
