<script>
  import Header from "components/widgets/Header.svelte";

  export let dark = false;
  export let paddedHeader = false;
</script>

<div class="header-container">
  <Header {dark} padded={paddedHeader} />
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .header-container {
    display: flex;
    justify-content: center;
  }
</style>
