<script>
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection dark>
  <div class="middle-summary">
    <div class="columns icon-row">
      <div class="icon-column">
        <IconColumn icon="coins" heading4="$0.001 / transfer" dark>
          Ultra-low and reliable network fees. Paid directly by end users, or
          invoiced monthly.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="investment" heading4="Settled in 2 seconds" dark>
          Our proprietary network monitoring solutions and risk mitigation
          algorithms enables reliable settlement in 2 seconds.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="exchange" heading4="Atomic swaps" dark>
          The on-chain equivalent of delivery versus payment, except there is no central
          clearing counterparty required, and no settlement risk.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="technology" heading4="150k++ txns/sec" dark>
          Proven ultra-high throughput with the capacity to handle millions of
          transactions per second.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .middle-summary {
    @extend .generic-section;
  }
</style>
