<script>
  export let dark = false;
  export let name = false;
  export let position = false;
</script>

<div class="team-name {dark ? 'dark' : ''}">{name}</div>
<div class="team-position {dark ? 'dark' : ''}">{position}</div>
<div><slot /></div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .team-name {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: $N95;

    &.dark {
      color: $N15;
    }
  }
  .team-position {
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $N80;

    &.dark {
      color: $N15;
    }
  }
</style>
