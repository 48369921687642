<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TeamMember from "components/widgets/TeamMember.svelte";
  import Link from "components/elements/Link.svelte";
  import { COLOR_PRIMARY } from "buttonStyles";
  import TeamJSON from "assets/json/team.json";
</script>

<PageSection>
  <div class="about-team">
    <div class="columns">
      <div class="column">
        <div class="title">
          <Eyebrow>Team</Eyebrow>
          <Heading3>Meet the people behind Tokenized</Heading3>
        </div>
      </div>
      <div class="column">
        <div class="team wrap">
          {#each TeamJSON as member}
            <TeamMember
              src={member.image}
              name={member.name}
              position={member.position}
            />
          {/each}
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column careers">
        <div class="title">
          <Eyebrow>Careers</Eyebrow>
          <Heading3>Tokenized is hiring</Heading3>
          <Body
            >We're always on the lookout for exceptional individuals that want
            to join us on our mission. If you are interested in becoming a part of our team, please
            send a CV and cover letter to
            <Link
              color={COLOR_PRIMARY}
              href="mailto:hr@tokenized.com">
              hr@tokenized.com
            </Link>
          </Body>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .about-team {
    @extend .generic-section;

    .columns {
      flex-direction: column;

      .column {
        width: 100%;

        &.careers {
          max-width: 948px;

          :global(a) {
            display: inline-flex;
          }
        }

        .team {
          flex-direction: row;
          gap: 0px 48px;
        }
      }
    }
  }
</style>
