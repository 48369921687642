<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M62 36.5371C62 50.3446 50.807 61.5376 37.0002 61.5376C23.1927 61.5376 12.0005 50.3446 12.0005 36.5371C12.0005 22.7304 23.1935 11.5374 37.0002 11.5374C50.8077 11.5374 62 22.7304 62 36.5371Z"
    fill="#EEF0F2"
  />
  <path
    d="M41 2.53735H3.00049C2.44699 2.53735 2 2.9851 2 3.5371V56.5374C2 57.0894 2.44699 57.5371 3.00049 57.5371H41C41.5535 57.5371 42.0005 57.0894 42.0005 56.5374V3.5371C42.0005 2.9851 41.5535 2.53735 41 2.53735Z"
    fill="white"
  />
  <path
    d="M42.0005 8.53735V3.5371C42.0005 2.9851 41.5535 2.53735 41 2.53735H3.00049C2.44699 2.53735 2 2.9851 2 3.5371V8.53735H42.0005Z"
    fill="#B2D8FA"
  />
  <path
    d="M42.0005 14.5374V3.5371C42.0005 2.9851 41.5535 2.53735 41 2.53735H3.00049C2.44699 2.53735 2 2.9851 2 3.5371V56.5374C2 57.0894 2.44699 57.5371 3.00049 57.5371H41C41.5535 57.5371 42.0005 57.0894 42.0005 56.5374V45.5371"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M13.0005 5.53735H15.0007M4.00049 8.53735H40.0005H4.00049ZM5.00024 5.53735H7.00049H5.00024ZM9.00073 5.53735H11.0002H9.00073Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M28.9473 45.5151C26.8293 42.0628 27.4623 35.7808 28.2153 33.4941L30.3191 32.9781C30.3191 32.9781 29.7813 29.7921 32.1941 26.7043C35.0921 22.9963 43.3638 15.6208 59.2788 14.7478C53.4641 21.2338 49.3128 32.2828 48.2763 33.9088C46.1111 35.3788 44.0021 36.3771 44.0021 36.3771L45.9273 38.2491C45.9273 38.2491 35.4303 47.2828 28.9473 45.5151Z"
    fill="#B2D8FA"
  />
  <path
    d="M46.9025 23.9196C34.769 33.1199 28.4795 44.7719 25.9722 52.4211M25.0002 14.5371H38M25.0002 19.5374H32M6.00049 32.5371H22.0002M6.00049 37.5374H22.0002M6.00049 42.5369H22.0002M28.9475 45.5151C26.8295 42.0629 27.4625 35.7809 28.2155 33.4941L30.3192 32.9781C30.3192 32.9781 29.7815 29.7921 32.1942 26.7044C35.0922 22.9964 43.364 15.6209 59.279 14.7479C53.4642 21.2339 49.313 32.2829 48.2765 33.9089C46.1112 35.3789 44.0022 36.3771 44.0022 36.3771L45.9275 38.2491C45.9275 38.2491 35.4305 47.2829 28.9475 45.5151ZM21.0005 26.5371H7.00024V13.5374H21.0005V26.5371Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
