<script>
  export let dark = false;
</script>

<div class="eyebrow {dark ? 'dark' : ''}"><slot /></div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .eyebrow {
    font-size: 17px;
    line-height: 24px;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 15px;
      line-height: 23px;
    }
    font-weight: 600;
    text-transform: uppercase;
    color: $B60;
    padding-left: 16px;
    padding-bottom: 18px;
    letter-spacing: 0.03em;

    &.dark {
      color: $B35;
    }
  }
</style>
