<script>
  export let dark = false;
  export let shaded = false;
  export let short = false;
  export let medium = false;
  export let long = false;
</script>

<div
  class="section {shaded ? 'shaded' : ''}{short ? 'short' : ''}
  {medium ? 'medium' : ''}{long ? 'long' : ''}{dark ? 'dark' : ''}"
>
  <slot />
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .section {
    display: flex;
    flex-direction: column;
    padding: 144px 0px 128px;
    align-items: center;
    @media only screen and (max-width: $size-tablet-max) {
      padding: 128px 0px;
    }
    @media only screen and (max-width: $size-phone-max) {
      padding: 80px 0px;
    }

    &.shaded {
      background-color: $I05;
    }
    &.dark {
      color: $B10;
      background-color: $I90;
    }
    &.short {
      padding: 0px 0px;
    }
    &.medium {
      padding: 128px 0px;
    }
    &.long {
      padding: 160px 0px 128px;

      @media only screen and (max-width: $size-tablet-max) {
        padding: 80px 0px;
      }
    }
  }
</style>
