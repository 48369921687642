<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Link from "components/elements/Link.svelte";
  import { COLOR_ATTENTION } from "buttonStyles";
  import { TRAVEL_RULE_URL } from "utils/menu";
</script>

<PageSection>
  <div class="travel-rule">
    <div class="columns">
      <div class="column left image">
        <ImageFrame width={448} shaded={false} src="/images/globe.webp" />
      </div>
      <div class="column right">
        <Eyebrow>TRAVEL RULE</Eyebrow>
        <Heading3 large>
          Ensure all transfers comply with the Travel Rule
        </Heading3>
        <Body>
          Ensure transacting parties’ identities are verified and recorded
          against transactions over the threshold transaction value.
        </Body>
        <div class="buttons">
          <Link color={COLOR_ATTENTION} href={TRAVEL_RULE_URL}>
            <TextButton
              large
              noFill
              color={COLOR_ATTENTION}
              iconRight="chevron-right"
            >
              Learn more
            </TextButton>
          </Link>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .travel-rule {
    @extend .generic-section;
  }
</style>
