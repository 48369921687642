<script>
  import { LEGAL_DOCS_URL } from "utils/menu";
  import { LEGAL_VERSION, LEGAL_DOCS_SOURCE } from "utils/document";
  import Document from "components/pages/Documents/Document.svelte";

  // Retrieve active page or default to intro
  export let params = { wild: "policy/cookie" };
  export let defaultSubPage = "policy/cookie";
</script>

<Document
  documentSourceUrl={LEGAL_DOCS_SOURCE}
  documentVersion={LEGAL_VERSION}
  baseDocumentUrl={LEGAL_DOCS_URL}
  menuTitle="Legal Documents"
  {params}
  {defaultSubPage}
/>

<style lang="scss">
  @import "../../../../styles/global.scss";
</style>
