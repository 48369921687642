<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M51 31.8751C51 42.3683 42.4928 50.8755 31.9996 50.8755C21.5072 50.8755 13 42.3683 13 31.8751C13 21.3812 21.5072 12.8755 31.9996 12.8755C42.4936 12.8747 51 21.3812 51 31.8751Z"
    fill="#EEF0F2"
  />
  <path
    d="M18.0063 55.168C11.1918 51.0805 6.33183 44.0733 5.15283 35.8759M58.5998 35.8759C57.4358 43.9713 52.6801 50.9065 45.9999 55.0158M19.7387 7.87553C23.5033 5.97865 27.6612 4.99363 31.8767 5.00003C36.2417 5.00003 40.3651 6.03578 44.0139 7.87477"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M49.9994 31.8754C53.5874 31.8754 55.9994 31.2979 59.9999 29.8759V26.8759C59.9999 25.2507 54.4994 22.8755 49.9994 22.8755C45.4995 22.8755 39.9998 25.2507 39.9998 26.8759V29.8759C43.9995 31.2979 46.4122 31.8754 49.9994 31.8754Z"
    fill="#B2D8FA"
  />
  <path
    d="M49.9994 31.8754C53.5874 31.8754 55.9994 31.2979 59.9999 29.8759V26.8759C59.9999 25.2507 54.4994 22.8755 49.9994 22.8755C45.4995 22.8755 39.9998 25.2507 39.9998 26.8759V29.8759C43.9995 31.2979 46.4122 31.8754 49.9994 31.8754Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M49.9995 10.8755C47.415 10.8755 45 12.9717 45 15.5569V19.1944C45 21.7797 47.415 23.8759 49.9995 23.8759C52.5847 23.8759 54.9997 21.7797 54.9997 19.1944V15.5569C54.9997 12.9717 52.5847 10.8755 49.9995 10.8755Z"
    fill="white"
  />
  <path
    d="M49.9995 10.8755C47.415 10.8755 45 12.9717 45 15.5569V19.1944C45 21.7797 47.415 23.8759 49.9995 23.8759C52.5847 23.8759 54.9997 21.7797 54.9997 19.1944V15.5569C54.9997 12.9717 52.5847 10.8755 49.9995 10.8755Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M31.9997 59.8755C35.5877 59.8755 37.9997 59.2972 42.0001 57.8752V54.8752C42.0001 53.25 36.4997 50.8755 31.9997 50.8755C27.4997 50.8755 22 53.25 22 54.8752V57.8752C25.9997 59.2972 28.4125 59.8755 31.9997 59.8755Z"
    fill="#B2D8FA"
  />
  <path
    d="M31.9997 59.8755C35.5877 59.8755 37.9997 59.2972 42.0001 57.8752V54.8752C42.0001 53.25 36.4997 50.8755 31.9997 50.8755C27.4997 50.8755 22 53.25 22 54.8752V57.8752C25.9997 59.2972 28.4125 59.8755 31.9997 59.8755Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M31.9997 38.876C29.4152 38.876 27.0002 40.9715 27.0002 43.5567V47.1942C27.0002 49.7794 29.4152 51.8756 31.9997 51.8756C34.5849 51.8756 36.9999 49.7794 36.9999 47.1949V43.5567C36.9999 40.9715 34.5849 38.876 31.9997 38.876Z"
    fill="white"
  />
  <path
    d="M31.9997 38.876C29.4152 38.876 27.0002 40.9715 27.0002 43.5567V47.1942C27.0002 49.7794 29.4152 51.8756 31.9997 51.8756C34.5849 51.8756 36.9999 49.7794 36.9999 47.1949V43.5567C36.9999 40.9715 34.5849 38.876 31.9997 38.876V38.876Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M13.9997 31.8754C17.5877 31.8754 19.9996 31.2979 24.0001 29.8759V26.8759C24.0001 25.2507 18.4997 22.8755 13.9997 22.8755C9.49971 22.8755 4 25.2507 4 26.8759V29.8759C7.99973 31.2979 10.4125 31.8754 13.9997 31.8754Z"
    fill="#B2D8FA"
  />
  <path
    d="M13.9997 31.8754C17.5877 31.8754 19.9996 31.2979 24.0001 29.8759V26.8759C24.0001 25.2507 18.4997 22.8755 13.9997 22.8755C9.49971 22.8755 4 25.2507 4 26.8759V29.8759C7.99973 31.2979 10.4125 31.8754 13.9997 31.8754Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M13.9997 10.8755C11.4152 10.8755 9.00024 12.9717 9.00024 15.5569V19.1944C9.00024 21.7797 11.4152 23.8759 13.9997 23.8759C16.585 23.8759 18.9999 21.7797 18.9999 19.1944V15.5569C18.9999 12.9717 16.585 10.8755 13.9997 10.8755Z"
    fill="white"
  />
  <path
    d="M13.9997 10.8755C11.4152 10.8755 9.00024 12.9717 9.00024 15.5569V19.1944C9.00024 21.7797 11.4152 23.8759 13.9997 23.8759C16.585 23.8759 18.9999 21.7797 18.9999 19.1944V15.5569C18.9999 12.9717 16.585 10.8755 13.9997 10.8755Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
