<script>
  export let width = 24;
  export let height = 24;
  export let color = "#2565BF";
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="6.5" cy="6.5" r="3.5" stroke={color} stroke-width="2" />
  <circle cx="17.5" cy="6.5" r="3.5" stroke={color} stroke-width="2" />
  <path
    d="M10 17.5C10 19.433 8.433 21 6.5 21C4.567 21 3 19.433 3 17.5C3 15.567 4.567 14 6.5 14C8.433 14 10 15.567 10 17.5Z"
    stroke={color}
    stroke-width="2"
  />
  <circle cx="17.5" cy="17.5" r="3.5" stroke={color} stroke-width="2" />
</svg>
