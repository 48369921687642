<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg {ref} {width} {height} fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M60.0021 30.0014C60.0021 45.4665 47.4665 58.0035 32.0014 58.0035C16.5363 58.0035 4 45.4665 4 30.0014C4 14.5363 16.5371 2 32.0014 2C47.4665 2 60.0028 14.5363 60.0028 30.0014"
    fill="#EEF0F2"
  />
  <path
    d="M46.0024 61.0036H60.0028V47.0033H46.0024V61.0036ZM27.0018 61.0036H41.0021V47.0033H27.0026V61.0036H27.0018ZM8.00195 61.0036H22.0023V47.0033H8.00195V61.0036ZM38.0021 25.0034L28.6121 38.0032H49.0024L57.0027 25.0034H38.0021Z"
    fill="white"
  />
  <path
    d="M41.0025 55.0037V61.0037H27.0029V47.0034H38.0025"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M30.0018 54.0039L34.0023 57.004L42.0027 47.0034M22.0023 55.0037V61.0037H8.00195V47.0034H19.0023M60.002 61.0037H46.0024V47.0034H60.0028L60.002 61.0037Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M11.002 54.0039L15.0025 57.0039L23.002 47.0034"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M29.0021 37.0037L21.2695 27.5514C20.8736 27.0675 20.3751 26.6776 19.8101 26.4099C19.2451 26.1422 18.6277 26.0034 18.0025 26.0034C15.0385 26.0034 12.877 27.8477 12.3505 31.6554C11.8952 34.9427 11.002 42.004 11.002 42.004H33.0026C37.0023 42.004 37.0023 37.0037 37.0023 37.0037H29.0021Z"
    fill="#B2D8FA"
  />
  <path
    d="M32.2203 33.0037L38.0028 25.0034H57.0027L49.0024 38.0032H41.0021M20.002 42.0038H58.0024"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M21.5017 22.0037C18.2527 22.0037 16.002 19.51 16.002 16.4327V14.5749C16.002 11.4977 18.2527 9.00391 21.5017 9.00391C24.7523 9.00391 27.0023 11.4977 27.0023 14.5749V16.4327C27.0023 19.51 24.7523 22.0037 21.5017 22.0037Z"
    fill="white"
  />
  <path
    d="M21.5017 22.0037C18.2527 22.0037 16.002 19.51 16.002 16.4327V14.5749C16.002 11.4977 18.2527 9.00391 21.5017 9.00391C24.7523 9.00391 27.0023 11.4977 27.0023 14.5749V16.4327C27.0023 19.51 24.7523 22.0037 21.5017 22.0037Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M19.0022 35.0035L25.0023 42.004H33.0026C37.0023 42.004 37.0023 37.0037 37.0023 37.0037H29.0021L21.2695 27.5514C20.8736 27.0675 20.3751 26.6776 19.8101 26.4099C19.2451 26.1422 18.6277 26.0034 18.0025 26.0034C15.0385 26.0034 12.877 27.8477 12.3505 31.6554C11.8959 34.9427 11.002 42.004 11.002 42.004"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
</svg>
