<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M52.9978 36.9985C52.9978 49.701 42.7002 59.9976 29.9988 59.9976C17.2973 59.9976 6.99976 49.701 6.99976 36.9985C6.99976 24.2961 17.2973 13.9995 29.9988 13.9995C42.7002 13.9995 52.9978 24.2961 52.9978 36.9985Z"
    fill="#EEF0F2"
  />
  <path
    d="M48.767 36.3206L42.8852 39.1105L42.5632 39.2325C42.1463 38.4895 41.4363 37.9985 40.4483 37.9985H34.4486C33.0736 37.9985 30.9827 35.9986 29.3268 35.9986H23.449C21.0321 35.9986 18.8732 37.9775 17.6563 39.1105C15.7764 40.9904 11.9995 44.7862 11.9995 44.7862L21.1911 53.9879C22.6941 52.4859 24.464 50.998 25.9989 50.998H37.9984C39.8713 50.998 41.7353 50.423 43.0592 49.099L53.0098 39.1485L55.8387 32.0778C54.1148 30.3538 50.1809 32.1958 48.767 36.3206Z"
    fill="white"
  />
  <path
    d="M29.9988 43.9983H40.4483C42.1052 43.9983 42.9982 42.6933 42.9982 41.0374V41.0184C42.9982 39.3605 42.1052 37.9985 40.4483 37.9985H34.4486C33.0736 37.9985 30.9827 35.9986 29.3268 35.9986H23.449C21.0321 35.9986 18.8732 37.9775 17.6563 39.1105C15.7764 40.9904 11.9995 44.7862 11.9995 44.7862L21.1911 53.9879C22.6941 52.4859 24.464 50.998 25.9989 50.998H37.9984C39.8713 50.998 41.7353 50.423 43.0592 49.099L53.0098 39.1485L55.8387 32.0778C54.1148 30.3538 50.1809 32.1958 48.767 36.3206L42.8852 39.1105"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M10.9997 41.9984L5 47.9981L17.9994 60.9976L23.9982 54.9978L10.9997 41.9984Z"
    fill="#B2D8FA"
  />
  <path
    d="M10.9997 41.9984L5 47.9981L17.9994 60.9976L23.9982 54.9978L10.9997 41.9984Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M12.5468 49.451L10.5469 47.4511"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M45.7722 15.3475L37.5186 8.72982C37.2616 8.52383 36.8216 8.42684 36.4436 8.56384C36.4426 8.56384 26.715 12.3527 26.715 12.3527C26.275 12.4397 25.9151 12.7846 25.8451 13.2536L24.3221 23.5302C24.2581 23.9882 24.3351 24.4611 24.6661 24.7261L32.9208 31.3429C33.2377 31.5989 33.4807 31.7578 33.7977 31.6348L43.7813 27.754C44.3192 27.545 44.5452 27.319 44.6182 26.824L46.1702 16.3585C46.2162 16.0455 46.0622 15.5795 45.7722 15.3475Z"
    fill="#B2D8FA"
  />
  <path
    d="M45.7722 15.3475L37.5186 8.72982C37.2616 8.52383 36.8216 8.42684 36.4436 8.56384C36.4426 8.56384 26.715 12.3527 26.715 12.3527C26.275 12.4397 25.9151 12.7846 25.8451 13.2536L24.3221 23.5302C24.2581 23.9882 24.3351 24.4611 24.6661 24.7261L32.9208 31.3429C33.2377 31.5989 33.4807 31.7578 33.7977 31.6348L43.7813 27.754C44.3192 27.545 44.5452 27.319 44.6182 26.824L46.1702 16.3585C46.2162 16.0455 46.0622 15.5795 45.7722 15.3475V15.3475Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M36.2396 11.6417L34.8577 19.9293M34.8577 19.9293L27.751 22.5212M34.8577 19.9293L41.6944 25.3441"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
</svg>
