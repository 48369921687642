<script>
  import PageLayout from "components/PageLayout.svelte";
  import Heading1 from "components/elements/aliases/Heading1.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Heading4 from "components/elements/aliases/Heading4.svelte";
  import Heading5 from "components/elements/aliases/Heading5.svelte";
  import Heading6 from "components/elements/aliases/Heading6.svelte";
  import Caption from "components/elements/headings/Caption.svelte";
  import BulletPoint from "components/elements/headings/BulletPoint.svelte";
</script>

<PageLayout>
  <div class="container">
    <div class="light">
      <div class="row">
        <Heading1>Heading 1</Heading1>
      </div>
      <div class="row">
        <Heading2>Heading 2</Heading2>
      </div>
      <div class="row">
        <Heading3>Heading 3</Heading3>
      </div>
      <div class="row">
        <Heading4>Heading 4</Heading4>
      </div>
      <div class="row">
        <Heading5>Heading 5</Heading5>
      </div>
      <div class="row">
        <Heading6>Heading 6</Heading6>
      </div>
      <div class="row">
        <Caption>Caption</Caption>
      </div>
      <div class="row">
        <BulletPoint>Bullet point</BulletPoint>
      </div>

      <!-- large light -->
      <div class="separator" />
      <div class="row">
        <Heading1 large>Heading 1</Heading1>
      </div>
      <div class="row">
        <Heading2 large>Heading 2</Heading2>
      </div>
      <div class="row">
        <Heading3 large>Heading 3</Heading3>
      </div>

      <!-- large light end -->
    </div>
    <div class="dark">
      <div class="row">
        <Heading1 dark>Heading 1</Heading1>
      </div>
      <div class="row">
        <Heading2 dark>Heading 2</Heading2>
      </div>
      <div class="row">
        <Heading3 dark>Heading 3</Heading3>
      </div>
      <div class="row">
        <Heading4 dark>Heading 4</Heading4>
      </div>
      <div class="row">
        <Heading5 dark>Heading 5</Heading5>
      </div>
      <div class="row">
        <Heading6 dark>Heading 6</Heading6>
      </div>
      <div class="row">
        <Caption dark>Caption</Caption>
      </div>
      <div class="row">
        <BulletPoint dark>Bullet point</BulletPoint>
      </div>

      <!-- large dark -->
      <div class="separator" />
      <div class="row">
        <Heading1 dark large>Heading 1</Heading1>
      </div>
      <div class="row">
        <Heading2 dark large>Heading 2</Heading2>
      </div>
      <div class="row">
        <Heading3 dark large>Heading 3</Heading3>
      </div>

      <!-- large dark -->
    </div>
  </div>
</PageLayout>

<style lang="scss">
  @import "../../../styles/global.scss";

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .separator {
      width: 100%;
      height: 100px;
    }

    .light,
    .dark {
      padding: 20px;
      width: 600px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .dark {
      background-color: $B90;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
</style>
