<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M57.2537 14.5373H52V21.5371H63.0002C63.0002 17.5374 60.4277 14.5373 57.2537 14.5373ZM49 29.5373C49 42.7921 38.2547 53.5373 25 53.5373C11.7452 53.5373 1 42.7921 1 29.5373C1 16.2826 11.7452 5.53735 25 5.53735C38.2547 5.53735 49 16.2826 49 29.5373Z"
    fill="#EEF0F2"
  />
  <path
    d="M55 21.5371H63.0002C63.0002 17.5374 60.4277 14.5374 57.2537 14.5374H48.0002"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M57.6393 14.5374C54.4683 14.5374 52 17.2163 52 20.4098V54.4283C52 57.6233 49.018 60.5371 45.847 60.5371H22V20.2854C22 17.0911 24.8298 14.5374 28.0008 14.5374H57.6678H57.6393Z"
    fill="white"
  />
  <path
    d="M57.6393 14.5374C54.4683 14.5374 52 17.2163 52 20.4098V54.4283C52 57.6233 49.018 60.5371 45.847 60.5371H22V20.2854C22 17.0911 24.8298 14.5374 28.0008 14.5374H57.6678H57.6393Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M40.1891 54.5374H14.7761C15.6559 57.5374 18.5899 60.5374 21.2524 60.5374H45.7353C43.0743 60.5374 40.8424 57.5374 40.1891 54.5374Z"
    fill="#B2D8FA"
  />
  <path
    d="M40.0001 48.5374H46.0001M40.1891 54.5374H14.7761C15.6559 57.5374 18.5899 60.5374 21.2524 60.5374H45.7354C43.0744 60.5374 40.8424 57.5374 40.1891 54.5374ZM28.0001 24.5374H46.0001H28.0001ZM28.0001 28.5378H46.0001H28.0001ZM28.0001 32.5376H46.0001H28.0001Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M32.1049 47.1086C32.1049 47.1086 31.5311 42.2928 29.1011 39.8628L9.78639 20.5473C8.58489 19.3466 6.69489 19.2693 5.47989 20.4843C4.26639 21.6985 4.34214 23.5885 5.54439 24.7908L24.8591 44.1056C27.2884 46.5348 32.1049 47.1086 32.1049 47.1086Z"
    fill="#B2D8FA"
  />
  <path
    d="M8.59914 27.8456L12.8419 23.6036M32.1049 47.1086C32.1049 47.1086 31.5311 42.2928 29.1011 39.8628L9.78639 20.5473C8.5849 19.3466 6.69489 19.2693 5.47989 20.4843C4.2664 21.6985 4.34214 23.5886 5.54439 24.7908L24.8591 44.1056C27.2884 46.5349 32.1049 47.1086 32.1049 47.1086ZM12.1354 37.0383L2.94189 27.8456L12.1354 37.0383ZM5.77089 30.6746L7.8919 28.5521L5.77089 30.6746ZM31.9339 46.9376L33.3484 48.3513L31.9339 46.9376ZM24.8629 44.1101L29.1049 39.8666L24.8629 44.1101Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M36.0002 48.5374H37.9997"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
