<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import { COLOR_ATTENTION } from "buttonStyles";
  import { SDK_DOCS_URL, API_DOCS_URL } from "utils/menu";
</script>

<PageSection>
  <div class="build-front-end">
    <div class="columns">
      <div class="column left image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/sdk-example.webp"
          alt="Build your own front-end"
        />
      </div>
      <div class="column right">
        <Eyebrow>Build your own front-end</Eyebrow>
        <Heading3>
          Access every app feature through our SDK and API
        </Heading3>
        <Body>
          Our entire UI is free and open source, and can be utilized to create
          custom front-ends, as well as leveraged to embed our services into
          existing apps.
          <br /><br />Our JavaScript SDK and REST API aims to provide developers with
          a first-class integration experience. Our platform’s architecture
          allows for the best-in-class developer flexibility and transparency,
          while always preserving the security model.
        </Body>
        <div class="buttons">
          <TextButton
            large
            noFill
            color={COLOR_ATTENTION}
            iconRight="chevron-right"
            href={SDK_DOCS_URL}>
            SDK Documentation
          </TextButton>
          <TextButton
            large
            noFill
            color={COLOR_ATTENTION}
            iconRight="chevron-right"
            href={API_DOCS_URL}>
            API Reference
          </TextButton>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .build-front-end {
    @extend .generic-section;

    .image {
      margin-bottom: -32px;
    }
  }
</style>
