<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M54.5763 31.4322C54.5763 44.4117 44.0553 54.9319 31.0767 54.9319C18.0972 54.9319 7.57629 44.4117 7.57629 31.4322C7.57629 18.4543 18.0972 7.93262 31.0767 7.93262C44.0553 7.93262 54.5763 18.4551 54.5763 31.4322Z"
    fill="#EEF0F2"
  />
  <path
    d="M33.7956 12.0802C31.8119 12.3749 29.2949 13.7331 27.5009 15.5264C24.1597 18.8684 23.0107 23.5971 24.1454 27.8571L18.9997 32.9465V36.8173L18.4687 37.4998H14.598L12.9997 39.398V43.2695L12.0173 43.4997H8.14577L4.92004 47.1777L8.79077 51.4999H15.243L30.4604 36.0373C32.3496 37.0603 34.5044 37.451 36.5339 37.451C43.6243 37.451 50.0616 28.4998 47.9998 20.5004C45.9366 12.5002 38.7494 10.8134 33.7956 12.0802Z"
    fill="white"
  />
  <path
    d="M33.7956 12.0798C31.4101 12.6006 29.2249 13.797 27.5009 15.526C24.1597 18.868 23.0107 23.5968 24.1454 27.8568L18.9997 32.9462V36.8169L18.4687 37.4994H14.598L12.9997 39.3977V43.2692L12.0173 43.4994H8.14577L4.92004 47.1774L8.79077 51.4996H15.243L30.4604 36.0377C32.3496 37.0599 34.4414 37.4492 36.5339 37.4507M28.1452 32.9462L13.3065 47.7849"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M38.9998 61.4994H44.7448L48.9995 56.4864V34.9638C54.9995 33.3933 58.277 28.5123 58.277 22.7261C58.277 15.6709 52.6355 9.95215 45.5803 9.95215C38.5258 9.95215 32.7096 15.6709 32.7096 22.7261C32.7096 27.4526 35.9998 31.5686 38.9998 33.7766V41.5L40.9993 43.4995V45.4997L38.9998 48.3617V50.0987L40.9993 52.4995V54.4997L38.9998 56.4999V61.4994Z"
    fill="#B2D8FA"
  />
  <path
    d="M44.9998 38.5V55.4995M44.9998 36.4998V34.4996M38.9998 61.4994H44.7448L48.9995 56.4864V34.9638C54.9995 33.3933 58.277 28.5123 58.277 22.7261C58.277 15.6709 52.6355 9.95215 45.5803 9.95215C38.5258 9.95215 32.7096 15.6709 32.7096 22.7261C32.7096 27.4526 35.9998 31.5686 38.9998 33.7766V41.5L40.9993 43.4995V45.4997L38.9998 48.3617V50.0987L40.9993 52.4995V54.4997L38.9998 56.4999V61.4994Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M25.0001 14.5002C25.0001 8.42521 29.9253 3.5 36.0003 3.5C42.0753 3.5 46.9998 8.42521 46.9998 14.5002C46.9998 16.1382 46.642 17.6922 46 19.0894"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M46.0021 22.5026C44.9427 22.5024 43.9268 22.0813 43.1778 21.3321C42.4289 20.5828 42.0082 19.5668 42.0084 18.5074C42.0086 17.448 42.4297 16.4321 43.1789 15.6831C43.9282 14.9341 44.9442 14.5135 46.0036 14.5137C47.0631 14.5139 48.0792 14.9349 48.8282 15.6843C49.5772 16.4336 49.9979 17.4498 49.9977 18.5093C49.9975 19.5688 49.5765 20.5848 48.8271 21.3338C48.0778 22.0829 47.0616 22.5036 46.0021 22.5034"
    fill="white"
  />
  <path
    d="M46.0021 22.5026C44.9427 22.5024 43.9268 22.0813 43.1778 21.3321C42.4289 20.5828 42.0082 19.5668 42.0084 18.5074C42.0086 17.448 42.4297 16.4321 43.1789 15.6831C43.9282 14.9341 44.9442 14.5135 46.0036 14.5137C47.0631 14.5139 48.0792 14.9349 48.8282 15.6843C49.5772 16.4336 49.9979 17.4498 49.9977 18.5093C49.9975 19.5688 49.5765 20.5848 48.8271 21.3338C48.0778 22.0829 47.0616 22.5036 46.0021 22.5034V22.5026Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
