<script>
  export let dark = false;
</script>

<div class="box-title {dark ? 'dark' : ''}"><slot /></div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .box-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: $N90;

    &.dark {
      color: $white;
    }
  }
</style>
