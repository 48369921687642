<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M63.9992 24.9996C63.9992 37.1496 54.1503 46.9993 41.9996 46.9993C29.8497 46.9993 20 37.1496 20 24.9996C20 12.8497 29.8489 3 41.9996 3C54.1496 3 63.9992 12.8497 63.9992 24.9996Z"
    fill="#EEF0F2"
  />
  <path d="M42 22H8.00046L1 61.9995H48.9997L42 22Z" fill="white" />
  <path
    d="M48.255 57.7418L48.9997 61.9995H1L8.00046 22H35.3131"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M52.4516 37.1469C50.9442 36.5506 49.9992 35.0394 49.9992 33.4179V22.3929C52.3887 21.6152 53.9996 20.1655 53.9996 18.5005C53.9996 16.015 50.4177 14.0005 45.9994 14.0005C41.582 14.0005 38 16.015 38 18.5005C38 20.1655 39.611 21.6152 41.9997 22.3929V33.4179C41.9997 35.0386 41.0547 36.5506 39.5472 37.1469C36.7925 38.2344 35 40.0021 35 41.9993V47.9993C35 51.3143 39.9245 53.9992 45.9994 53.9992C52.0744 53.9992 56.9996 51.3143 56.9996 47.9993V41.9993C56.9996 40.0021 55.2071 38.2344 52.4516 37.1469Z"
    fill="#B2D8FA"
  />
  <path
    d="M52.4516 37.1469C50.9442 36.5506 49.9992 35.0394 49.9992 33.4179V22.3929C52.3887 21.6152 53.9996 20.1655 53.9996 18.5005C53.9996 16.015 50.4177 14.0005 45.9994 14.0005C41.582 14.0005 38 16.015 38 18.5005C38 20.1655 39.611 21.6152 41.9997 22.3929V33.4179C41.9997 35.0386 41.0547 36.5506 39.5472 37.1469C36.7925 38.2343 35 40.0021 35 41.9993V47.9993C35 51.3143 39.9245 53.9992 45.9994 53.9992C52.0744 53.9992 56.9996 51.3143 56.9996 47.9993V41.9993C56.9996 40.0021 55.2071 38.2343 52.4516 37.1469Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M39.5324 46.8541C36.7851 45.7628 35.0001 43.9951 35.0001 42.0001M56.9998 42.0001C56.9998 45.3136 52.0753 48.0001 45.9996 48.0001C45.2571 48.0001 44.5326 47.9596 43.8321 47.8838M15.9997 30.9999H32.0002M32.9999 34.9996H15M43.2523 22.7275C42.8143 22.6375 42.3951 22.5257 41.9968 22.3975L43.2523 22.7275ZM50.0015 22.3975C49.1488 22.675 48.1978 22.8662 47.1853 22.951L50.0015 22.3975Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
