<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M54.0007 31.4994C54.0007 43.3735 44.3752 52.9998 32.5011 52.9998C20.6271 52.9998 11.0007 43.3735 11.0007 31.4994C11.0007 19.6253 20.6271 9.99902 32.5011 9.99902C44.3752 9.99902 54.0015 19.6253 54.0015 31.4994"
    fill="#24375B"
  />
  <path
    d="M59.0007 43.4999C59.0007 52.0612 52.0609 59.0002 43.5011 59.0002C34.9398 59.0002 28.0007 52.0612 28.0007 43.4999C28.0007 34.9401 34.9398 27.9995 43.5011 27.9995C52.0609 27.9995 59.0007 34.9401 59.0007 43.4999Z"
    fill="#B2D8FA"
  />
  <path
    d="M38.0009 20.9994C38.0009 29.8367 30.8384 36.9992 22.0011 36.9992C13.1638 36.9992 6.00122 29.8367 6.00122 20.9994C6.00122 12.1636 13.1638 4.99951 22.0011 4.99951C30.8384 4.99951 38.0009 12.1636 38.0009 20.9994Z"
    fill="white"
  />
  <path
    d="M3.09009 39.0257L8.84037 38.1684M8.84037 38.1684L9.69762 43.9195M8.84037 38.1684C4.29984 42.7105 4.30017 50.0733 8.8407 54.6161C13.3827 59.1588 20.747 59.1588 25.2891 54.6161M55.4801 24.4246C60.0222 19.8833 60.0222 12.5183 55.4801 7.977C50.9381 3.43422 43.5746 3.43422 39.0326 7.97625M55.4801 24.4246L61.2323 23.5673M55.4801 24.4246L54.6232 18.6743"
    stroke="#B2D8FA"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M25.2891 36.6617C24.2278 36.8822 23.1283 36.9992 22.0011 36.9992C13.1638 36.9992 6.00122 29.8367 6.00122 20.9994C6.00122 12.1636 13.1638 4.99951 22.0011 4.99951C30.8384 4.99951 38.0009 12.1636 38.0009 20.9994C38.0009 22.5219 37.7879 23.9934 37.3919 25.3884M59.001 43.4995C59.001 52.0608 52.0612 58.9998 43.5014 58.9998C34.9401 58.9998 28.0011 52.0608 28.0011 43.4995C28.0011 34.9397 34.9401 27.9991 43.5014 27.9991C52.0612 27.9991 59.001 34.9397 59.001 43.4995Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.0007 24.9993C19.0006 25.262 19.0523 25.5222 19.1528 25.7649C19.2533 26.0076 19.4006 26.2282 19.5864 26.414C19.7721 26.5997 19.9927 26.747 20.2354 26.8475C20.4781 26.948 20.7383 26.9997 21.001 26.9996H23.0005C23.531 26.9996 24.0398 26.7889 24.4149 26.4137C24.79 26.0386 25.0008 25.5298 25.0008 24.9993V23.4408C25.0009 23.0211 24.8689 22.6119 24.6235 22.2713C24.3781 21.9307 24.0317 21.676 23.6335 21.5433L20.368 20.4543C19.9699 20.3219 19.6235 20.0674 19.3781 19.7271C19.1327 19.3868 19.0007 18.9779 19.0007 18.5583V16.9998C19.0007 16.4693 19.2115 15.9605 19.5866 15.5854C19.9617 15.2103 20.4705 14.9995 21.001 14.9995H23.0005C23.5309 14.9997 24.0396 15.2105 24.4147 15.5856C24.7897 15.9607 25.0006 16.4693 25.0008 16.9998M22.0008 14.9995V11.9995M22.0008 26.9996V29.9996M37.0008 41.9997H43.0009M37.0008 44.9997H43.0009M47.0006 50H44.0006C41.2511 50 39.0011 47.75 39.0011 44.9997V41.9997C39.0011 39.2494 41.2511 36.9994 44.0006 36.9994H47.0006"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
