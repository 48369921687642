<script>
  import { push } from "svelte-spa-router";
  import Icon from "components/elements/Icon";
  import Anchor from "components/elements/Anchor.svelte";

  export let title;
  export let icon;
  export let active = false;
  export let link;
  export let afterRedirect = () => {};

  const onClick = (url) => {
    push(url);

    // Scroll to the top
    window.scrollTo(0, 0);

    // Optional callback received from parent components
    afterRedirect();
  };
</script>

<Anchor href={link}>
  <div
    class="menu-item {active ? 'active' : ''}"
    on:click={() => onClick(link)}
  >
    <div class="column-icon">
      <Icon name={icon} />
    </div>
    <div class="column-text">
      {#if !!title}
        <div>
          <div class="title">{title}</div>
          <div class="chevron-icon">
            <Icon name="chevron-right" colorName="N30" />
          </div>
          <div class="arrow-icon">
            <Icon name="arrow-right" colorName="B65" />
          </div>
        </div>
      {/if}
      <div class="description">
        <slot />
      </div>
    </div>
  </div>
</Anchor>

<style lang="scss">
  @import "../../styles/global.scss";

  .menu-item {
    border-radius: 6px;
    display: flex;
    width: 100%;
    min-height: 84px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;

    .column-icon {
      width: 40px;
      padding: 16px 0px 0px 16px;
    }
    .column-text {
      width: 100%;
      padding: 16px 16px 16px 24px;

      > div {
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .title {
          font-weight: 700;
          font-size: 17px;
          line-height: 25px;
          color: $N90;
          white-space: nowrap;
          @media only screen and (max-width: $size-phone-max) {
            white-space: normal;
          }
        }
        .chevron-icon,
        .arrow-icon {
          height: 18px;
          margin-left: 6px;
        }
        .arrow-icon {
          display: none;
        }
      }

      .description {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 0px;
        color: $N75;
        height: fit-content;
      }
    }
    &:hover,
    &.active {
      background-color: $N10;
      .column-text {
        > div {
          .chevron-icon {
            display: none;
          }
          .arrow-icon {
            display: initial;
            margin-left: 8px;
          }
        }
      }
    }
  }
</style>
