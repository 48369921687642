<script>
  import Icon from "components/elements/Icon";
  import Anchor from "components/elements/Anchor";
  export let dark = false;
  export let width = 154;
  export let height = 32;
</script>

<Anchor href="/">
  <Icon name="logo-{dark ? 'dark' : 'default'}" {width} {height} />
</Anchor>
