/**
 * Tokenized Protocol Documentation related configuration
 */

export const PROTOCOL_DOCS_SOURCE = "https://tokenized.com/apidocs/";
export const LEGAL_DOCS_SOURCE = "/legal/";
export const TOC_YAML = "config/toc-docs.yaml";
export const PROTOCOL_VERSION = "v1.1.1";
export const LEGAL_VERSION = "v1.0.1";

// Convenience method for relative retrieval of the protocol source files
export const documentFetch = (file, baseUrl) => fetch(`${baseUrl}${file}`);

// Convenience method for retrieving the TOC file
export const fetchTOC = (baseUrl) => documentFetch(TOC_YAML, baseUrl);

// Convert path component into an .md source file name
export const getPageFilename = (page) => {
  return page && `${page.replace("/", "-")}.md`;
};

// Convert path to a relative filename and retrieve its contents
export const fetchPageData = (page, baseUrl) => {
  const pageFilename = getPageFilename(page);
  return documentFetch(pageFilename, baseUrl);
};
