<script>
  import Icon from "components/elements/Icon";

  export let icon = "";
</script>

<div class="heading-icon">
  <Icon name={icon} />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .heading-icon {
    padding-bottom: 24px;
    padding-left: 16px;
  }
</style>
