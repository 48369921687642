<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection dark>
  <div class="about-hero">
    <div class="columns">
      <div class="column">
        <Eyebrow dark>Our Mission</Eyebrow>
        <Heading3 dark>
          Leverage technology to make voluntary exchange simpler, faster, and
          more affordable.
        </Heading3>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .about-hero {
    @extend .generic-section;

    .column {
      width: calc(75% - 24px);

      @media only screen and (max-width: $size-tablet-max) {
        width: 100%;
      }
    }
  }
</style>
