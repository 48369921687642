<script>
  import Icon from "components/elements/Icon";
  import FeatureText from "components/elements/headings/FeatureText.svelte";
  export let icon = "check-circle";
  export let width = "20";
  export let height = "20";
  export let dark = "";
</script>

<div class="feature-icon">
  <span class="icon"><Icon name={icon} {width} {height} /></span>
  <FeatureText {dark}>
    <slot />
  </FeatureText>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .feature-icon {
    display: flex;
    flex-direction: row;
    min-height: 36px;

    .icon {
      margin-top: 4px;
      display: block;
      overflow: visible !important;
      z-index: 99 !important;
    }
  }
</style>
