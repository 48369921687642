<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection>
  <div class="simpler-ux">
    <div class="columns">
      <div class="column left image tablet-visible">
        <ImageFrame width={560} src="/images/token-holders-ui.webp" />
      </div>
      <div class="column right">
        <Eyebrow>TRANSFORMING back and front office processes</Eyebrow>
        <Heading3>
          A simpler UX for capital formation, asset servicing, and secondary
          trading
        </Heading3>
        <Body>
          A digital transformation of financial contracts can yield a step
          change improvement in automation over existing methods, and can
          eliminate many costs and inefficiencies throughout the various
          processes required to form capital, service assets, and trade in the
          secondary markets.
          <br /><br />This is particularly true of the private capital markets,
          where the contracts and tooling is poorly digitized.
          <br /><br />No more paying or calculation agents, and no more emailing
          your lawyer to find out when your next coupon payment is due, or how
          much you’ve been paid so far. Our platform unlocks novel and real-time
          insights.
        </Body>
        <div class="column left image mobile-visible">
          <ImageFrame width={560} src="/images/token-holders-ui.webp" />
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .simpler-ux {
    @extend .generic-section;
  }
</style>
