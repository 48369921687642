<script>
  import Heading4 from "components/elements/aliases/Heading4.svelte";
  import Heading5 from "components/elements/aliases/Heading5.svelte";
  import Caption from "components/elements/headings/Caption.svelte";
  import HeadingIcon from "components/elements/headings/HeadingIcon.svelte";

  export let icon = "";
  export let heading = ""; // Default heading size
  export let heading4 = "";
  export let dark = "";
</script>

<div class="icon-column {dark ? 'dark' : ''}">
  <HeadingIcon {icon} />
  {#if heading}
    <Heading5 {dark}>{heading}</Heading5>
  {/if}
  {#if heading4}
    <Heading4 {dark}>{heading4}</Heading4>
  {/if}
  <Caption {dark}>
    <slot />
  </Caption>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .icon-column {
    width: 100%
  }
</style>
