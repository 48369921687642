<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4784_15017)">
    <path
      d="M62.0048 27.0001C62.0048 41.3588 50.3642 52.9995 36.0047 52.9995C21.6453 52.9995 10.0046 41.3588 10.0046 27.0001C10.0046 12.6407 21.6453 1 36.0047 1C50.3642 1 62.0048 12.6407 62.0048 27.0001Z"
      fill="#EEF0F2"
    />
    <path
      d="M57.7561 19.5698L46.1874 31.7505C45.8139 32.1445 45.3642 32.4585 44.8657 32.6733C44.3671 32.8881 43.83 32.9993 43.2871 33H40.7019C40.165 32.9993 39.6338 32.8905 39.1398 32.6802C38.6458 32.47 38.1991 32.1625 37.8264 31.776L31.0847 24.909L27.8342 28.8285L36.0054 38.4547V63.9996H48.0054V38.4547L57.5423 26.9423C58.4461 25.9383 58.9639 24.6458 59.0032 23.2954C59.0425 21.9451 58.6007 20.6247 57.7568 19.5698"
      fill="#B2D8FA"
    />
    <path
      d="M18.0049 23.9912C20.2173 23.9912 22.0046 22.12 22.0046 19.8122V18.1787C22.0046 15.871 20.2173 13.9998 18.0049 13.9998C15.7931 13.9998 14.0051 15.871 14.0051 18.1787V19.8122C14.0051 22.12 15.7931 23.9912 18.0049 23.9912"
      fill="white"
    />
    <path
      d="M18.0049 23.9912C20.2173 23.9912 22.0046 22.12 22.0046 19.8122V18.1787C22.0046 15.871 20.2173 13.9998 18.0049 13.9998C15.7931 13.9998 14.0051 15.871 14.0051 18.1787V19.8122C14.0051 22.12 15.7931 23.9912 18.0049 23.9912V23.9912Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M33.7562 14.5696L22.1875 26.751C21.814 27.1449 21.3643 27.4587 20.8657 27.6734C20.3671 27.8881 19.8301 27.9991 19.2873 27.9998H16.702C16.1652 27.999 15.6339 27.8903 15.1399 27.68C14.6459 27.4697 14.1993 27.1622 13.8266 26.7758L2.02913 14.5718C1.18531 15.6265 0.743642 16.9466 0.78293 18.2967C0.822218 19.6468 1.33991 20.939 2.24363 21.9428L12.0056 33.4552V59.0001H24.0055V33.4552L33.5424 21.942C34.4463 20.938 34.964 19.6455 35.0033 18.2952C35.0426 16.9448 34.6009 15.6245 33.7569 14.5696"
      fill="#B2D8FA"
    />
    <path
      d="M24.0049 58.9994V33.4545L33.5418 21.942C34.4456 20.938 34.9634 19.6455 35.0027 18.2952C35.042 16.9448 34.6002 15.6245 33.7563 14.5696L22.1876 26.751C21.814 27.1447 21.3642 27.4584 20.8657 27.6731C20.3671 27.8878 19.8302 27.9989 19.2874 27.9998H16.7021C16.1653 27.999 15.634 27.8903 15.14 27.68C14.646 27.4697 14.1994 27.1622 13.8267 26.7758L2.02923 14.5718C1.18516 15.6263 0.743272 16.9464 0.782425 18.2966C0.821577 19.6467 1.33922 20.939 2.24298 21.9428L12.0049 33.4552V59.0001M18.0049 42.9997V58.9994V42.9997Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M42.0046 28.9907C44.2171 28.9907 46.0043 27.1202 46.0043 24.8117V23.179C46.0043 20.8712 44.2171 19 42.0046 19C39.7929 19 38.0049 20.8712 38.0049 23.179V24.8117C38.0049 27.1202 39.7929 28.9907 42.0046 28.9907"
      fill="white"
    />
    <path
      d="M27.8357 28.8309L36.0046 38.4549V63.9998M42.0046 28.9907C44.2171 28.9907 46.0043 27.1202 46.0043 24.8117V23.179C46.0043 20.8712 44.2171 19 42.0046 19C39.7929 19 38.0049 20.8712 38.0049 23.179V24.8117C38.0049 27.1202 39.7929 28.9907 42.0046 28.9907V28.9907ZM42.0046 48.0001V63.9998V48.0001Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M48.0046 63.9996V38.4547L57.5415 26.9423C58.4454 25.9383 58.9631 24.6458 59.0024 23.2954C59.0417 21.9451 58.6 20.6247 57.756 19.5698L46.1874 31.7505C45.8138 32.1444 45.3641 32.4582 44.8655 32.673C44.367 32.8878 43.83 32.999 43.2871 33H40.7019C40.165 32.9993 39.6338 32.8905 39.1398 32.6802C38.6458 32.47 38.1991 32.1625 37.8264 31.776L31.0854 24.909"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </g>
  <defs>
    <clipPath id="clip0_4784_15017">
      <rect {width} {height} fill="white" />
    </clipPath>
  </defs>
</svg>
