<script>
  export let dark = false;
</script>

<div class="body {dark ? 'dark' : ''}"><slot /></div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .body {
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: $N90;

    &.dark {
      color: $I10;
    }
  }
</style>
