<script>
  export let width = 64;
  export let height = 65;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M57.9998 34.5374C57.9998 48.8961 46.3598 60.5369 31.9995 60.5369C17.6408 60.5369 6 48.8961 6 34.5374C6 20.1779 17.6408 8.53711 31.9995 8.53711C46.3598 8.53711 57.9998 20.1779 57.9998 34.5374Z"
    fill="#EEF0F2"
  />
  <path
    d="M12.0002 46.5371L10.9998 47.5368L7 59.5368L19 55.5371L19.9998 54.5366L12.0002 46.5371Z"
    fill="white"
  />
  <path
    d="M12.0002 46.5371L10.9998 47.5368L7 59.5368L19 55.5371L19.9998 54.5366L12.0002 46.5371Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M54.8792 15.4158L51.121 11.6583C50.8425 11.3796 50.5119 11.1586 50.148 11.0078C49.784 10.8569 49.3939 10.7793 49 10.7793C48.6061 10.7793 48.216 10.8569 47.852 11.0078C47.4881 11.1586 47.1575 11.3796 46.879 11.6583L43 15.5365L51.0002 23.5368L54.8792 19.6578C55.4417 19.0952 55.7576 18.3323 55.7576 17.5368C55.7576 16.7413 55.4417 15.9783 54.8792 15.4158Z"
    fill="white"
  />
  <path
    d="M54.8792 15.4158L51.121 11.6583C50.8425 11.3796 50.5119 11.1586 50.148 11.0078C49.784 10.8569 49.3939 10.7793 49 10.7793C48.6061 10.7793 48.216 10.8569 47.852 11.0078C47.4881 11.1586 47.1575 11.3796 46.879 11.6583L43 15.5365L51.0002 23.5368L54.8792 19.6578C55.4417 19.0952 55.7576 18.3323 55.7576 17.5368C55.7576 16.7413 55.4417 15.9783 54.8792 15.4158V15.4158Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M12 46.5376L19.9995 54.5371L51 23.5374L42.9998 15.5371L12 46.5376Z"
    fill="#B2D8FA"
  />
  <path
    d="M12 46.5376L19.9995 54.5371L51 23.5374L42.9998 15.5371L12 46.5376Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M16 50.5368L46.9998 19.5371"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
</svg>
