<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import ImageFrame from "../../../elements/ImageFrame.svelte";
</script>

<PageSection shaded>
  <div class="compliance-hero">
    <div class="columns top">
      <div class="column left">
        <Eyebrow>AML & Compliance</Eyebrow>
        <Heading2>Improved compliance outcomes</Heading2>
        <Body>
          Our platform can streamline and automate many important compliance
          processes to deliver improved outcomes for issuers, users, and
          regulated entities.
        </Body>
      </div>
      <div class="column right image">
        <ImageFrame
          shaded={false}
          width={560}
          src="/images/digital-hand.webp"
          alt="AML & Compliance"
        />
      </div>
    </div>
    <div class="icon-row">
      <div class="icon-column">
        <IconColumn icon="vault" heading="Freeze / Unfreeze">
          Freeze tokens indefinitely, or with a specified expiration, such that
          the tokens unfreeze automatically after the specified time.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="judgement" heading="Confiscate">
          Confiscate frozen, or active, tokens and transfer them to a new
          address. Confiscated tokens can go to any address.
        </IconColumn>
      </div>

      <div class="icon-column">
        <IconColumn icon="id-scan" heading="KYC and Whitelisting">
          Tokens can, optionally, be restricted to addresses that are linked to
          entities that have had their identity verified with KYC/KYB processes.
          The whitelisting is enforced by the smart contract with help from the
          relevant identity oracles.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="permission" heading="SaaS, Hybrid, and On-prem">
          Our architecture is modular and configurable, and allows for different
          types of hosting arrangements to satisfy different business and
          regulatory requirements.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .compliance-hero {
    @extend .generic-section;
    flex-direction: column;
    align-items: center;
    .bottom {
      gap: 50px 48px;
      flex-wrap: wrap;
      > .column {
        width: fit-content;
        align-items: flex-start;
        .buttons {
          width: max-content;
        }
      }

      @media only screen and (min-width: $size-desktop-min) {
        width: 100%;
        max-width: 1264px;
      }
    }
  }
</style>
