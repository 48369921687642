<script>
  import Icon from "components/elements/Icon";
  import MobileMenu from "components/widgets/MobileMenu";

  export let dark = true;

  let menuVisible = false;
  const onMenuClick = () => {
    menuVisible = !menuVisible;
  };
</script>

<div class="menu-button">
  <div on:click={onMenuClick}>
    <Icon name="menu" colorName={dark ? "B40" : "B65"} />
  </div>
  {#if menuVisible}
    <MobileMenu {onMenuClick} />
  {/if}
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .menu-button {
    margin-right: 8px;
    display: none;
  }

  @media screen and (max-width: $size-tablet-max) {
    .menu-button,
    .menu-button > div:first-child {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
</style>
