<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M48.0002 31.9995C48.0002 40.2833 41.284 46.9995 33.0002 46.9995C24.7157 46.9995 18.0002 40.2833 18.0002 31.9995C18.0002 23.7158 24.7157 16.9995 33.0002 16.9995C41.284 16.9995 48.0002 23.7158 48.0002 31.9995Z"
    fill="#EEF0F2"
  />
  <path
    d="M54.0003 20.999H48.0827L43.9997 26.999H42.0002M55 31.9993H43.9997"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M51.0002 20.999C51.0002 21.7947 51.3163 22.5577 51.8789 23.1203C52.4415 23.683 53.2046 23.999 54.0002 23.999C54.7959 23.999 55.5589 23.683 56.1216 23.1203C56.6842 22.5577 57.0002 21.7947 57.0002 20.999C57.0002 20.2034 56.6842 19.4403 56.1216 18.8777C55.5589 18.3151 54.7959 17.999 54.0002 17.999C53.2046 17.999 52.4415 18.3151 51.8789 18.8777C51.3163 19.4403 51.0002 20.2034 51.0002 20.999Z"
    fill="#B2D8FA"
  />
  <path
    d="M54.0002 42.9995H48.0827L44.1242 36.9995H42.0002M51.0002 20.999C51.0002 21.7947 51.3163 22.5577 51.8789 23.1203C52.4415 23.683 53.2046 23.999 54.0002 23.999C54.7959 23.999 55.5589 23.683 56.1216 23.1203C56.6842 22.5577 57.0002 21.7947 57.0002 20.999C57.0002 20.2034 56.6842 19.4403 56.1216 18.8777C55.5589 18.3151 54.7959 17.999 54.0002 17.999C53.2046 17.999 52.4415 18.3151 51.8789 18.8777C51.3163 19.4403 51.0002 20.2034 51.0002 20.999Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M51.0002 43C51.0002 42.2044 51.3163 41.4413 51.8789 40.8787C52.4415 40.3161 53.2046 40 54.0002 40C54.7959 40 55.5589 40.3161 56.1216 40.8787C56.6842 41.4413 57.0002 42.2044 57.0002 43C57.0002 43.7956 56.6842 44.5587 56.1216 45.1213C55.5589 45.6839 54.7959 46 54.0002 46C53.2046 46 52.4415 45.6839 51.8789 45.1213C51.3163 44.5587 51.0002 43.7956 51.0002 43Z"
    fill="#B2D8FA"
  />
  <path
    d="M51.0002 43C51.0002 42.2044 51.3163 41.4413 51.8789 40.8787C52.4415 40.3161 53.2046 40 54.0002 40C54.7959 40 55.5589 40.3161 56.1216 40.8787C56.6842 41.4413 57.0002 42.2044 57.0002 43C57.0002 43.7956 56.6842 44.5587 56.1216 45.1213C55.5589 45.6839 54.7959 46 54.0002 46C53.2046 46 52.4415 45.6839 51.8789 45.1213C51.3163 44.5587 51.0002 43.7956 51.0002 43Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M52 31.9995C52 32.7952 52.3161 33.5582 52.8787 34.1208C53.4413 34.6834 54.2044 34.9995 55 34.9995C55.7956 34.9995 56.5587 34.6834 57.1213 34.1208C57.6839 33.5582 58 32.7952 58 31.9995C58 31.2039 57.6839 30.4408 57.1213 29.8782C56.5587 29.3156 55.7956 28.9995 55 28.9995C54.2044 28.9995 53.4413 29.3156 52.8787 29.8782C52.3161 30.4408 52 31.2039 52 31.9995Z"
    fill="#B2D8FA"
  />
  <path
    d="M45.8373 51.9998L39.0828 41.9993H37.9998M52 31.9995C52 32.7952 52.3161 33.5582 52.8787 34.1208C53.4413 34.6834 54.2044 34.9995 55 34.9995C55.7956 34.9995 56.5587 34.6834 57.1213 34.1208C57.6839 33.5582 58 32.7952 58 31.9995C58 31.2039 57.6839 30.4408 57.1213 29.8782C56.5587 29.3156 55.7956 28.9995 55 28.9995C54.2044 28.9995 53.4413 29.3156 52.8787 29.8782C52.3161 30.4408 52 31.2039 52 31.9995Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M43 52C43 51.2043 43.3161 50.4413 43.8787 49.8787C44.4413 49.3161 45.2044 49 46 49C46.7956 49 47.5587 49.3161 48.1213 49.8787C48.6839 50.4413 49 51.2043 49 52C49 52.7956 48.6839 53.5587 48.1213 54.1213C47.5587 54.6839 46.7956 55 46 55C45.2044 55 44.4413 54.6839 43.8787 54.1213C43.3161 53.5587 43 52.7956 43 52Z"
    fill="#B2D8FA"
  />
  <path
    d="M45.8372 13.9995L39.0827 21.9998H33.0002M43 51.9998C43 51.2041 43.3161 50.4411 43.8787 49.8784C44.4413 49.3158 45.2043 48.9998 46 48.9998C46.7956 48.9998 47.5587 49.3158 48.1213 49.8784C48.6839 50.4411 49 51.2041 49 51.9998C49 52.7954 48.6839 53.5585 48.1213 54.1211C47.5587 54.6837 46.7956 54.9998 46 54.9998C45.2043 54.9998 44.4413 54.6837 43.8787 54.1211C43.3161 53.5585 43 52.7954 43 51.9998Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M43 13.9995C43 14.7952 43.3161 15.5582 43.8787 16.1208C44.4413 16.6834 45.2044 16.9995 46 16.9995C46.7956 16.9995 47.5587 16.6834 48.1213 16.1208C48.6839 15.5582 49 14.7952 49 13.9995C49 13.2039 48.6839 12.4408 48.1213 11.8782C47.5587 11.3156 46.7956 10.9995 46 10.9995C45.2044 10.9995 44.4413 11.3156 43.8787 11.8782C43.3161 12.4408 43 13.2039 43 13.9995Z"
    fill="#B2D8FA"
  />
  <path
    d="M43 13.9995C43 14.7952 43.3161 15.5582 43.8787 16.1208C44.4413 16.6834 45.2044 16.9995 46 16.9995C46.7956 16.9995 47.5587 16.6834 48.1213 16.1208C48.6839 15.5582 49 14.7952 49 13.9995C49 13.2039 48.6839 12.4408 48.1213 11.8782C47.5587 11.3156 46.7956 10.9995 46 10.9995C45.2044 10.9995 44.4413 11.3156 43.8787 11.8782C43.3161 12.4408 43 13.2039 43 13.9995Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M25.9998 8.99902C22.825 8.99902 20.1483 11.114 19.2903 14.0113C15.2463 14.1388 12.0003 17.885 12.0003 22.499C12.0003 22.8553 12.025 23.2055 12.0633 23.549C9.11876 24.9005 7 28.6153 7 32.999C7 35.7853 7.85575 38.3023 9.23425 40.115C8.986 41.1768 8.92332 42.2734 9.049 43.3565C9.58375 48.0095 13.3398 51.4003 17.4438 50.945C18.5268 53.885 21.496 55.9993 25 55.9993C29.4175 55.9993 33.0003 52.6415 33.0003 48.4993V15.9995C33.0004 15.0802 32.8193 14.1698 32.4676 13.3204C32.1158 12.471 31.6001 11.6993 30.9501 11.0492C30.3 10.3991 29.5282 9.88348 28.6788 9.53171C27.8295 9.17993 26.9191 8.99892 25.9998 8.99902Z"
    fill="#B2D8FA"
  />
  <path
    d="M25.9998 8.99902C22.825 8.99902 20.1483 11.114 19.2903 14.0113C15.2463 14.1388 12.0003 17.885 12.0003 22.499C12.0003 22.8553 12.025 23.2055 12.0633 23.549C9.11876 24.9005 7 28.6153 7 32.999C7 35.7853 7.85575 38.3023 9.23425 40.115C8.986 41.1768 8.92332 42.2734 9.049 43.3565C9.58375 48.0095 13.3398 51.4003 17.4438 50.945C18.5268 53.885 21.496 55.9993 25 55.9993C29.4175 55.9993 33.0003 52.6415 33.0003 48.4993V15.9995C33.0004 15.0802 32.8193 14.1698 32.4676 13.3204C32.1158 12.471 31.6001 11.6993 30.9501 11.0492C30.3 10.3991 29.5282 9.88348 28.6788 9.53171C27.8295 9.17993 26.9191 8.99892 25.9998 8.99902V8.99902Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M25.9998 22.9997C24.1433 22.9997 22.3629 22.2623 21.0502 20.9496C19.7375 19.6369 19 17.8564 19 16C19 15.3047 19.1013 14.6327 19.2903 13.999M16.9998 32.659C19.189 33.967 21.7322 32.821 23.5577 30.6587C25.8662 27.925 29.6702 26.86 31.978 29.5937C32.3913 30.0841 32.7353 30.629 33.0002 31.213M32.9087 18.97C29.833 19.6247 27.0423 17.833 25.9998 14.9995M32.9087 44.1347C29.833 43.48 27.0423 45.2717 25.9998 48.1045M21.0018 20.9987C19 21.4997 16.9998 22.9577 16.9998 25M18.0018 43.9997C16 43.4987 13.9998 42.04 13.9998 39.9985M18.0003 52.135C17.3457 51.0354 17 49.7794 16.9998 48.4997C16.9998 44.3575 20.5817 40.9997 25 40.9997V40C25 38.2495 27.4112 35.9995 28.9997 35.9995M13 28.9997C13.4639 28.993 13.9246 29.0785 14.3551 29.2513C14.7857 29.4241 15.1776 29.6808 15.5081 30.0065C15.8386 30.3322 16.101 30.7203 16.2801 31.1483C16.4592 31.5763 16.5515 32.0356 16.5515 32.4996C16.5515 32.9636 16.4592 33.4229 16.2801 33.8509C16.101 34.2789 15.8386 34.667 15.5081 34.9927C15.1776 35.3184 14.7857 35.5751 14.3551 35.7479C13.9246 35.9208 13.4639 36.0063 13 35.9995M33.0002 26.9995H36.0002M37.9997 26.9995H40M40 37H37.9997M36.0002 37H33.0002M33.0002 41.9995H36.0002M33.0002 31.9997H37.9997M40 31.9997H42.0002M33.0002 7V58M33.0002 4.99975V1M33.0002 59.9995V64"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
