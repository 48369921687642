<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M57.9999 32.5369C57.9999 48.0011 45.4637 60.5365 30.0003 60.5365C14.5362 60.5365 2 48.0011 2 32.5369C2 17.0728 14.5362 4.53735 30.0003 4.53735C45.4637 4.53735 57.9999 17.072 57.9999 32.5369Z"
    fill="#EEF0F2"
  />
  <path d="M61.9995 47.5365H10V18.5364H61.9995V47.5365Z" fill="white" />
  <path
    d="M22.5353 39.0015C23.473 39.9391 23.9999 41.2107 24.0001 42.5367C24.0001 43.8628 23.4734 45.1344 22.5357 46.0721C21.598 47.0098 20.3261 47.5366 19 47.5366H10V37.5372H18.9999C20.3259 37.5372 21.5976 38.0639 22.5353 39.0015Z"
    fill="#B2D8FA"
  />
  <path
    d="M49.9995 18.5364H10V47.5365H61.9995V22.5369"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M46.9998 42.5363H55.9998M17 25.5369H25.0002M27.0004 30.5363H31.9999M27.0004 25.5369H40.0001"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M1 12.5369H18.9999C20.326 12.5369 21.5979 13.0637 22.5356 14.0014C23.4733 14.9391 24.0001 16.2109 24.0001 17.5371V42.5367C23.9999 41.2107 23.473 39.939 22.5353 39.0015C21.5976 38.0639 20.3259 37.5372 18.9999 37.5372H1V12.5369Z"
    fill="white"
  />
  <path
    d="M1 12.5369H18.9999C20.326 12.5369 21.5979 13.0637 22.5356 14.0014C23.4733 14.9391 24.0001 16.2109 24.0001 17.5371V42.5367C23.9999 41.2107 23.473 39.939 22.5353 39.0015C21.5976 38.0639 20.3259 37.5372 18.9999 37.5372H1V12.5369Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M19 47.5366C20.3261 47.5366 21.598 47.0098 22.5357 46.0721C23.4734 45.1344 24.0002 43.8625 24.0002 42.5364"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M59.5861 20.9506L40.9997 39.5363L34 42.5362L37 35.5365L55.5856 16.9509C55.7713 16.7651 55.9918 16.6178 56.2344 16.5173C56.477 16.4167 56.7371 16.365 56.9998 16.365C57.2624 16.365 57.5224 16.4167 57.7651 16.5173C58.0077 16.6178 58.2282 16.7651 58.4139 16.9509L59.5861 18.1224C59.7718 18.3081 59.9192 18.5285 60.0197 18.7712C60.1203 19.0138 60.172 19.2739 60.172 19.5365C60.172 19.7991 60.1203 20.0592 60.0197 20.3019C59.9192 20.5445 59.7718 20.7649 59.5861 20.9506Z"
    fill="#B2D8FA"
  />
  <path
    d="M59.5861 20.9506L40.9997 39.5363L34 42.5362L37 35.5365L55.5856 16.9509C55.7713 16.7651 55.9918 16.6178 56.2344 16.5173C56.477 16.4167 56.7371 16.365 56.9997 16.365C57.2624 16.365 57.5224 16.4167 57.7651 16.5173C58.0077 16.6178 58.2282 16.7651 58.4139 16.9509L59.5861 18.1224C59.7718 18.3081 59.9192 18.5285 60.0197 18.7712C60.1203 19.0138 60.172 19.2739 60.172 19.5365C60.172 19.7991 60.1203 20.0592 60.0197 20.3019C59.9192 20.5445 59.7718 20.7649 59.5861 20.9506V20.9506Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M54 18.5364L57.9997 22.5369M57.9997 16.5369L60 14.5366C60.2652 14.2717 60.6248 14.1228 60.9997 14.1228C61.3746 14.1228 61.7342 14.2717 61.9995 14.5366V14.5366C62.5515 15.0886 62.5515 15.9841 61.9995 16.5369L60 18.5364L57.9997 16.5369Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path d="M37.9995 34.5369L42 38.5366L37.9995 34.5369Z" fill="#B2D8FA" />
  <path
    d="M37.9995 34.5369L42 38.5366"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M45.0002 42.5364H43"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
</svg>
