<script>
  import Icon from "components/elements/Icon";
  import Anchor from "components/elements/Anchor";
  import {
    TELEGRAM_URL,
    LINKEDIN_URL,
    TWITTER_URL,
    DISCOURSE_URL,
    GITHUB_URL,
  } from "utils/menu";
</script>

<div class="social {$$props.class || ''}">
  <Anchor href={TWITTER_URL}><Icon name="twitter" /></Anchor>
  <Anchor href={LINKEDIN_URL}><Icon name="linkedin" /></Anchor>
  <Anchor href={GITHUB_URL}><Icon name="github" /></Anchor>
  <Anchor href={DISCOURSE_URL}><Icon name="discourse" /></Anchor>
  <Anchor href={TELEGRAM_URL}><Icon name="telegram" /></Anchor>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .social {
    margin-top: 0px;
    gap: 16px;
    display: flex;
    align-items: center;
  }
</style>
