/** Header menu contents */

export const PRODUCTION_HOSTNAME = "tokenized.com";

// External resource URLs
export const REQUEST_ACCESS_FORM_URL =
  "https://webforms.pipedrive.com/f/1yNvgKrJOQhAYEuTNl4bJgnPgEj6ve0MsKLd14iSPiRaUvsf4WV7eSVmcBuFEZ5Rh";
export const BLOG_URL = "https://medium.com/tokenized-blog";
export const API_DOCS_URL = "https://docs.api.tokenized.com/";
export const SDK_DOCS_URL =
  "https://docs.api.tokenized.com/sdk/0.8.0/react/index.html";
export const PROTOCOL_DOCS_URL = "/protocol/docs/";
export const LEGAL_DOCS_URL = "/legal/";
export const PRIVACY_POLICY = "/legal/privacy";
export const TERMS_AND_CONDITIONS = "/legal/terms";
export const CONTACT_EMAIL = "inquiries@tokenized.com";
export const CONTACT_URL = `https://webforms.pipedrive.com/f/1I1NtowMr98RQNe3c1LViibRvDNIavw9VafwhxqEi6XJju7zpCuVlBDpRZNdaR3lV`;
export const TRAVEL_RULE_URL =
  "https://www.fatf-gafi.org/publications/fatfrecommendations/documents/guidance-rba-virtual-assets-2021.html";

export const DOWNLOADS_URL = "/#/download/";
export const EMAIL_DOWNLOAD_LINK_URL = "/#/download/email-link";

// Download URLs per platform

export const DOWNLOAD_WALLET_WINDOWS_URL =
  "https://downloads.tokenized.com/prod/Tokenized.exe";
export const DOWNLOAD_WALLET_MAC_URL =
  "https://downloads.tokenized.com/prod/Tokenized.dmg";
export const DOWNLOAD_WALLET_LINUX_URL =
  "https://downloads.tokenized.com/prod/Tokenized.AppImage";
export const DOWNLOAD_AUTHENTICATOR_IOS_URL =
  "https://apps.apple.com/us/app/tokenized-authenticator/id1499017570";
export const DOWNLOAD_AUTHENTICATOR_ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.tokenized";

// Social links

export const TELEGRAM_URL = "https://t.me/joinchat/TWC9JGKemPMwZjc1";
export const LINKEDIN_URL = "https://www.linkedin.com/company/tokenized/";
export const TWITTER_URL = "https://twitter.com/Tokenized_com";
export const DISCOURSE_URL = "https://forum.tokenized.com/";
export const GITHUB_URL = "https://github.com/tokenized";

export const BUTTON_DATA = [
  { label: "Platform", link: "" },
  // { label: "Customers", link: "" },
  // { label: "Learn", link: "" },
  { label: "Developers", link: "" },
  { label: "Company", link: "" },
];

/**
 * Could be API-driven or taken from a JSON file.
 * Hardcoded for now.
 */
export const MENU_CONTENTS = {
  0: [
    {
      icon: "wallet",
      title: "Digital Wallet",
      description: "Manage digital assets, liabilities, and equity",
      link: "/platform/wallet/",
    },
    {
      icon: "settings",
      title: "Smart Contract Engine",
      description: "Draft, issue, and manage smart contracts",
      link: "/platform/contracts/",
    },
    {
      icon: "items",
      title: "Tokenization",
      description: "Tokenize any security or utility instrument",
      link: "/platform/tokenization/",
    },
    {
      icon: "application",
      title: "Compliance",
      description: "Ensure strict compliance with laws and regulations",
      link: "/platform/compliance/",
    },
    {
      icon: "tag",
      title: "Pricing",
      description: "Workspace plans and smart contract fees",
      link: "/platform/pricing/",
    },
  ],
  1: [
    {
      icon: "book-open",
      title: "Tokenized Protocol",
      description: "Documentation for the smart contract protocol",
      link: PROTOCOL_DOCS_URL,
    },
    {
      icon: "tool",
      title: "SDK Documentation",
      description: "A JavaScript software development kit",
      link: SDK_DOCS_URL,
    },
    {
      icon: "code",
      title: "API Reference",
      description: "Our platform’s API documentation",
      link: API_DOCS_URL,
    },
  ],
  2: [
    {
      icon: "info",
      title: "About Us",
      description: "Learn about our company",
      link: "/platform/about/",
    },
    {
      icon: "document",
      title: "Blog",
      description: "Our team’s latest news and thoughts",
      link: BLOG_URL,
    },
    {
      icon: "mail",
      title: "Contact",
      description: "Reach out to connect with our team",
      link: CONTACT_URL,
    },
  ],
};
