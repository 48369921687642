<script>
  import Icon from "components/elements/Icon";
  import UAParser from "ua-parser-js";
  import TextButton from "elements/TextButton.svelte";
  import { COLOR_PRIMARY } from "buttonStyles";
  import Anchor from "elements/Anchor.svelte";
  import { apiPost } from "utils/api";

  const parser = new UAParser();
  const operatingSystem = parser.getOS() || { name: "Windows" };

  let email = "";
  let isEmailValid = false;
  let isEmailSent = false;

  function validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    isEmailValid = emailRegex.test(email);
  }

  async function sendDownloadLink() {
    console.log("Requesting a download link for: ", email);
    const body = new URLSearchParams({ email }).toString();
    try {
      const response = await apiPost("/auth/emailLink", body, {
        "Content-Type": "application/x-www-form-urlencoded",
      });
      console.log("emailLink response: ", await response.text());
    } catch (e) {
      console.error("emailLink error: ", e);
    }

    if (isEmailValid) {
      isEmailSent = true;
    }
  }
</script>

<div class="lbc-section">
  <div class="lbc-main">
    <div class="logo">
      <Icon name="logo-dark" width="192" height="40" />
    </div>
    {#if isEmailSent}
      <div class="form">
        <h2>Download link sent</h2>
        <p>
          Use your Mac, Windows, or Linux device to follow the download link,
          install the desktop app, and sign up.
        </p>
        <h2>Next, get the mobile app</h2>
        <p>
          To secure your account, you’ll also need the Tokenized Authenticator
          app for iOS or Android.
        </p>
      </div>
      <div class="download-apps">
        {#if operatingSystem.name !== "iOS"}
          <Anchor
            href="https://play.google.com/store/apps/details?id=com.tokenized"
            ><img
              src="/images/google_play.svg"
              alt="tokenized google play link"
            />
          </Anchor>{/if}
        {#if operatingSystem.name !== "Android"}
          <Anchor
            href="https://apps.apple.com/us/app/tokenized-authenticator/id1499017570"
            ><img src="/images/app_store.svg" alt="tokenized app store link" />
          </Anchor>{/if}
      </div>
    {:else}
      <div class="form">
        <h2>Download the desktop app with a link sent to your inbox</h2>

        <div class="email-input">
          <span class="label"> Email </span>
          <input
            class="input-email"
            placeholder="name@example.com"
            bind:value={email}
            on:input={validateEmail}
          />
        </div>
        <TextButton
          center
          onClick={sendDownloadLink}
          disabled={!isEmailValid}
          large
          color={COLOR_PRIMARY}
        >
          Send me a download link
        </TextButton>
        <span class="terms">
          By submitting your email address, you agree to receive marketing
          emails and other communications from Tokenized. For more details,
          please review our <a href="/#/legal/privacy" target="_blank"
            >Privacy Policy</a
          >
          and
          <a href="/#/legal/terms" target="_blank">Terms and Conditions</a>.
        </span>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .lbc-section {
    display: flex;
    flex-direction: column;
    padding: 96px 16px;
    align-items: center;
    color: $B10;
    background-color: $I85;
    min-height: 100vh;
  }
  .lbc-main {
    display: flex;
    flex-direction: column;
    width: 384px;
    color: $TL95;
    .form {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      color: inherit;
      h2 {
        font-size: 24px;
        line-height: 32px;
        color: inherit;
      }
      h2,
      p {
        margin: 0px !important;
      }
      p {
        color: $TL85;
      }
      .email-input {
        span.label {
          font-size: 13px;
          font-weight: 600;

          color: $I45;
        }
        .input-email {
          background-color: $white;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 48px;
          border: none;
          box-shadow: 0 0 0px 4px $TB15;
          border-radius: 6px;
          font-size: 15px;
          gap: 0px;
          padding: 12px 18px;
          margin-top: 9px;
          &:focus {
            outline: none;
            box-shadow: 0 0 0px 4px $B65;
          }
          &::placeholder {
            color: $N45;
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
      :global(.button) {
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        border: solid 1px $I75;
      }
      .terms,
      .terms a {
        font-size: 14px;
        line-height: 18px;
        color: $TL45;
        a {
          text-decoration: underline;
        }
      }
    }
    .download-apps {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }

  @media only screen and (max-width: $size-phone-max) {
    .lbc-main {
      width: 90vw;
    }
  }
</style>
