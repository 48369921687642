<script>
  import { querystring } from "svelte-spa-router";
  import { onMount, afterUpdate, tick } from "svelte";
  import PageLayout from "components/PageLayout.svelte";
  import Spinner from "components/elements/Spinner.svelte";
  import { COLOR_PRIMARY } from "buttonStyles";
  import TextButton from "components/elements/TextButton.svelte";
  import BackToTop from "components/elements/BackToTop.svelte";
  import DocumentMenu from "components/widgets/DocumentMenu.svelte";
  import {
    getPaginator,
    scrollToSection,
    scrollToTop,
    loadTOC,
    parseAnchors,
    getParsedPageData,
    getParsedURLParams,
  } from "modules/Document.svelte";

  export let params;
  export let defaultSubPage;
  export let baseDocumentUrl;
  export let menuTitle;
  export let documentVersion;
  export let documentSourceUrl;

  let previousPage;
  let activeSubPage;
  let activeSection;
  let loading = false;
  let parsedTOC = {};
  let pageData = "";
  let currentPaginator;
  $: {
    currentPaginator = getPaginator(activeSubPage, parsedTOC);
  }
  const parseURLParams = () => {
    [activeSubPage, activeSection] = getParsedURLParams(params, defaultSubPage);
    navigateToPage(activeSubPage, activeSection);
  };

  const navigateToPage = async (page, section) => {
    loading = true;
    activeSubPage = page;
    await loadPageData(page);
    loading = false;
    scrollToSection(section, tick);

    // If no section is specified, scroll to top by default
    if (!section) {
      scrollToTop();
    }
  };

  const loadPageData = async (page) => {
    // Do not reload the same page
    if (page === previousPage) {
      return;
    }
    previousPage = page;
    pageData = ""; // Clear (and rerender) previous page before loading new one
    pageData = await getParsedPageData(page, documentSourceUrl);
  };

  const initSubPage = async () => {
    parseURLParams();
    parsedTOC = await loadTOC(documentSourceUrl);
    navigateToPage(activeSubPage, activeSection);
  };

  const onHashChange = () => {
    parseURLParams();
  };

  onMount(() => initSubPage(documentSourceUrl));
  afterUpdate(() => {
    parseAnchors(baseDocumentUrl, activeSubPage, navigateToPage);
  });

  // Reload dynamic page contents upon all navigation (incl. back button)
  window.onhashchange = onHashChange;

  // The legal docs are shown in an embedded web view inside the
  // mobile and desktop apps – where we don’t want to show any
  // navigation elements, just the formatted document content
  const urlParams = new URLSearchParams($querystring);
  const isEmbedded = urlParams.has("embedded");
</script>

{#if isEmbedded}
  <div class="document-block">
    {#if !pageData || loading}
      <Spinner />
    {:else}
      <div class="wrapper columns">
        <div class="page-contents">
          {@html pageData}
        </div>
      </div>
    {/if}
  </div>
{:else}
  <PageLayout paddedHeader>
    <div class="document-block">
      {#if !pageData || loading}
        <Spinner />
      {:else}
        <div class="wrapper columns">
          <div class="menu-left">
            <div class="mobile-visible">
              <DocumentMenu
                bind:parsedTOC
                bind:activeSubPage
                {navigateToPage}
                {baseDocumentUrl}
                {documentVersion}
                {menuTitle}
                mobile
              />
            </div>
            <div class="desktop-visible">
              <DocumentMenu
                bind:parsedTOC
                bind:activeSubPage
                {navigateToPage}
                {baseDocumentUrl}
                {documentVersion}
                {menuTitle}
              />
            </div>
          </div>
          <div class="page-contents">
            {@html pageData}
            <div class="paginator">
              <div class="pag-button previous">
                {#if currentPaginator.previousLink}
                  <TextButton
                    color={COLOR_PRIMARY}
                    iconLeft="arrow-right"
                    href={`#${baseDocumentUrl}${currentPaginator.previousLink}`}
                  >
                    {currentPaginator.previousLabel}
                  </TextButton>
                {/if}
              </div>
              <div class="pag-button">
                {#if currentPaginator.nextLink}
                  <TextButton
                    color={COLOR_PRIMARY}
                    iconRight="arrow-right"
                    href={`#${baseDocumentUrl}${currentPaginator.nextLink}`}
                  >
                    {currentPaginator.nextLabel}
                  </TextButton>
                {/if}
              </div>
            </div>
          </div>
          <BackToTop />
        </div>
      {/if}
    </div>
  </PageLayout>
{/if}

<style lang="scss">
  @import "../../../styles/global.scss";
  :global(a) {
    color: $B65;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: $B75;
    }
  }

  .document-block {
    @extend .generic-section;
    row-gap: 0px;
    background-color: $white;
    border-top: solid 1px $N10;
    min-height: calc(100vh - 429px);
    @media only screen and (max-width: $size-tablet-max) {
      min-height: calc(100vh - 566px);
    }
    @media only screen and (max-width: $size-phablet-max) {
      min-height: 100%;
    }

    .wrapper {
      flex-direction: row;
      align-items: stretch;

      @media only screen and (max-width: $size-tablet-max) {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
      }

      .menu-left {
        width: calc(25% - 36px);
        padding: 40px 0;
        border-right: solid 1px $N10;

        @media only screen and (max-width: $size-tablet-max) {
          width: 100%;
          padding: 24px 0;
          border-right: solid 0px $N10;

          :global(.desktop-visible) {
            display: none;
          }
          :global(.mobile-visible) {
            display: flex;
          }
        }

        @media only screen and (max-width: $size-phone-max) {
          padding: 16px 0;
        }
      }
      .page-contents {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 12px 8px 0px 16px;
        overflow-x: auto;
        @media only screen and (max-width: $size-phablet-max) {
          padding: 0 16px;
        }

        :global(ul.flags li) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: nowrap;
          width: fit-content;
          gap: 12px;
        }

        :global(ul li img) {
          padding: 0px;
          border: unset;
          max-width: 24px;
        }

        :global(h1) {
          font-size: 30px;
          font-weight: 700;
          color: $B80;
          line-height: 1.2;
        }

        :global(h2) {
          margin-top: 40px;
        }

        :global(h3) {
          font-size: 18px;
          font-weight: 600;
          color: $N90;
          margin-bottom: 8px;
          line-height: 20px;
        }

        :global(h4) {
          font-size: 15px;
          font-weight: 500;
          color: $N90;
          margin-top: 12px;
          margin-bottom: 12px;
          font-weight: 400;
        }

        :global(h5) {
          font-size: 15px;
          font-weight: 400;
          color: $N80;
          margin: 8px 0px 8px 24px;
          font-weight: 400;
        }

        :global(h6) {
          font-size: 14px;
          font-weight: 400;
          color: $N80;
          margin: 8px 0px 8px 36px;
          font-weight: 400;
        }

        :global(table) {
          width: 80%;
        }

        :global(pre) {
          width: 80% !important;
        }

        :global(.copy-link) {
          display: inline-block;
          color: $N45;
          height: 24px;
          > :global(svg) {
            margin-left: 8px;
            margin-right: 8px;
            &:hover {
              color: $N60;
            }
          }
        }
        :global(.copied-link) {
          text-decoration: none;
          > :global(span) {
            font-size: 11px;
          }
        }
        :global(.copy-link:hover::before) {
          color: $N60;
        }
        :global(.copied-link) {
          color: $N45;
          text-transform: uppercase;
        }
        :global(.copied-link::before) {
          color: $N45;
        }

        :global(ul) {
          list-style: none;
        }

        :global(li) {
          margin: 2px 0;
        }

        :global(ul li::before) {
          content: "\2022";
          color: $N30;
          display: inline-block;
          vertical-align: top;
          width: 1.25em;
          margin-left: -1.25em;
        }

        :global(li p) {
          display: inline-block;
          vertical-align: top;
          margin-top: 0;
        }

        :global(img) {
          display: block;
          max-width: 90%;
          margin: auto;
          border: 1px solid $N10;
          padding: 20px;
          @media only screen and (max-width: $size-phablet-max) {
            max-width: 80%;
          }
        }
        :global(pre) {
          font-size: 14px;
          margin-bottom: 30px;
          padding: 20px;
          border-radius: 5px;
          background: #f2f2f2;
          width: 100%;
          white-space: pre-wrap;
        }
        :global(blockquote) {
          padding: 12.5px 25px;
          margin: 0 0 25px;
          border-radius: 5px;
          background: #f2f2f2 !important;
          border-left: 7px solid #707070;
        }
        :global(.collection-method-list > *) {
          column-count: 3;
          column-gap: 2em;
          @media only screen and (max-width: $size-phablet-max) {
            column-count: 2;
          }
          @media only screen and (max-width: $size-phone-max) {
            column-count: 1;
          }
        }
        :global(table) {
          text-align: start;
          margin-bottom: 16px;
          width: 95%;
          word-break: break-word;
          max-width: 900px;
        }

        :global(th) {
          text-align: start;
        }
        :global(th),
        :global(td) {
          border-bottom: 1px solid $N10;
          word-wrap: break-word;
        }

        :global(tr:last-child th) {
          border-bottom: none;
        }
        :global(tr:last-child td) {
          border-bottom: none;
        }
        :global(td) {
          padding: 8px 10px;
          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            padding-left: 0px;
          }
        }
        :global(th) {
          padding: 8px 10px;
          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            padding-left: 0px;
          }
        }
        :global(span[name="image-label"]) {
          color: $N60;
          font-style: italic;
          margin: 16px auto 16px;
          display: table;
        }
        :global(.placeholder-link) {
          color: #000000;
        }
      }
    }
    :global(.paginator) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 108px;
      width: 100%;
      :global(.pag-button:first-child .icon) {
        transform: rotate(180deg);
      }
    }
  }
</style>
