<script>
  import { querystring } from "svelte-spa-router";
  import UAParser from "ua-parser-js";
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import ImageFrame from "elements/ImageFrame.svelte";
  import TextButton from "elements/TextButton.svelte";
  import { COLOR_ATTENTION } from "buttonStyles";
  import Anchor from "elements/Anchor.svelte";
  import {
    DOWNLOAD_WALLET_WINDOWS_URL,
    DOWNLOAD_WALLET_MAC_URL,
    DOWNLOAD_WALLET_LINUX_URL,
    DOWNLOAD_AUTHENTICATOR_IOS_URL,
    DOWNLOAD_AUTHENTICATOR_ANDROID_URL,
  } from "utils/menu";
  import { EMAIL_DOWNLOAD_LINK_URL } from "utils/menu";

  const DEFAULT_DOWNLOAD_BY_OS = {
    Windows: DOWNLOAD_WALLET_WINDOWS_URL,
    "Mac OS": DOWNLOAD_WALLET_MAC_URL,
    Linux: DOWNLOAD_WALLET_LINUX_URL,
    iOS: DOWNLOAD_AUTHENTICATOR_IOS_URL,
    Android: DOWNLOAD_AUTHENTICATOR_ANDROID_URL,
  };

  const VERSION_LIST = [
    {
      os: "Windows",
      title: "Desktop app for Windows",
      href: DOWNLOAD_WALLET_WINDOWS_URL,
      notice: "Windows 10 or Windows 11",
    },
    {
      os: "Mac OS",
      title: "Desktop app for Mac",
      href: DOWNLOAD_WALLET_MAC_URL,
      notice: "MacOS 10.13 High Sierra and later",
    },
    {
      os: "Linux",
      title: "Desktop app for Linux",
      href: DOWNLOAD_WALLET_LINUX_URL,
      notice: "Ubuntu 14.04, Fedora 24, Debian 8, and later",
    },
  ];

  const parser = new UAParser();
  const operatingSystem = parser.getOS() || { name: "Windows" };
  const isSupportedDesktopOs = VERSION_LIST.some(
    (version) => version.os === operatingSystem.name
  );
  const isMobile = (operatingSystem.name === "iOS" || operatingSystem.name === "Android");

  const params = new URLSearchParams($querystring);
  if (params.has("auto")) {
    if (DEFAULT_DOWNLOAD_BY_OS[operatingSystem.name]) {
      window.location.href = DEFAULT_DOWNLOAD_BY_OS[operatingSystem.name];
    }
  }

  const defaultVersion =
    VERSION_LIST.find((version) => version.os === operatingSystem.name) ||
    VERSION_LIST[0];
  const otherVersions = VERSION_LIST.filter(
    (version) => (!isSupportedDesktopOs || version.os !== defaultVersion.os)
  );
</script>

<PageSection shaded>
  <div class="downloads-hero">
    <div class="columns top">
      <div class="column left">
        <Eyebrow>Desktop app</Eyebrow>
        <Heading2>Get started today</Heading2>

        <div class="hero">
          {#if isSupportedDesktopOs}
            <span class="sub-heading"
              >Access all the platform features from the Tokenized desktop app</span
            >
            <TextButton
              iconLeft="download"
              center
              large
              color={COLOR_ATTENTION}
              href={defaultVersion.href}>{defaultVersion.title}</TextButton
            >
            <span class="notice">{defaultVersion.notice}</span>
          {:else}
            <span class="sub-heading"
              >Access all the platform features from the Tokenized desktop app for
              Windows, Mac, and Linux.</span
            >
            <TextButton
              center
              large
              color={COLOR_ATTENTION}
              href={EMAIL_DOWNLOAD_LINK_URL}
              >Send me a download link</TextButton
            >
          {/if}

          <div class="mobile-visible">
            <ImageFrame
              shaded={false}
              width={560}
              src="/images/laptop.webp"
              alt="Download Tokenized"
            />
          </div>

          <div class="versions">
            {#if isSupportedDesktopOs}
              <span class="title">Need another version?</span>
            {:else}
              <span class="title">Supported platforms</span>
            {/if}
            {#each otherVersions as version}
              <div class="ver">
                <span><Anchor href={version.href}>{version.title}</Anchor></span
                >
                <span> · {version.notice} </span>
              </div>
            {/each}
          </div>
        </div>
      </div>
      <div class="column image tablet-visible">
        <ImageFrame
          shaded={false}
          width={560}
          src="/images/laptop.webp"
          alt="Download Tokenized"
        />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .downloads-hero {
    @extend .generic-section;
    :global(.columns) {
      padding: 0px 32px 0px 0px !important;
    }
    :global(.tablet-visible) {
      padding-right: 8px !important;
    }
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    .hero {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      .sub-heading {
        color: $N90;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 24px;
      }
      .button {
        width: fit-content !important;
      }
      .notice {
        color: $N65;
        font-size: 16px;
        margin-bottom: 50px;
        margin-top: 20px;
      }
      .versions {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .title {
          color: $B95;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .ver {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 4px;
          color: $B65;
          font-size: 15px;
          span:last-child {
            color: $N65;
          }
        }
      }
    }

    @media only screen and (max-width: $size-desktop-min) {
      padding-left: 32px;
    }
    @media only screen and (max-width: $size-tablet-max) {
      padding-left: 24px;
    }
    @media only screen and (max-width: $size-phablet-max) {
      padding-left: 16px;
    }
    @media only screen and (max-width: $size-phone-max) {
      padding-left: 12px;
    }
    @media only screen and (min-width: $size-desktop-min) {
      width: 100%;
      max-width: 1264px;
    }

    @media only screen and (max-width: $size-laptop-min) {
      .hero {
        .mobile-visible {
          margin-top: 48px;
        }
        .versions {
          margin-top: 48px;
          .ver:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }

    @media only screen and (max-width: $size-phablet-min) {
      .versions {
        margin-top: 48px;
        .ver {
          display: flex;
          flex-direction: row;
          span:last-child {
            margin-left: 4px;
          }
        }
      }
    }
  }
</style>
