<script>
  export let width = 24;
  export let height = 24;
  export let color = "#2565BF";
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14.7003 6.29998C14.5171 6.48691 14.4145 6.73823 14.4145 6.99998C14.4145 7.26173 14.5171 7.51305 14.7003 7.69998L16.3003 9.29998C16.4873 9.48321 16.7386 9.58584 17.0003 9.58584C17.2621 9.58584 17.5134 9.48321 17.7003 9.29998L21.4703 5.52998C21.9732 6.64117 22.1254 7.87921 21.9068 9.07913C21.6882 10.279 21.1091 11.3838 20.2466 12.2463C19.3842 13.1087 18.2794 13.6878 17.0795 13.9064C15.8796 14.1251 14.6415 13.9728 13.5303 13.47L6.62034 20.38C6.22252 20.7778 5.68295 21.0013 5.12034 21.0013C4.55773 21.0013 4.01817 20.7778 3.62034 20.38C3.22252 19.9822 2.99902 19.4426 2.99902 18.88C2.99902 18.3174 3.22252 17.7778 3.62034 17.38L10.5303 10.47C10.0275 9.35879 9.87526 8.12075 10.0939 6.92083C10.3125 5.72092 10.8916 4.61614 11.7541 3.7537C12.6165 2.89127 13.7213 2.31215 14.9212 2.09352C16.1211 1.8749 17.3592 2.02714 18.4703 2.52998L14.7103 6.28998L14.7003 6.29998Z"
    stroke={color}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
