<script>
  export let src = false;
  export let shaded = true;
  export let width = 0;
  export let height = 0;
  export let alt = "";

  let styleWidth;
  let styleHeight;
  $: styleWidth = width > 0 ? `max-width: ${width}px;` : "width: auto";
  $: styleHeight = height > 0 ? `max-height: ${height}px;` : "height: auto";
</script>

<div
  class="image-frame {shaded ? 'shaded' : ''}"
  style="{styleWidth} {styleHeight}"
>
  <img {src} {alt} />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .image-frame {
    display: block;
    width: 100%;
    margin: auto;
    img {
      display: block;
      width: inherit;
      height: inherit;
    }
    &.shaded {
      border-radius: 16px;
      box-shadow:
        0px 30px 60px -12px rgba(63, 73, 89, 0.2),
        0px 18px 36px -18px rgba(0, 0, 0, 0.15);
      margin-bottom: 48px;
    }
  }
</style>
