<script>
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import { COLOR_PRIMARY } from "buttonStyles";
  import { DOWNLOADS_URL, CONTACT_URL } from "utils/menu";

  export let shaded = false;
</script>

<PageSection medium {shaded}>
  <div class="take-step">
    <div class="columns">
      <div class="column full">
        <Heading3>Ready to try Tokenized?</Heading3>
        <Body>
          Get started with the desktop app or request an in-depth demonstration
          from our team.
        </Body>
        <div class="buttons">
          <TextButton
            dark
            color={COLOR_PRIMARY}
            class="action-button"
            href={DOWNLOADS_URL}
          >
            Download the app
          </TextButton>
          <span class="contact-us"
            ><TextButton color={COLOR_PRIMARY} href={CONTACT_URL} noFill
              >Contact us
            </TextButton></span
          >
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .take-step {
    @extend .generic-section;
    .full {
      width: 100%;
      .buttons {
        gap: 20px;
        .contact-us {
          :global(.button) {
            color: $B80 !important;
          }
        }
      }
    }
  }
</style>
