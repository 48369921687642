<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M61.9992 32.0003C61.9992 47.4638 49.4637 59.9999 33.9996 59.9999C18.5354 59.9999 6 47.4638 6 32.0003C6 16.5362 18.5354 4 33.9996 4C49.4637 4 61.9992 16.5362 61.9992 32.0003Z"
    fill="#24375B"
  />
  <path
    d="M22.0145 51.7643L27.842 45.9353C27.842 45.9353 32.3847 47.0513 35.6202 43.8143L48.3483 31.0861L39.9994 19H30.9994C29.0832 18.9182 27.5682 19.339 26.1792 20.728L19.5065 27.4014L8.58057 38.3289L22.0145 51.7643Z"
    fill="white"
  />
  <path
    d="M22.0145 51.7643L27.842 45.9353C27.842 45.9353 32.3847 47.0513 35.6202 43.8143L48.3483 31.0861L39.9994 19H30.9994C29.0832 18.9182 27.5682 19.339 26.1792 20.728L19.5065 27.4014L8.58057 38.3289L22.0145 51.7643Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M3 41L8.99997 35L25.9994 51.9994L19.9994 57.9994L3 41Z"
    fill="#B2D8FA"
  />
  <path
    d="M7.99949 39.9995L9.99972 41.9997M3 41L8.99997 35L25.9994 51.9994L19.9994 57.9994L3 41Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M48.9995 15C42.3725 15 36.9995 20.373 36.9995 26.9999C36.9995 33.6276 42.3725 38.9999 48.9995 38.9999C52.0774 38.9999 54.8757 37.8321 56.9997 35.9271V18.0742C54.8757 16.1692 52.0774 15 48.9995 15Z"
    fill="#B2D8FA"
  />
  <path
    d="M48.9995 15C42.3725 15 36.9995 20.373 36.9995 26.9999C36.9995 33.6276 42.3725 38.9999 48.9995 38.9999C52.0774 38.9999 54.8757 37.8321 56.9997 35.9271V18.0742C54.8757 16.1692 52.0774 15 48.9995 15V15Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M43.9988 26H29.9994L28.0562 39.1219C28.0562 39.1219 34.0441 38.1274 35.9986 32.9997H42.9991C48.7913 32.9997 47.6243 26 43.9988 26Z"
    fill="white"
  />
  <path
    d="M29.9994 26.0005H43.9988C47.6243 26.0005 48.7913 33.0002 42.9991 33.0002H35.9994C34.0441 38.1279 28.0562 39.1224 28.0562 39.1224"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M50.9993 41.0003V44.0003H56.9992V10H50.9993V13"
    stroke="#7A95C1"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
