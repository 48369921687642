/** Button style constants */

export const THEME_DARK = Symbol('dark');
export const THEME_LIGHT = Symbol('light');

export const SIZE_SMALL = Symbol('small');
export const SIZE_MEDIUM = Symbol('medium');
export const SIZE_LARGE = Symbol('large');

export const FILL_SOLID = Symbol('solid');
export const FILL_LINK = Symbol('link');

export const COLOR_PRIMARY = Symbol('primary');
export const COLOR_ATTENTION = Symbol('attention');
export const COLOR_SECONDARY = Symbol('secondary');
export const COLOR_SUBTLE = Symbol('subtle');

export const STATE_ACTIVE = Symbol('active');
export const STATE_DISABLED = Symbol('disabled');

export const BUTTON_COLORS = {
  [COLOR_PRIMARY]: { class: 'color-primary' },
  [COLOR_ATTENTION]: { class: 'color-attention' },
  [COLOR_SECONDARY]: { class: 'color-secondary' },
  [COLOR_SUBTLE]: { class: 'color-subtle' },
};

export const BUTTON_THEMES = {
  [THEME_DARK]: { class: 'theme-dark' },
  [THEME_LIGHT]: { class: 'theme-light' },
};

export const BUTTON_SIZES = {
  [SIZE_MEDIUM]: { class: 'size-medium' },
  [SIZE_LARGE]: { class: 'size-large' },
};

export const BUTTON_STATES = {
  [STATE_ACTIVE]: { class: 'active' },
  [STATE_DISABLED]: { class: 'disabled' },
};

export const BUTTON_FILL = {
  [FILL_SOLID]: { class: 'fill-solid' },
  [FILL_LINK]: { class: 'fill-link' },
};

export const LINK_SIZES = {
  [SIZE_SMALL]: { class: 'size-small' },
  [SIZE_MEDIUM]: { class: 'size-medium' },
  [SIZE_LARGE]: { class: 'size-large' },
};

