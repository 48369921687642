<script>
  export let dark = false;
</script>

<div class="feature-text {dark ? 'dark' : ''}"><slot /></div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .feature-text {
    padding-bottom: 12px;
    padding-left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $N80;
    white-space: normal;
    &.dark {
      color: $N15;
    }
  }
</style>
