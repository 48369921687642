<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import ImageFrame from "../../../elements/ImageFrame.svelte";
</script>

<PageSection shaded>
  <div class="tokenization-hero">
    <div class="columns top">
      <div class="column left">
        <Eyebrow>Tokenization</Eyebrow>
        <Heading2>Create digital assets in 3 simple steps</Heading2>
        <Body>
          Our platform is a ‘one-stop shop’ to allow for the creation of digital
          assets through the formation of smart contracts, and the tokenization
          of the associated financial and legal instruments.
        </Body>
      </div>
      <div class="column right image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/3-steps.webp"
          alt="smart contracts"
        />
      </div>
    </div>
    <div class="columns bottom icon-row">
      <div class="icon-column">
        <IconColumn icon="certificate-of-honor" heading="Equity">
          Ordinary/Common and Preferred Shares/Stock, Units in a Trust, General
          and Limited Partnership Interests, LLC Units, and more.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="checkbook" heading="Debt">
          Fixed and Floating-Rate Notes/Bonds, Promissory Notes, Time/Demand
          Deposit Certificates, Commercial Paper, and more.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="contract" heading="Derivative / Hybrid">
          Futures, Forwards, Options, Warrants, Swaps, Convertible Debt, and
          more.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="feature" heading="Utility / Non-regulated">
          Loyalty/Reward Points, Coupons, Tickets, Licenses, Leases, Property
          Titles, Carbon Credits, and more.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .tokenization-hero {
    @extend .generic-section;
  }
</style>
