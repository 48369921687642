<script>
  export let dark = false;
</script>

<div class="box-text {dark ? 'dark' : ''}"><slot /></div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .box-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $N75;

    &.dark {
      color: $N20;
    }
  }
</style>
