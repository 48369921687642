<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M56.9994 25.9993C56.9994 40.3588 45.3595 51.9995 30.9993 51.9995C16.6407 51.9995 5 40.3588 5 25.9993C5 11.6407 16.6407 0 30.9993 0C45.3595 0 56.9994 11.6407 56.9994 25.9993Z"
    fill="#EEF0F2"
  />
  <path
    d="M15.9994 23.9998C11.8317 23.9998 5 25.9993 5 30.9995V41.9997C5 44.3134 7.40674 46.9991 9.99947 46.9991V63.9993H21.9994V46.9991C24.5456 46.9991 26.9996 44.2902 26.9996 41.9997V29.9465C26.9996 26.0173 20.1687 23.999 15.9994 23.999"
    fill="#B2D8FA"
  />
  <path
    d="M45.9992 24C41.8315 24 34.9998 26.0175 34.9998 29.9467V41.9992C34.9998 44.2904 37.4545 46.9994 39.9992 46.9994V63.9995H51.9991V46.9994C54.5934 46.9994 56.9994 44.3136 56.9994 41.9999V30.9997C56.9994 25.9995 50.1684 24 45.9992 24Z"
    fill="white"
  />
  <path
    d="M9.99947 46.9994C7.40674 46.9994 5 44.3136 5 41.9999V30.9997C5 25.9995 11.8317 24 15.9994 24C20.1687 24 26.9996 26.0175 26.9996 29.9467V30.9997M9.99947 31.9995V63.9995M15.9994 44.9999V63.9995M21.9994 63.9995V46.9994C24.5456 46.9994 26.9996 44.2904 26.9996 41.9999V37.9994H45.9993"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M51.9993 46.9994C54.5935 46.9994 56.9995 44.3136 56.9995 41.9999V30.9997C56.9995 25.9995 50.1686 24 45.9993 24C41.8316 24 34.9999 26.0175 34.9999 29.9467V33.9997H15.9995M51.9993 31.9995V63.9995M45.9993 44.9999V63.9995M39.9994 63.9995V46.9994C37.4546 46.9994 34.9999 44.2904 34.9999 41.9999V40.9994"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M41.9996 41.9999L45.9993 37.9995L41.9996 33.9997M20 37.9995L15.9995 33.9997L20 30"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M15.9995 20.9999C18.914 20.9999 20.9997 19.0394 20.9997 16.2V13.8C20.9997 10.9597 18.914 9 15.9995 9C13.0865 9 11 10.9597 11 13.8V16.2C11 19.0394 13.0865 20.9999 15.9995 20.9999Z"
    fill="white"
  />
  <path
    d="M15.9995 20.9999C18.914 20.9999 20.9997 19.0394 20.9997 16.2V13.8C20.9997 10.9597 18.914 9 15.9995 9C13.0865 9 11 10.9597 11 13.8V16.2C11 19.0394 13.0865 20.9999 15.9995 20.9999Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M45.9992 20.9999C48.9137 20.9999 50.9994 19.0394 50.9994 16.2V13.8C50.9994 10.9597 48.9137 9 45.9992 9C43.0862 9 40.9998 10.9597 40.9998 13.8V16.2C40.9998 19.0394 43.0862 20.9999 45.9992 20.9999Z"
    fill="white"
  />
  <path
    d="M45.9992 20.9999C48.9137 20.9999 50.9994 19.0394 50.9994 16.2V13.8C50.9994 10.9597 48.9137 9 45.9992 9C43.0862 9 40.9998 10.9597 40.9998 13.8V16.2C40.9998 19.0394 43.0862 20.9999 45.9992 20.9999Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
