<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M53.9952 30.5028C53.9952 43.2055 43.6977 53.5029 30.9958 53.5029C18.2939 53.5029 7.99573 43.2055 7.99573 30.5028C7.99573 17.8009 18.2932 7.50269 30.9958 7.50269C43.6977 7.50269 53.9952 17.8009 53.9952 30.5028Z"
    fill="#EEF0F2"
  />
  <path
    d="M7.651 46.6581C7.651 46.6581 8.82025 39.8317 12.4262 36.228L41.0918 7.58261C42.8745 5.80062 45.5445 5.55239 47.166 7.17388C48.7868 8.79387 48.5378 11.4623 46.755 13.2443C46.755 13.2443 21.6947 38.2852 18.0887 41.8897C14.4827 45.4927 7.651 46.6581 7.651 46.6581Z"
    fill="white"
  />
  <path
    d="M36.3268 30.5781L49.8815 17.0242"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M45.7117 21.194L42.2565 17.7395"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M18.2656 41.713L12.6031 36.0505L36.594 12.0769L42.2565 17.7394L18.2656 41.713Z"
    fill="#B2D8FA"
  />
  <path
    d="M42.2565 17.7394L36.594 12.0769M18.2656 41.713L12.6031 36.0505L18.2656 41.713Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M57.4955 53.2089C55.3077 51.7592 52.9715 50.4542 50.6143 50.722C46.3078 51.2095 42.4678 59.6822 39.6688 58.5392C36.8706 57.3962 42.3928 49.1058 39.9658 48.1383C37.5374 47.17 31.7496 57.7359 29.4149 56.2862C27.0787 54.8357 30.2414 48.9003 28.2554 48.2103C26.2687 47.5218 23.6504 54.3602 21.9487 54.6602C20.2462 54.961 20.0715 51.6167 17.9925 51.394C15.9127 51.1697 10.647 55.5332 6.90002 58.5197"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M7.651 46.6581C7.651 46.6581 8.82025 39.8317 12.4262 36.228L41.0918 7.58261C42.8745 5.80062 45.5445 5.55239 47.166 7.17388C48.7868 8.79387 48.5378 11.4623 46.755 13.2443C46.755 13.2443 21.6947 38.2852 18.0887 41.8897C14.4827 45.4927 7.651 46.6581 7.651 46.6581Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
