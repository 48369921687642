<script>
  export let width = 64;
  export let height = 65;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M58.0018 28.538C58.0018 43.4511 45.914 55.5404 31.0017 55.5404C16.0878 55.5404 4 43.4511 4 28.538C4 13.6264 16.0893 1.53711 31.0009 1.53711C45.914 1.53711 58.0026 13.6264 58.0026 28.538"
    fill="#EEF0F2"
  />
  <path
    d="M60.0025 18.5403C60.0025 19.8665 59.4757 21.1383 58.5379 22.0761C57.6002 23.0138 56.3284 23.5406 55.0022 23.5406C53.6761 23.5406 52.4042 23.0138 51.4665 22.0761C50.5288 21.1383 50.002 19.8665 50.002 18.5403C50.002 17.2142 50.5288 15.9423 51.4665 15.0046C52.4042 14.0669 53.6761 13.54 55.0022 13.54C56.3284 13.54 57.6002 14.0669 58.5379 15.0046C59.4757 15.9423 60.0025 17.2142 60.0025 18.5403Z"
    fill="white"
  />
  <path
    d="M60.0025 18.5403C60.0025 19.8665 59.4757 21.1383 58.5379 22.0761C57.6002 23.0138 56.3284 23.5406 55.0022 23.5406C53.6761 23.5406 52.4042 23.0138 51.4665 22.0761C50.5288 21.1383 50.002 19.8665 50.002 18.5403C50.002 17.2142 50.5288 15.9423 51.4665 15.0046C52.4042 14.0669 53.6761 13.54 55.0022 13.54C56.3284 13.54 57.6002 14.0669 58.5379 15.0046C59.4757 15.9423 60.0025 17.2142 60.0025 18.5403Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M60.0025 35.5394C60.0025 36.8655 59.4757 38.1374 58.5379 39.0751C57.6002 40.0128 56.3284 40.5396 55.0022 40.5396C53.6761 40.5396 52.4042 40.0128 51.4665 39.0751C50.5288 38.1374 50.002 36.8655 50.002 35.5394C50.002 34.2132 50.5288 32.9414 51.4665 32.0036C52.4042 31.0659 53.6761 30.5391 55.0022 30.5391C56.3284 30.5391 57.6002 31.0659 58.5379 32.0036C59.4757 32.9414 60.0025 34.2132 60.0025 35.5394Z"
    fill="white"
  />
  <path
    d="M60.0025 35.5394C60.0025 36.8655 59.4757 38.1374 58.5379 39.0751C57.6002 40.0128 56.3284 40.5396 55.0022 40.5396C53.6761 40.5396 52.4042 40.0128 51.4665 39.0751C50.5288 38.1374 50.002 36.8655 50.002 35.5394C50.002 34.2132 50.5288 32.9414 51.4665 32.0036C52.4042 31.0659 53.6761 30.5391 55.0022 30.5391C56.3284 30.5391 57.6002 31.0659 58.5379 32.0036C59.4757 32.9414 60.0025 34.2132 60.0025 35.5394Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M60.0025 52.5403C60.0025 53.8665 59.4757 55.1383 58.5379 56.076C57.6002 57.0137 56.3284 57.5406 55.0022 57.5406C53.6761 57.5406 52.4042 57.0137 51.4665 56.076C50.5288 55.1383 50.002 53.8665 50.002 52.5403C50.002 51.2141 50.5288 49.9423 51.4665 49.0046C52.4042 48.0668 53.6761 47.54 55.0022 47.54C56.3284 47.54 57.6002 48.0668 58.5379 49.0046C59.4757 49.9423 60.0025 51.2141 60.0025 52.5403Z"
    fill="white"
  />
  <path
    d="M50.0028 35.54H37.0029M60.0026 52.5404C60.0026 53.8665 59.4758 55.1383 58.5381 56.0761C57.6003 57.0138 56.3285 57.5406 55.0023 57.5406C53.6762 57.5406 52.4043 57.0138 51.4666 56.0761C50.5288 55.1383 50.002 53.8665 50.002 52.5404C50.002 51.2142 50.5288 49.9424 51.4666 49.0046C52.4043 48.0669 53.6762 47.5401 55.0023 47.5401C56.3285 47.5401 57.6003 48.0669 58.5381 49.0046C59.4758 49.9424 60.0026 51.2142 60.0026 52.5404V52.5404Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M50.0027 52.541H44.0027C43.8714 52.541 43.7414 52.5151 43.6201 52.4649C43.4988 52.4146 43.3886 52.341 43.2958 52.2481C43.2029 52.1553 43.1293 52.0451 43.079 51.9238C43.0288 51.8025 43.0029 51.6725 43.0029 51.5412V19.5408C43.0029 18.988 43.4507 18.541 44.0027 18.541H50.0027"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M23.0023 21.5398C19.2575 21.5398 12.002 23.5828 12.002 27.5399V39.6217C12.002 41.9519 13.6722 43.5397 16.0025 43.5397H17.0022V62.5396H29.0023V43.5397H30.0021C32.3331 43.5397 34.0026 41.9519 34.0026 39.6217V27.5391C34.0026 23.5828 26.7485 21.5391 23.0023 21.5391"
    fill="#B2D8FA"
  />
  <path
    d="M17.0022 43.5409H16.0025C13.6722 43.5409 12.002 41.8706 12.002 39.5411V27.541C12.002 23.584 19.2575 21.541 23.0023 21.541C26.7485 21.541 34.0026 23.584 34.0026 27.541V39.5411C34.0026 41.8706 32.3331 43.5409 30.0021 43.5409H29.0023"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M23.0022 18.5276C25.7945 18.5276 28.0025 16.2431 28.0025 13.4261V11.7236C28.0025 8.90584 25.7945 6.62207 23.0022 6.62207C20.21 6.62207 18.002 8.90584 18.002 11.7244V13.4261C18.002 16.2431 20.21 18.5269 23.0022 18.5269"
    fill="white"
  />
  <path
    d="M24.0027 42.5405V62.5402M23.003 18.5276C25.7952 18.5276 28.0032 16.2431 28.0032 13.4261V11.7236C28.0032 8.90584 25.7952 6.62207 23.003 6.62207C20.2107 6.62207 18.0027 8.90584 18.0027 11.7244V13.4261C18.0027 16.2431 20.2107 18.5269 23.003 18.5269V18.5276ZM29.003 62.5402V29.54V62.5402ZM17.0029 29.54V62.5402V29.54Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path d="M20.0023 53.5406H6.00195V42.541H20.0023V53.5406Z" fill="white" />
  <path
    d="M20.0023 53.5406H6.00195V42.541H20.0023V53.5406Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
