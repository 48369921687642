<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection>
  <div class="atomic-swaps">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Atomic Swaps</Eyebrow>
        <Heading3>The next evolution of Delivery versus Payment</Heading3>
        <Body>
          The Tokenized platform enables digital assets to be traded using
          atomic swaps. Atomic swaps are trades that are executed and settled on
          a distributed ledger by smart contracts, where transfer requests
          either fully execute on every term at the exact same time, and for all
          transacting parties, or the transfer is wholly rejected.
          <br /><br />This method removes the need for a central clearing
          counterparty, and the associated costs, while still completely
          eliminating settlement risk from the trade.
          <br /><br />The protocol that our platform leverages allows for
          extremely flexible and customizable atomic swaps that enable any
          number of transacting parties to trade any number of digital assets,
          and in any quantity.
          <br /><br />The method also supports all types of digital assets,
          including utility and security tokens, and helps to improve reporting
          and insights.
        </Body>
      </div>
      <div class="column left image">
        <ImageFrame
          width={560}
          src="/images/new-trade-offer.webp"
        />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .atomic-swaps {
    @extend .generic-section;

    .columns {
      flex-direction: row-reverse;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }
  }
</style>
