<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
</script>

<PageSection>
  <div class="real-time-insights">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Real-time insights</Eyebrow>
        <Heading3>Keep everyone in sync</Heading3>
        <Body>
          Our platform enables a digital transformation of many important
          records to keep everyone in sync with important information, in
          real-time.
        </Body>
      </div>
      <div class="column left image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/in-sync.webp"
          alt="Keep everyone in sync"
        />
      </div>
    </div>
    <div class="icon-row">
      <div class="icon-column">
        <IconColumn icon="duties" heading="Interest / coupon schedules">
          Know how much you owe, or are owed, and when the payment is due.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="idea" heading="License / lease / royalty fees">
          Keep track of fee schedules and histories, even with large numbers of
          licenses or leases.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="taxes" heading="Late penalties">
          If payments are late, automatically calculate penalty fees.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="inspiration" heading="Contract amendments">
          Stay on top of all the important details of your contracts.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .real-time-insights {
    @extend .generic-section;

    .columns {
      flex-direction: row-reverse;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }
  }
</style>
