<script>
  import Icon from "components/elements/Icon";

  export let value;
  export let options = [];
  export let change = () => {};
  export let className = "";
  export let chevronIcon = "chevron-down";
</script>

<div class="select-wrapper">
  <div class={`dropdown ${className}`}>
    {#each options as option}
      {#if option === value || (option.value !== undefined && option.value === value)}
        {option.label}
      {/if}
    {/each}
    <Icon name={chevronIcon} ref="icon-svg" />
  </div>
  <select class={`dropdown ${className}`} bind:value>
    {#each options as option}
      <option value={option.value !== undefined ? option.value : option}>
        {option.label}
      </option>
    {/each}
  </select>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .select-wrapper {
    position: relative;
    height: 36px;
  }
  .dropdown {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
    border-radius: 4px;
    height: 36px;
    font-size: 15px;
    border-color: $N20;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: $N85;
    cursor: pointer;
    z-index: 0;

    :global([ref="icon-svg"] > path) {
      stroke: $N45;
    }
  }
  select.dropdown {
    opacity: 0;
    position: absolute;
    z-index: 1;
  }
</style>
