<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4679_20865)">
    <path
      d="M53.9998 27.0002C53.9998 41.359 42.3597 53.0005 27.9995 53.0005C13.6407 53.0005 2 41.359 2 27.0002C2 12.6415 13.6407 1 27.9995 1C42.3597 1 53.9998 12.6415 53.9998 27.0002Z"
      fill="#EEF0F2"
    />
    <path
      d="M56 8.00049H24.9995C24.474 7.9997 23.9536 8.10262 23.4679 8.30335C22.9823 8.50408 22.5411 8.79867 22.1695 9.17024C21.7979 9.54181 21.5033 9.98303 21.3026 10.4687C21.1019 10.9543 20.999 11.4748 20.9998 12.0002V29.0005H8.99976C8.47428 28.9997 7.95381 29.1026 7.46819 29.3033C6.98256 29.5041 6.54131 29.7987 6.16974 30.1702C5.79817 30.5418 5.50359 30.983 5.30286 31.4686C5.10213 31.9543 4.99921 32.4748 5 33.0002V52H12.9995V58C12.9986 58.5256 13.1015 59.0462 13.3022 59.5319C13.5029 60.0177 13.7976 60.4591 14.1692 60.8307C14.5409 61.2024 14.9823 61.497 15.468 61.6978C15.9538 61.8985 16.4744 62.0013 17 62.0005H50.9998C51.5253 62.0012 52.0458 61.8983 52.5315 61.6975C53.0172 61.4968 53.4585 61.2021 53.83 60.8305C54.2016 60.4588 54.4962 60.0175 54.6969 59.5318C54.8975 59.046 55.0004 58.5255 54.9995 58V15.0002H59.9998V12.0002C60.0005 11.4748 59.8976 10.9543 59.6969 10.4687C59.4962 9.98303 59.2016 9.54181 58.83 9.17024C58.4584 8.79867 58.0172 8.50408 57.5316 8.30335C57.0459 8.10262 56.5255 7.9997 56 8.00049Z"
      fill="white"
    />
    <path
      d="M24.9995 8.00049C24.474 7.9997 23.9536 8.10262 23.4679 8.30335C22.9823 8.50408 22.5411 8.79867 22.1695 9.17024C21.7979 9.54181 21.5033 9.98303 21.3026 10.4687C21.1019 10.9543 20.999 11.4748 20.9998 12.0002V58C21.0006 58.5255 20.8978 59.046 20.6971 59.5318C20.4964 60.0175 20.2019 60.4588 19.8303 60.8305C19.4587 61.2021 19.0174 61.4968 18.5318 61.6975C18.0461 61.8983 17.5255 62.0012 17 62.0005C16.4744 62.0013 15.9538 61.8985 15.468 61.6978C14.9823 61.497 14.5409 61.2024 14.1692 60.8307C13.7976 60.4591 13.5029 60.0177 13.3022 59.5319C13.1015 59.0462 12.9986 58.5256 12.9995 58V33.0002C13.0003 32.4748 12.8974 31.9543 12.6966 31.4686C12.4959 30.983 12.2013 30.5418 11.8298 30.1702C11.4582 29.7987 11.017 29.5041 10.5313 29.3033C10.0457 29.1026 9.52524 28.9997 8.99976 29.0005C8.47428 28.9997 7.95381 29.1026 7.46819 29.3033C6.98256 29.5041 6.54131 29.7987 6.16974 30.1702C5.79817 30.5418 5.50359 30.983 5.30286 31.4686C5.10213 31.9543 4.99921 32.4748 5 33.0002V52H9.99951"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.9995 8.00049C26.0625 8.00049 27.082 8.42276 27.8336 9.1744C28.5852 9.92604 29.0075 10.9455 29.0075 12.0085L29 15.0002H59.9998V12.0002C60.0006 11.4748 59.8977 10.9543 59.6969 10.4687C59.4962 9.98302 59.2016 9.5418 58.83 9.17023C58.4585 8.79867 58.0172 8.50407 57.5316 8.30335C57.046 8.10262 56.5255 7.9997 56 8.00049H24.9995Z"
      fill="#B2D8FA"
    />
    <path
      d="M24.9995 8.00049C26.0625 8.00049 27.082 8.42276 27.8336 9.1744C28.5852 9.92604 29.0075 10.9455 29.0075 12.0085L29 15.0002H59.9998V12.0002C60.0006 11.4748 59.8977 10.9543 59.6969 10.4687C59.4962 9.98302 59.2016 9.5418 58.83 9.17023C58.4585 8.79867 58.0172 8.50407 57.5316 8.30335C57.046 8.10262 56.5255 7.9997 56 8.00049H24.9995"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 29.0005H18M17.0002 62.0005H51C51.5256 62.0013 52.0461 61.8983 52.5318 61.6976C53.0174 61.4968 53.4587 61.2021 53.8303 60.8305C54.2019 60.4588 54.4964 60.0175 54.6971 59.5318C54.8978 59.0461 55.0007 58.5255 54.9998 58V43"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M34.3836 50.8346C32.3856 47.5773 32.9826 41.6501 33.6936 39.4924L35.6789 39.0048C35.6789 39.0048 35.1719 36.0004 37.4466 33.0874C40.1811 29.5886 47.9857 22.6316 62.9999 21.8081C57.5137 27.9274 53.5979 38.3501 52.6192 39.8853C50.5769 41.2728 48.5879 42.2134 48.5879 42.2134L50.4029 43.9803C50.4029 43.9803 40.4999 52.5011 34.3836 50.8346Z"
      fill="#B2D8FA"
    />
    <path
      d="M34.3836 50.8346C32.3856 47.5773 32.9826 41.6501 33.6936 39.4924L35.6789 39.0048C35.6789 39.0048 35.1719 36.0004 37.4466 33.0874C40.1811 29.5886 47.9857 22.6316 62.9999 21.8081C57.5137 27.9274 53.5979 38.3501 52.6192 39.8853C50.5769 41.2728 48.5879 42.2134 48.5879 42.2134L50.4029 43.9803C50.4029 43.9803 40.4999 52.5011 34.3836 50.8346V50.8346Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M51.3237 30.4595C39.8757 39.1407 33.9417 50.1335 31.5762 57.3492M54.9994 19.0002V15.0005"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_4679_20865">
      <rect {width} {height} fill="white" />
    </clipPath>
  </defs>
</svg>
