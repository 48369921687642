<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";

  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection shaded>
  <div class="treasury-management">
    <div class="columns">
      <div class="column left">
        <Eyebrow>TREASURY MANAGEMENT</Eyebrow>
        <Heading3>Securely control digital assets</Heading3>
        <Body>
          Manage all of your digital assets in a safe and secure manner,
          regardless of the value of the assets and the size of the
          transactions.
          <br /><br />Use different roles, permissions, and authorization
          schemes to easily manage your digital assets in a collaborative and
          highly secure way. No device, including our platform servers, ever has
          visibility of any user’s private keys.
          <br /><br />Our platform is enterprise-grade and leverages
          state-of-the-art multi-party authorization schemes to enable the
          best-in-class security.
          <br /><br />If a user’s keys are lost, the issuer of a contract can
          freeze, unfreeze, and confiscate tokens to allow users to get their
          assets back.
        </Body>
      </div>
      <div class="column right">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/treasury-management.webp"
        />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .treasury-management {
    @extend .generic-section;

    .column {
      :global(.image-frame) {
        margin-bottom: -48px;
      }
    }
  }
</style>
