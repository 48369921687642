<script>
  import Link from "components/elements/Link.svelte";
  import { COLOR_PRIMARY } from "buttonStyles";
  import Heading6 from "components/elements/aliases/Heading6.svelte";
  import Icon from "components/elements/Icon";

  export let parsedTOC = {};
  export let activeSubPage;
  export let mobile = false;
  export let navigateToPage;
  export let baseDocumentUrl;
  export let documentVersion;

  export let menuTitle = "";

  let isExpanded = false;
  let menuVisible;

  const onExpandMenu = () => (isExpanded = !isExpanded);

  $: menuVisible = !!Object.keys(parsedTOC).length && (isExpanded || !mobile);
</script>

<div class="document-menu" class:mobile>
  {#if mobile}
    <div class="mobile-toggle" on:click={onExpandMenu}>
      <div class="menu-title">
        <div>{menuTitle}</div>
        <div class="menu-version">{documentVersion}</div>
      </div>
      {#if !isExpanded}
        <Icon name="chevron-down" colorName="N90" />
      {:else}
        <Icon name="chevron-up" colorName="N90" width="16px" height="16px" />
      {/if}
    </div>
  {:else}
    <div class="menu-title">
      <div>{menuTitle}</div>
      <div class="menu-version">{documentVersion}</div>
    </div>
  {/if}
  {#if menuVisible}
    <div class="menu-items">
      {#each Object.entries(parsedTOC) as [header, data]}
        {#each Object.entries(data.pages) as [pageLink, label]}
          <Link
            scrollTop
            color={COLOR_PRIMARY}
            href={`${baseDocumentUrl}${pageLink}`}
            class={`toc-link ${pageLink === activeSubPage ? "active" : ""}`}
            onClick={() => navigateToPage(pageLink)}
          >
            {label}
          </Link>
        {/each}
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .document-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 16px;
    width: 100%;

    .menu-items {
      margin: 8px 0 16px;
      width: 100%;

      :global(.heading) {
        margin-top: 16px;
        padding-bottom: 8px;
      }
    }

    &.mobile {
      .menu-title {
        margin-bottom: 0px;
      }
      > div {
        padding-left: 0px;
      }
      .menu-items {
        margin-top: 0;
        display: none;
      }
      border: solid 1px $N15;
      border-radius: 4px;
    }
    .mobile-toggle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      width: 100%;
      background-color: transparent;
      cursor: pointer;
    }
    .menu-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      min-width: fit-content;
      > div {
        color: $N90;
        font-size: 16px;
        font-weight: 700;
        padding-left: 16px;
        white-space: nowrap;
      }
      .menu-version {
        margin-left: 12px;
        font-weight: 400;
        color: $N60;
        padding: 0px;
      }
    }

    :global(.toc-link) {
      width: 100%;
      padding: 4px 0 4px 24px;
      margin: 0 8px;
      color: $N75;
      text-decoration: none;

      &:hover {
        color: $N90;
      }
    }

    :global(.toc-link:hover),
    :global(.toc-link.active) {
      position: relative;
      border-radius: 6px;
      background-color: $N05;
    }

    :global(.toc-link.active) {
      color: $B65;
      &:before {
        content: "";
        position: absolute;
        display: inline-block;
        background-color: $B65;
        width: 2px;
        height: 24px;
        border-radius: 1px;
        left: -8px;
        top: 4px;
      }
    }

    @media only screen and (max-width: $size-tablet-max) {
      &.mobile {
        .menu-items {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
</style>
