<script>
  export let width = 64;
  export let height = 65;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M59.5817 35.0361C59.5817 49.6706 47.7177 61.5352 33.0827 61.5352C18.4478 61.5352 6.58374 49.6716 6.58374 35.0361C6.58374 20.4017 18.4478 8.53711 33.0827 8.53711C47.7177 8.53711 59.5817 20.4007 59.5817 35.0361Z"
    fill="#EEF0F2"
  />
  <path
    d="M28.0829 27.0363L12.5835 42.5357L26.306 56.7832L33.1776 49.9115C34.7686 48.3205 38.0091 50.7364 40.2481 48.4975C42.4875 46.2586 42.5687 46.1766 42.5687 46.1766L44.8964 43.8497L28.0829 27.0363Z"
    fill="white"
  />
  <path
    d="M28.0829 27.0363L12.5835 42.5357L26.306 56.7832L33.1776 49.9115C34.7686 48.3205 38.0091 50.7364 40.2481 48.4975C42.4875 46.2586 42.5687 46.1766 42.5687 46.1766L44.8964 43.8497L28.0829 27.0363Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M23.5156 14.3953C24.5671 11.2605 23.8539 7.66359 21.3566 5.16568C18.5869 2.39679 14.4648 1.81881 11.1203 3.41475L16.4068 8.70155L12.1645 12.9434L6.87759 7.65659C5.28225 11.0015 5.85963 15.1233 8.62903 17.8932C11.1262 20.3911 14.7233 21.1041 17.8588 20.0521L50.5822 52.7299C52.1442 54.2919 54.6765 54.2919 56.2385 52.7299C57.8007 51.168 57.8007 48.6351 56.2389 47.0731L23.5156 14.3953Z"
    fill="#B2D8FA"
  />
  <path
    d="M23.5156 14.3953C24.5671 11.2605 23.8539 7.66359 21.3566 5.16568C18.5869 2.39679 14.4648 1.81881 11.1203 3.41475L16.4068 8.70155L12.1645 12.9434L6.87759 7.65659C5.28225 11.0015 5.85963 15.1233 8.62903 17.8932C11.1262 20.3911 14.7233 21.1041 17.8588 20.0521L50.5822 52.7299C52.1442 54.2919 54.6765 54.2919 56.2385 52.7299C57.8007 51.168 57.8007 48.6351 56.2389 47.0731L23.5156 14.3953Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M38.3264 19.3072L36.2054 17.1863C35.4241 16.4053 34.1581 16.4053 33.3769 17.1863L29.1345 21.4291L34.0839 26.3779L38.3264 22.1361C39.1075 21.3551 39.1075 20.0882 38.3264 19.3072Z"
    fill="white"
  />
  <path
    d="M29.1345 21.4291L33.3769 17.1863C34.1581 16.4053 35.4241 16.4053 36.2054 17.1863L38.3264 19.3072C39.1075 20.0882 39.1075 21.3551 38.3264 22.1361L34.0839 26.3779"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M44.6902 22.8435L42.5688 20.7225C41.788 19.9416 40.5216 19.9416 39.7408 20.7225L34.084 26.3783L39.0338 31.3281L44.6902 25.6713C45.4715 24.8904 45.4715 23.6244 44.6902 22.8435Z"
    fill="white"
  />
  <path
    d="M39.0338 31.3281L44.6902 25.6713C45.4715 24.8904 45.4715 23.6244 44.6902 22.8435L42.5688 20.7225C41.788 19.9416 40.5216 19.9416 39.7408 20.7225L34.084 26.3783"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M49.7486 30.5124C50.4692 29.7914 50.4208 28.5745 49.6396 27.7935L47.5186 25.6716C46.7374 24.8906 45.5198 24.8426 44.7988 25.5636L38.9248 31.4374C38.2037 32.1583 38.2525 33.3763 39.0334 34.1573L41.1548 36.2782C41.9356 37.0591 43.1531 37.1081 43.8742 36.3872L49.7486 30.5124Z"
    fill="white"
  />
  <path
    d="M49.7486 30.5124C50.4692 29.7914 50.4208 28.5745 49.6396 27.7935L47.5186 25.6716C46.7374 24.8906 45.5198 24.8426 44.7988 25.5636L38.9248 31.4374C38.2037 32.1583 38.2525 33.3763 39.0334 34.1573L41.1548 36.2782C41.9356 37.0591 43.1531 37.1081 43.8742 36.3872L49.7486 30.5124Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M32.2931 20.3455C31.063 19.1155 29.7932 18.4475 27.8444 18.7425C25.896 19.0375 22.7728 19.3545 21.6813 20.1565C20.5899 20.9595 19.6264 24.9383 19.6264 28.0022C19.6264 31.717 19.485 35.3209 18.7061 36.0999L12.1646 42.6416L29.0646 41.1907C33.0493 37.2058 32.445 30.3961 29.2064 27.1582C29.8103 27.7622 32.1184 30.0701 33.693 31.644C36.9011 34.8529 41.6817 32.562 42.4622 30.5141C41.4549 29.5071 32.2931 20.3455 32.2931 20.3455Z"
    fill="white"
  />
  <path
    d="M12.1646 42.6416L18.7061 36.0999C19.485 35.3209 19.6264 31.717 19.6264 28.0022C19.6264 24.9383 20.5899 20.9595 21.6813 20.1565C22.7728 19.3545 25.896 19.0375 27.8444 18.7425C29.7932 18.4475 31.063 19.1155 32.2931 20.3455L42.4622 30.5141C41.6817 32.562 36.9011 34.8529 33.693 31.644C32.1184 30.0701 29.8103 27.7622 29.2064 27.1582C32.445 30.3961 33.0493 37.2058 29.0646 41.1907"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M52.7009 36.1983C53.3618 35.5373 53.3548 34.4583 52.6852 33.7893L50.8674 31.9714C50.1978 31.3014 49.1194 31.2944 48.4585 31.9554L43.0735 37.3402C42.413 38.0012 42.4196 39.0792 43.0891 39.7491L44.9074 41.5671C45.5769 42.237 46.6553 42.2441 47.3163 41.5831L52.7009 36.1983Z"
    fill="white"
  />
  <path
    d="M52.7009 36.1983C53.3618 35.5373 53.3548 34.4583 52.6852 33.7893L50.8674 31.9714C50.1978 31.3014 49.1194 31.2944 48.4585 31.9554L43.0735 37.3402C42.413 38.0012 42.4196 39.0792 43.0891 39.7491L44.9074 41.5671C45.5769 42.237 46.6553 42.2441 47.3163 41.5831L52.7009 36.1983Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M24.673 61.9766L6.67407 43.9862L13.0375 37.6195L31.0365 55.6098L24.673 61.9766Z"
    fill="#EEF0F2"
  />
  <path
    d="M12.5837 43.0733L15.5835 46.0732M24.673 61.9766L6.67407 43.9862L13.0375 37.6195L31.0365 55.6098L24.673 61.9766ZM51.5822 48.0731L53.5821 50.073L51.5822 48.0731Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
