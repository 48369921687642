<script>
  import Icon from "components/elements/Icon";

  export let icon = "";
  export let colorName = "B40";
</script>

<div class="box-icon">
  <Icon name={icon} {colorName} />
</div>
