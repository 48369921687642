<script>
  export let width = 64;
  export let height = 65;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4679_2573)">
    <path
      d="M31.9998 60.5368C17.0877 60.5368 5 48.4491 5 33.537C5 18.6255 17.0884 6.53711 31.9998 6.53711C46.912 6.53711 58.9997 18.627 58.9997 33.537C58.9997 48.4491 46.9113 60.5368 31.9998 60.5368Z"
      fill="#EEF0F2"
    />
    <path
      d="M33.9999 57.5367V53.537C33.9999 53.537 37.9996 51.1145 37.9996 46.5372V28.5373L23.6275 26.5371L17.2232 31.7593C15.8103 33.0568 14.9995 34.9243 14.9995 36.8893V46.3258L15.001 57.5367H33.9999Z"
      fill="white"
    />
    <path
      d="M33.9999 57.5367V53.537C33.9999 53.537 37.9996 51.1145 37.9996 46.5372V28.5373L23.6275 26.5371L17.2232 31.7593C15.8103 33.0568 14.9995 34.9243 14.9995 36.8893V46.3258L15.001 57.5367H33.9999Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M23 1.53711V40.5369H28.0002V44.5367H49.0001V1.53711H23Z"
      fill="white"
    />
    <path
      d="M23 1.53711V40.5369H28.0002V44.5366H49.0001V1.53711H23Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M43.9997 36.5369V9.53709C43.204 9.53709 42.441 9.22103 41.8784 8.65842C41.3157 8.09581 40.9997 7.33275 40.9997 6.53711H31C31 7.33275 30.6839 8.09581 30.1213 8.65842C29.5587 9.22103 28.7956 9.53709 28 9.53709V36.5369C28.7956 36.5369 29.5587 36.853 30.1213 37.4156C30.6839 37.9782 31 38.7413 31 39.5369H40.9997C40.9997 38.7413 41.3157 37.9782 41.8784 37.4156C42.441 36.853 43.204 36.5369 43.9997 36.5369Z"
      fill="#B2D8FA"
    />
    <path
      d="M35.9995 12.5371V14.5373M43.9997 36.5369V9.53709C43.204 9.53709 42.441 9.22103 41.8784 8.65842C41.3157 8.09581 40.9997 7.33275 40.9997 6.53711H31C31 7.33275 30.6839 8.09581 30.1213 8.65842C29.5587 9.22103 28.7956 9.53709 28 9.53709V36.5369C28.7956 36.5369 29.5587 36.853 30.1213 37.4156C30.6839 37.9782 31 38.7413 31 39.5369H40.9997C40.9997 38.7413 41.3157 37.9782 41.8784 37.4156C42.441 36.853 43.204 36.5369 43.9997 36.5369Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M35.9996 18.5371C38.7626 18.5386 41.0021 20.3304 41.0006 22.5406C40.9991 24.7493 38.7581 26.5381 35.9951 26.5373C33.2321 26.5358 30.9926 24.7448 30.9941 22.5353C30.9956 20.3251 33.2366 18.5363 35.9996 18.5378"
      fill="white"
    />
    <path
      d="M35.9996 18.5371C38.7626 18.5386 41.0021 20.3304 41.0006 22.5406C40.9991 24.7493 38.7581 26.5381 35.9951 26.5373C33.2321 26.5358 30.9926 24.7448 30.9941 22.5353C30.9956 20.3251 33.2366 18.5363 35.9996 18.5378V18.5371Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M31.315 29.7827C28.9953 32.1024 19.9331 40.9636 19.9331 40.9636L27.9191 51.3961C27.9191 51.3961 31.294 47.3055 29.0516 42.2978C29.0516 42.2978 33.0888 37.7769 34.0773 36.4081C36.283 33.1374 33.6348 27.4622 31.315 29.7827Z"
      fill="white"
    />
    <path
      d="M19.9331 40.9636C19.9331 40.9636 28.9953 32.1024 31.315 29.7827C33.6355 27.4622 36.283 33.1374 34.0773 36.4081C33.0888 37.7769 29.0523 42.2978 29.0523 42.2978C31.2948 47.3055 27.9198 51.3961 27.9198 51.3961"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path d="M13 56.5371V63.5368H36.9999V56.5371H13Z" fill="#B2D8FA" />
    <path
      d="M16 60.5368H19M13 63.5368V56.5371H36.9999V63.5368H13Z"
      stroke="#1F4A89"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </g>
  <defs>
    <clipPath id="clip0_4679_2573">
      <rect
        width="64"
        height="64"
        fill="white"
        transform="translate(0 0.537109)"
      />
    </clipPath>
  </defs>
</svg>
