<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M49.4738 15.2499C60.105 25.8811 60.105 43.1176 49.4738 53.7496C38.8425 64.3809 21.6053 64.3809 10.974 53.7496C0.342 43.1191 0.342 25.8819 10.974 15.2499C21.6053 4.61788 38.8425 4.61788 49.4738 15.2499Z"
    fill="#24375B"
  />
  <path
    d="M15.9156 43.7193L6.72363 52.912M15.2091 38.77L11.6736 42.3048L15.2091 38.77Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M55.5143 36.6476L42.7868 49.3758M31.473 2.70703L22.2803 11.8998L31.473 2.70703Z"
    stroke="#7A95C1"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M8.84473 45.1338L10.2592 43.7192"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M14.5019 19.6782L13.0874 21.092M20.1584 14.021L18.7447 15.4355L20.1584 14.021ZM17.3302 16.8492L15.9157 18.263L17.3302 16.8492Z"
    stroke="#7A95C1"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M23.6938 45.8412L11.6736 33.8202L43.4931 2L61.8778 20.3855L30.0583 52.205L25.8156 47.9622"
    fill="white"
  />
  <path
    d="M23.6938 45.8412L11.6736 33.8202L43.4931 2L61.8778 20.3855L30.0583 52.205L25.8156 47.9623"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M52.686 18.2629L45.6142 11.1919C45.0516 11.7543 44.2887 12.0703 43.4932 12.0703C42.6977 12.0703 41.9348 11.7543 41.3722 11.1919L20.8657 31.6992C21.1443 31.9777 21.3653 32.3083 21.516 32.6723C21.6668 33.0362 21.7444 33.4262 21.7444 33.8202C21.7444 34.2141 21.6668 34.6041 21.516 34.968C21.3653 35.332 21.1443 35.6626 20.8657 35.9412L27.9367 43.0121C28.4994 42.4497 29.2625 42.1337 30.0581 42.1337C30.8537 42.1337 31.6168 42.4497 32.1795 43.0121L52.686 22.5064C52.4073 22.2278 52.1862 21.8971 52.0353 21.533C51.8845 21.1689 51.8069 20.7787 51.8069 20.3847C51.8069 19.9906 51.8845 19.6004 52.0353 19.2363C52.1862 18.8723 52.4073 18.5415 52.686 18.2629Z"
    fill="#B2D8FA"
  />
  <path
    d="M43.4932 20.3854L44.9077 18.9709M52.686 18.2629L45.6142 11.1919C45.0516 11.7543 44.2887 12.0703 43.4932 12.0703C42.6977 12.0703 41.9348 11.7543 41.3722 11.1919L20.8657 31.6992C21.1443 31.9777 21.3653 32.3083 21.516 32.6723C21.6668 33.0362 21.7444 33.4262 21.7444 33.8202C21.7444 34.2141 21.6668 34.6041 21.516 34.968C21.3653 35.332 21.1443 35.6626 20.8657 35.9412L27.9367 43.0121C28.4994 42.4497 29.2625 42.1337 30.0581 42.1337C30.8537 42.1337 31.6168 42.4497 32.1795 43.0121L52.686 22.5064C52.4073 22.2278 52.1862 21.8971 52.0353 21.533C51.8845 21.1689 51.8069 20.7787 51.8069 20.3847C51.8069 19.9906 51.8845 19.6004 52.0353 19.2363C52.1862 18.8723 52.4073 18.5415 52.686 18.2629V18.2629ZM28.644 35.2339L30.0585 33.8202L28.644 35.2339Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M33.5908 30.2808C31.6393 28.3278 31.4833 25.3202 33.2413 23.563C35.0001 21.8057 38.0083 21.9655 39.9591 23.9193C41.9106 25.8723 42.0666 28.8798 40.3078 30.637C38.5498 32.3943 35.5416 32.2345 33.5908 30.2808Z"
    fill="white"
  />
  <path
    d="M25.8156 43.7192L16.6228 52.912M33.5908 30.2808C31.6393 28.3278 31.4833 25.3202 33.2413 23.563C35.0001 21.8057 38.0083 21.9655 39.9591 23.9193C41.9106 25.8723 42.0666 28.8797 40.3078 30.637C38.5498 32.3942 35.5416 32.2345 33.5908 30.2808V30.2808Z"
    stroke="#496497"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
