<script>
  export let width = 19;
  export let height = 19;
  export let color = '#7a869c';
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.65753 0.787109C4.86847 0.787109 0.833313 4.63477 0.833313 9.38477C0.833313 9.53711 0.837219 18.2871 0.837219 18.2871L9.65753 18.2793C14.4505 18.2793 18.3333 14.2832 18.3333 9.5332C18.3333 4.7832 14.4505 0.787109 9.65753 0.787109ZM9.58331 14.5371C8.8255 14.5371 8.10284 14.3691 7.45831 14.0645L4.29034 14.8496L5.18488 11.9199C4.80206 11.2129 4.58331 10.4004 4.58331 9.53711C4.58331 6.77539 6.82159 4.53711 9.58331 4.53711C12.345 4.53711 14.5833 6.77539 14.5833 9.53711C14.5833 12.2988 12.345 14.5371 9.58331 14.5371Z"
    fill={color}
  />
</svg>
