<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import { COLOR_ATTENTION } from "buttonStyles";
</script>

<PageSection>
  <div class="token-issuers">
    <div class="columns">
      <div class="column left">
        <Eyebrow>Token Issuers</Eyebrow>
        <Heading3>
          Create custom digital tokens with no-code smart contracts
        </Heading3>
        <Body>
          All digital tokens are, in essence, a digital legal/financial
          instrument (e.g. share certificate) that is used to signify the
          ownership of the holder’s rights, duties, and obligations specified in
          the originating contract.
          <br /><br />This is an important insight for understanding digital
          assets more broadly, and it applies as equally to complex financial
          instruments like collateralized debt obligations and swaps, as it does
          to utility instruments like reward points, coupons, vouchers, tickets,
          carbon credits, etc.
          <br /><br />A smart contract utilizes a software agent to interpret
          and enforce certain terms and conditions, as well as automate
          administrative tasks such as managing transfer requests and updating
          ownership registries, as well as enforcing rights and managing
          governance proceedings.
          <br /><br />This approach results in significant efficiency gains, and
          a better user experience, across the lifecycle of the contract.
        </Body>
        <div class="column right image mobile-visible">
          <ImageFrame width={560} src="/images/smart-contract.webp" />
        </div>
        <div class="sub-icon-row bottom">
          <IconColumn icon="ai">
            Smart contracts are a blend of machine-readable and enforceable
            terms, and human-readable and enforceable terms, that are recorded
            to a distributed ledger in a structured and interoperable format.
          </IconColumn>
          <IconColumn icon="ledger">
            The ownership registry, certificates, and all rights, duties, and
            obligations are recorded to a distributed ledger for an immutable
            single source of truth.
          </IconColumn>
        </div>
        <div class="buttons">
          <TextButton
            large
            noFill
            color={COLOR_ATTENTION}
            iconRight="chevron-right"
            href="/#/platform/contracts/"
          >
            Learn more about smart contracts
          </TextButton>
        </div>
      </div>
      <div class="column right image tablet-visible">
        <ImageFrame width={560} src="/images/smart-contract.webp" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .token-issuers {
    @extend .generic-section;
  }
</style>
