<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import ImageFrame from "../../../elements/ImageFrame.svelte";
</script>

<PageSection shaded>
  <div class="digital-wallet">
    <div class="columns top">
      <div class="column left">
        <Eyebrow>Digital Wallet</Eyebrow>
        <Heading2>Send, receive, and exchange tokenized instruments</Heading2>
        <Body>
          Individuals and organizations can now securely administer their
          digital assets, liabilities, and equity on one platform.
        </Body>
      </div>
      <div class="column right image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/instrument-list.webp"
          alt="digital wallet"
        />
      </div>
    </div>
    <div class="columns bottom icon-row">
      <div class="icon-column">
        <IconColumn icon="trusted-security" heading="Secure">
          Digital keys are generated, stored, and used on the user’s mobile
          device. The private keys are never shared with anyone, including
          Tokenized.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="security-padlock" heading="Private">
          Our wallet never reuses keys, and it employs privacy algorithms to
          ensure that no one can spy on your activities.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="winners" heading="Safe">
          Losing your password does not mean you lose your holdings. Each user
          is provided with backup codes for recovery, and even in the event of a
          loss of the backup code, there are recovery options.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="collaboration" heading="Open Source">
          Our entire UI is free and open-source software that can be used in
          many different ways, including white labelling.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .digital-wallet {
    @extend .generic-section;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: $I05;
  }
</style>
