<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M40.4105 19.8384L37.8736 13.7136L34.1468 14.9536C33.1128 13.5067 31.8608 12.2707 30.4549 11.2677L31.6968 7.53687L25.5721 4.99997L23.8132 8.51584C22.1092 8.23185 20.3503 8.21985 18.5954 8.51284L16.8374 4.99997L10.7127 7.53687L11.9536 11.2637C10.5057 12.2977 9.27074 13.5497 8.26777 14.9556L4.53691 13.7136L2 19.8384L5.51588 21.5974C5.23089 23.3013 5.21989 25.0602 5.51288 26.8142L2 28.5721L4.53691 34.6969L8.26377 33.4569C9.29673 34.9039 10.5487 36.1398 11.9556 37.1428L10.7127 40.8736L16.8374 43.4105L18.5974 39.8947C20.3013 40.1787 22.0602 40.1906 23.8142 39.8977L25.5721 43.4105L31.6968 40.8736L30.4559 37.1468C31.9038 36.1128 33.1398 34.8609 34.1427 33.4549L37.8736 34.6969L40.4105 28.5721L36.8936 26.8132C37.1786 25.1092 37.1906 23.3503 36.8976 21.5964L40.4105 19.8384Z"
    fill="#B2D8FA"
  />
  <path
    d="M40.4105 19.8384L37.8736 13.7136L34.1468 14.9536C33.1128 13.5067 31.8608 12.2707 30.4549 11.2677L31.6968 7.53687L25.5721 4.99997L23.8132 8.51584C22.1092 8.23185 20.3503 8.21985 18.5954 8.51284L16.8374 4.99997L10.7127 7.53687L11.9536 11.2637C10.5057 12.2977 9.27074 13.5497 8.26777 14.9556L4.53691 13.7136L2 19.8384L5.51588 21.5974C5.23089 23.3013 5.21989 25.0602 5.51288 26.8142L2 28.5721L4.53691 34.6969L8.26377 33.4569C9.29673 34.9039 10.5487 36.1398 11.9556 37.1428L10.7127 40.8736L16.8374 43.4105L18.5974 39.8947C20.3013 40.1787 22.0602 40.1906 23.8142 39.8977L25.5721 43.4105L31.6968 40.8736L30.4559 37.1468C31.9038 36.1128 33.1398 34.8609 34.1427 33.4549L37.8736 34.6969L40.4105 28.5721L36.8936 26.8131C37.1786 25.1092 37.1906 23.3503 36.8976 21.5964L40.4105 19.8384Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M27.0811 26.6412C25.7361 29.8861 22.0133 31.427 18.7664 30.0811C15.5195 28.7361 13.9775 25.0142 15.3225 21.7684C16.6684 18.5225 20.3903 16.9825 23.6382 18.3275C26.8851 19.6734 28.427 23.3953 27.0811 26.6412Z"
    fill="white"
  />
  <path
    d="M27.0811 26.6412C25.7361 29.8861 22.0133 31.427 18.7664 30.0811C15.5195 28.7361 13.9775 25.0142 15.3225 21.7684C16.6684 18.5225 20.3903 16.9825 23.6382 18.3275C26.8851 19.6734 28.427 23.3953 27.0811 26.6412V26.6412Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M45.3923 22.7044C46.3193 20.4684 48.8852 19.4075 51.1231 20.3344C53.361 21.2614 54.423 23.8263 53.496 26.0622C53.1275 26.9537 52.4742 27.698 51.6381 28.1791"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M61.7626 43.8705L60.0277 39.6797L56.7878 41.0217C56.1456 40.1228 55.3586 39.3369 54.4589 38.6957L55.8019 35.4539L51.611 33.7179L50.2681 36.9598C49.1788 36.7766 48.0667 36.776 46.9772 36.9578L45.6353 33.7179L41.4444 35.4539L42.7864 38.6927C41.8734 39.3447 41.0944 40.1347 40.4615 41.0217L37.2186 39.6787L35.4836 43.8695L38.7255 45.2125C38.5425 46.3017 38.5415 47.4138 38.7225 48.5034L35.4836 49.8453L37.2196 54.0362L40.4585 52.6942C41.1007 53.593 41.8877 54.379 42.7874 55.0201L41.4444 58.262L45.6353 59.9979L46.9782 56.7561C48.0675 56.9392 49.1796 56.9399 50.2691 56.7581L51.611 59.9979L55.8019 58.262L54.4599 55.0231C55.3587 54.3808 56.1447 53.5938 56.7858 52.6942L60.0277 54.0372L61.7626 49.8463L58.5208 48.5034C58.7007 47.4284 58.7088 46.3184 58.5238 45.2125L61.7626 43.8705Z"
    fill="#EEF0F2"
  />
  <path
    d="M61.7626 43.8705L60.0277 39.6797L56.7878 41.0217C56.1456 40.1228 55.3586 39.3369 54.4589 38.6957L55.8019 35.4539L51.611 33.7179L50.2681 36.9598C49.1788 36.7766 48.0667 36.776 46.9772 36.9578L45.6353 33.7179L41.4444 35.4539L42.7864 38.6927C41.8734 39.3447 41.0944 40.1347 40.4615 41.0217L37.2186 39.6787L35.4836 43.8695L38.7255 45.2125C38.5425 46.3017 38.5415 47.4138 38.7225 48.5034L35.4836 49.8453L37.2196 54.0362L40.4585 52.6942C41.1007 53.593 41.8877 54.379 42.7874 55.0201L41.4444 58.262L45.6353 59.9979L46.9782 56.7561C48.0675 56.9392 49.1796 56.9399 50.2691 56.7581L51.611 59.9979L55.8019 58.262L54.4599 55.0231C55.3587 54.3808 56.1447 53.5938 56.7858 52.6942L60.0277 54.0372L61.7626 49.8463L58.5208 48.5034C58.7007 47.4284 58.7088 46.3184 58.5238 45.2125L61.7626 43.8705Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M57.1089 30.49L60.2118 31.999L62.1617 27.9881L59.0588 26.4792C59.3026 25.4208 59.3689 24.3293 59.2548 23.2493L62.5137 22.1224L61.0567 17.9075L57.7978 19.0335C57.2119 18.0985 56.4829 17.2875 55.6489 16.6136L57.1579 13.5107L53.147 11.5608L51.6381 14.6636C50.5941 14.4236 49.5052 14.3506 48.4082 14.4676L47.2822 11.2088L43.0664 12.6657L44.1933 15.9246"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M52.6941 45.1715C53.6251 47.4204 52.5571 49.9973 50.3092 50.9283C48.0613 51.8593 45.4844 50.7913 44.5524 48.5434C43.6214 46.2955 44.6894 43.7186 46.9373 42.7876C49.1853 41.8566 51.7621 42.9236 52.6941 45.1715Z"
    fill="white"
  />
  <path
    d="M52.6941 45.1715C53.6251 47.4204 52.5571 49.9973 50.3092 50.9283C48.0613 51.8593 45.4844 50.7913 44.5524 48.5434C43.6214 46.2955 44.6894 43.7186 46.9373 42.7876C49.1853 41.8566 51.7621 42.9236 52.6941 45.1715Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
