<script>
  export let buttons;
  export let activeButtonIdx;
  export let onBackdropClick = () => {};

  let previousIdx = -1;
  let leftOffset = 0;
  let button;
  $: {
    button = buttons[activeButtonIdx];
    if (previousIdx !== activeButtonIdx) {
      leftOffset = 0;
    }
    if (button) {
      previousIdx = activeButtonIdx;
      buttons
        .filter((_, i) => i <= activeButtonIdx)
        .forEach((button, i) => {
          if (i > 0) {
            leftOffset += 32;
          }
          if (i === activeButtonIdx) {
            leftOffset += parseInt(button.getClientWidth() / 2, 10);
          } else {
            leftOffset += button.getClientWidth();
          }
        });
    }
  }
</script>

{#if !!button}
  <div class="backdrop" on:click={onBackdropClick} />
  <div class="menu" style="left: {leftOffset}px;">
    <div class="arrow" />
    <div class="content"><slot /></div>
  </div>
{/if}

<style lang="scss">
  @import "../../styles/global.scss";

  .arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $white;
  }
  .backdrop {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: transparent;
  }
  .menu {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 476px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $B95;
    z-index: 2;

    transform: translateX(-50%);
    .content {
      background-color: $white;
      width: 476px;
      height: fit-content;
      box-shadow: 0px 50px 100px -20px rgba(63, 73, 89, 0.2),
        0px 30px 60px -30px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
    }
  }
</style>
