<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M55.6614 32.6552C55.6614 45.9106 44.9169 56.6551 31.6615 56.6551C18.4068 56.6551 7.66162 45.9106 7.66162 32.6552C7.66162 19.4013 18.4068 8.65535 31.6615 8.65535C44.9169 8.65535 55.6614 19.4013 55.6614 32.6552Z"
    fill="#EEF0F2"
  />
  <path
    d="M25.0039 34.0008V20.2099C25.0039 17.3014 27.5959 14.9344 30.5036 14.9344C33.4121 14.9344 36.0041 17.3014 36.0041 20.2099V34.0008H42.004V20.5143C42.004 14.2946 36.7226 9.23592 30.5036 9.23592C24.2846 9.23592 19.0039 14.2946 19.0039 20.5143V34.0008H25.0039Z"
    fill="white"
  />
  <path
    d="M25.0039 34.0008V20.2099C25.0039 17.3014 27.5959 14.9344 30.5036 14.9344C33.4121 14.9344 36.0041 17.3014 36.0041 20.2099V34.0008H42.004V20.5143C42.004 14.2946 36.7226 9.23592 30.5036 9.23592C24.2846 9.23592 19.0039 14.2946 19.0039 20.5143V34.0008H25.0039Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M15.0044 31.0008V49.0007C15.0044 49.531 15.2151 50.0396 15.59 50.4145C15.965 50.7895 16.4736 51.0002 17.0039 51.0002H44.0037C44.5341 51.0002 45.0428 50.7895 45.4178 50.4146C45.7929 50.0396 46.0038 49.5311 46.004 49.0007V31.0008C46.0038 30.4704 45.793 29.9617 45.4179 29.5866C45.0428 29.2116 44.5342 29.0008 44.0037 29.0006H17.0039C16.4735 29.0008 15.9649 29.2116 15.59 29.5867C15.215 29.9618 15.0044 30.4704 15.0044 31.0008Z"
    fill="#B2D8FA"
  />
  <path
    d="M19.0041 46.0007V48.0002M19.0041 32.0005V44.0005M15.0044 31.0008V49.0007C15.0044 49.531 15.2151 50.0396 15.59 50.4145C15.965 50.7895 16.4736 51.0002 17.0039 51.0002H44.0037C44.5341 51.0002 45.0428 50.7895 45.4178 50.4146C45.7929 50.0396 46.0038 49.5311 46.004 49.0007V31.0008C46.0038 30.4704 45.793 29.9617 45.4179 29.5866C45.0428 29.2116 44.5342 29.0008 44.0037 29.0006H17.0039C16.4735 29.0008 15.9649 29.2116 15.59 29.5867C15.215 29.9618 15.0044 30.4704 15.0044 31.0008Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M32.7916 2.42C49.0163 3.07699 61.6365 16.7629 60.9787 32.9868C60.9481 33.67 60.8941 34.3519 60.8167 35.0313M19.7927 4.86798C9.83201 9.24571 2.6958 19.0024 2.22555 30.6063C2.01068 35.7635 3.16448 40.8856 5.56978 45.4525M14.1865 55.4874C18.9041 58.9643 24.5567 60.945 30.4126 61.1732C41.2846 61.6134 51.0098 56.0867 56.445 47.5135"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M33.0039 45.0001V40.4454C34.1949 39.7524 35.0034 38.4774 35.0034 37.0006C35.003 36.3936 34.8647 35.7946 34.5989 35.2489C34.333 34.7032 33.9467 34.2251 33.469 33.8506C32.9912 33.4761 32.4347 33.2151 31.8413 33.0872C31.2479 32.9594 30.6332 32.9681 30.0437 33.1126C28.6262 33.4411 27.4727 34.5834 27.1262 35.9971C26.6649 37.8856 27.5499 39.5994 29.0034 40.4454V45.0001C29.0136 45.5238 29.2287 46.0226 29.6027 46.3894C29.9766 46.7561 30.4795 46.9616 31.0033 46.9616C31.5271 46.9616 32.03 46.7561 32.4039 46.3894C32.7778 46.0226 32.993 45.5238 33.0031 45.0001"
    fill="white"
  />
  <path
    d="M33.0039 45.0001V40.4453C34.1949 39.7524 35.0034 38.4774 35.0034 37.0006C35.003 36.3936 34.8647 35.7946 34.5989 35.2489C34.333 34.7032 33.9467 34.2251 33.469 33.8506C32.9912 33.4761 32.4347 33.2151 31.8413 33.0872C31.2479 32.9594 30.6332 32.9681 30.0437 33.1126C28.6262 33.4411 27.4727 34.5834 27.1262 35.9971C26.6649 37.8856 27.5499 39.5994 29.0034 40.4453V45.0001C29.0033 45.2628 29.055 45.5229 29.1555 45.7657C29.256 46.0084 29.4033 46.2289 29.5891 46.4147C29.7748 46.6004 29.9954 46.7478 30.2381 46.8483C30.4808 46.9487 30.7409 47.0004 31.0037 47.0003C31.5341 47.0003 32.0429 46.7896 32.418 46.4145C32.7931 46.0393 33.0039 45.5306 33.0039 45.0001V45.0001Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
