<script>
  import Icon from "components/elements/Icon";

  export let dark = false;
</script>

<div class="bullet-point {dark ? 'dark' : ''}">
  <Icon name="check-circle" ref="icon-svg" />
  <span><slot /></span>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .bullet-point {
    padding-left: 16px;
    padding-bottom: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $N80;
    display: flex;
    align-items: center;

    span {
      padding-left: 12px;
    }
    &.dark {
      color: $N15;
      :global([ref="icon-svg"] > path) {
        stroke: $B40;
      }
    }
  }
</style>
