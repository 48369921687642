<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M49.9996 44.9998C49.9996 54.9402 41.9409 62.9997 31.9997 62.9997C22.0592 62.9997 13.9998 54.9402 13.9998 44.9998C13.9998 35.0586 22.0592 26.9999 31.9997 26.9999C41.9409 26.9999 49.9996 35.0586 49.9996 44.9998Z"
    fill="#EEF0F2"
  />
  <path d="M60.9999 45.9995H3.00049V3.99976H60.9999V45.9995Z" fill="white" />
  <path
    d="M8.99951 45.9994H20.9995"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M2 42.9995V46.9992H6.00046M2 6.99973V3H6.00046"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M2.99976 9.99972V39.9995M54.9999 45.9995H43M54.9999 3.99976H9.00047"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M61.9995 42.9995V46.9992H57.9998M61.9995 6.99973V3H57.9998"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M61 9.99963V39.9995"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M28 43.9991V58.9991L31.9997 55.9991L36.0002 58.9991V43.9991H28Z"
    fill="#B2D8FA"
  />
  <path
    d="M28 43.9991V58.9991L31.9997 55.9991L36.0002 58.9991V43.9991H28Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M31.9998 33.1887C33.6183 30.7775 37.378 32.3344 36.8163 35.1837C39.6648 34.6227 41.2233 38.3817 38.8113 39.9994C41.2233 41.6172 39.6648 45.3769 36.8163 44.8151C37.378 47.6651 33.6183 49.2221 31.9998 46.8116C30.3821 49.2221 26.6224 47.6651 27.1841 44.8151C24.3349 45.3776 22.7771 41.6172 25.1884 39.9994C22.7771 38.3817 24.3349 34.6219 27.1841 35.1837C26.6216 32.3344 30.3821 30.7775 31.9998 33.1887Z"
    fill="#B2D8FA"
  />
  <path
    d="M31.9998 33.1887C33.6183 30.7775 37.3781 32.3344 36.8163 35.1837C39.6648 34.6227 41.2233 38.3817 38.8113 39.9994C41.2233 41.6172 39.6648 45.3769 36.8163 44.8151C37.3781 47.6651 33.6183 49.2221 31.9998 46.8116C30.3821 49.2221 26.6224 47.6651 27.1841 44.8151C24.3349 45.3776 22.7771 41.6172 25.1884 39.9994C22.7771 38.3817 24.3349 34.6219 27.1841 35.1837C26.6216 32.3344 30.3821 30.7775 31.9998 33.1887Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
  <path
    d="M43.6756 40.7847C45.0998 40.2432 46.3748 38.8662 47.8958 38.8662C49.7888 38.8662 54.2333 41.8887 55.5833 40.6445C57.0383 39.4887 53.5065 35.687 53.5065 34.067C53.5065 32.447 55.9995 31.1533 55.9995 29.5333C55.9995 27.9133 53.5065 26.6195 53.5065 24.9995C53.5065 23.3795 55.9995 22.0866 55.9995 20.4658C55.9995 18.8466 53.5065 17.5528 53.5065 15.9328C53.5065 14.3129 57.039 10.5104 55.584 9.35463C54.2325 8.11188 49.7888 11.1321 47.8958 11.1321C46.0021 11.1321 44.4908 8.99988 42.5978 8.99988C40.7041 8.99988 39.1921 11.1329 37.2991 11.1329C35.4046 11.1329 33.8926 9.00063 31.9996 9.00063C30.1059 9.00063 28.5946 11.1336 26.7009 11.1336C24.8079 11.1336 23.2959 9.00063 21.4022 9.00063C19.5092 9.00063 17.9972 11.1336 16.1027 11.1336C14.2097 11.1336 9.76601 8.11113 8.41527 9.35612C6.96102 10.5111 10.4927 14.3136 10.4927 15.9336C10.4927 17.5536 7.99976 18.8466 7.99976 20.4666C7.99976 22.0866 10.4927 23.3795 10.4927 24.9995C10.4927 26.6195 7.99976 27.9133 7.99976 29.5325C7.99976 31.1525 10.4927 32.4463 10.4927 34.0662C10.4927 35.6862 6.96102 39.4887 8.41527 40.6437C9.76601 41.8887 14.2097 38.8662 16.1027 38.8662C17.5157 38.8662 18.715 40.0512 20.0177 40.6557M25.9997 24.9995H37.9996M22 20.9998H42.0001"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
  <path
    d="M34.9997 39.9996C34.9997 40.7953 34.6837 41.5583 34.1211 42.1209C33.5584 42.6835 32.7954 42.9996 31.9997 42.9996C31.2041 42.9996 30.441 42.6835 29.8784 42.1209C29.3158 41.5583 28.9998 40.7953 28.9998 39.9996C28.9998 39.204 29.3158 38.4409 29.8784 37.8783C30.441 37.3157 31.2041 36.9996 31.9997 36.9996C32.7954 36.9996 33.5584 37.3157 34.1211 37.8783C34.6837 38.4409 34.9997 39.204 34.9997 39.9996Z"
    fill="white"
  />
  <path
    d="M34.9997 39.9996C34.9997 40.7953 34.6837 41.5583 34.1211 42.1209C33.5584 42.6835 32.7954 42.9996 31.9997 42.9996C31.2041 42.9996 30.441 42.6835 29.8784 42.1209C29.3158 41.5583 28.9998 40.7953 28.9998 39.9996C28.9998 39.204 29.3158 38.4409 29.8784 37.8783C30.441 37.3157 31.2041 36.9996 31.9997 36.9996C32.7954 36.9996 33.5584 37.3157 34.1211 37.8783C34.6837 38.4409 34.9997 39.204 34.9997 39.9996Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
</svg>
