<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection>
  <div class="automate-tasks">
    <div class="columns">
      <div class="column left image">
        <ImageFrame
          width={448}
          shaded={false}
          src="/images/cubes.webp"
          alt="unlimited-possibilities"
        />
      </div>
      <div class="column right">
        <Eyebrow>Unlimited possibilities</Eyebrow>
        <Heading3>
          Improved divisibility, flexibility, and efficiency
        </Heading3>
        <Body>
          Our platform unlocks more utility and novel use cases for non-cash
          instruments. The novel value proposition is built on better tooling,
          improved and more trustful insights, extreme fractionalization, and
          increased automation.
        </Body>
        <div class="sub-icon-row">
          <IconColumn icon="cash-payment">
            Use non-cash instruments in a cash-like way. Examples like paying 1
            share option per minute of work becomes possible, frictionless, and
            manageable.
          </IconColumn>
          <IconColumn icon="ai">
            A revolution in digital insights combined with increased automation,
            enables more sophisticated commercial agreements, but in a practical
            way.
          </IconColumn>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .automate-tasks {
    @extend .generic-section;
  }
</style>
