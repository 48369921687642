<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection>
  <div class="identity-verification">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Identity verification</Eyebrow>
        <Heading3 large
          >Streamline the collection, verification, and sharing of KYC and due
          diligence records
        </Heading3>
        <Body>
          Our platform collects and distributes all the important KYC, KYB,
          and CDD records to the relevant parties, including regulated entities,
          issuers, and advisors.
          <br /><br />Our platform also leverages a distributed ledger to
          immutably timestamp submitted records to improve provenance.
        </Body>
      </div>
      <div class="column left image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/mobile-kyc.webp"
        />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .identity-verification {
    @extend .generic-section;

    .columns {
      flex-direction: row-reverse;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }
  }
</style>
