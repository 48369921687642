<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import FeatureList from "components/widgets/FeatureList.svelte";
</script>

<PageSection shaded>
  <div class="customer-engagement">
    <div class="columns">
      <div class="column left">
        <Eyebrow>enhanced customer engagement</Eyebrow>
        <Heading3>
          Send tokens to users directly on social media platforms
        </Heading3>
        <div class="feature-list">
          <FeatureList>Reward engagement and contributions</FeatureList>
          <FeatureList>Incentivize participation in polls</FeatureList>
          <FeatureList>Positively reinforce customers</FeatureList>
          <FeatureList
            >Improved customer insights and data collection</FeatureList
          >
          <FeatureList>And more...</FeatureList>
        </div>
      </div>
      <div class="column right image">
        <ImageFrame width={448} src="/images/twitter-feed.webp" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .customer-engagement {
    @extend .generic-section;
    .feature-list {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
    }
  }
</style>
