<script>
  export let dark = false;
  export let large = false;
  export let extralarge = false;
  export let small = small;
  export let style = "";
</script>

<div
  class="heading {$$props.class} {dark ? 'dark' : ''} {large
    ? 'large'
    : ''}  {extralarge ? 'extralarge' : ''}  {small ? 'small' : ''}"
  {style}
>
  <slot />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .heading {
    display: flex;
    padding-left: 16px;
    font-weight: 700;
    color: $B95;
    letter-spacing: 0.015em;
  }
  .heading1,
  .heading2,
  .heading3 {
    padding-bottom: 28px;
    padding-right: 16px;
    @media only screen and (max-width: $size-tablet-max) {
      padding-bottom: 24px;
    }
  }
  .heading4,
  .heading5,
  .heading6 {
    padding-bottom: 16px;
  }
  .heading1 {
    font-size: 50px;
    line-height: 65px;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 42px;
      line-height: 55px;
    }
  }
  .heading2 {
    font-size: 42px;
    line-height: 55px;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 35px;
      line-height: 46px;
    }
  }
  .heading3 {
    font-size: 35px;
    line-height: 46px;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 29px;
      line-height: 38px;
    }
  }
  .heading4 {
    font-size: 24px;
    line-height: 30px;
  }
  .heading5 {
    font-size: 18px;
    line-height: 22px;

    &.small {
      font-size: 17px;
      line-height: 25px;
    }
  }
  .heading6 {
    font-size: 16px;
    line-height: 20px;
  }
  .dark {
    color: $white;
  }
</style>
