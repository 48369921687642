<script>
  import {
    INSTRUMENTS,
    CONTRACT_DURATION_OPTIONS,
    DECIMAL_PRECISION_OPTIONS,
  } from "utils/instruments";
  import {
    formatDecimal,
    formatInteger,
    formatDecimalCompound,
  } from "utils/format";
  import Input from "components/elements/Input.svelte";
  import Select from "components/elements/Select.svelte";

  // "Starting points" for the slider ranges, in scaled units.
  const PRICING_RANGE_THESHOLDS = [
    0, 1000000, 1000000000, 1000000000000, 1000000000000000,
  ];

  const PRECISION_MULTIPLIER = 0.5; // 50% of current outstanding token fees per decimal point

  const formatDecimalGetter = (precision) => (value) =>
    formatDecimalCompound(value, precision);

  const numericInput = (value, min) => {
    let parsedValue = parseInt(
      value ? String(value).replace(/[^0-9]/g, "") : "0",
      10
    );
    if (min !== undefined && parsedValue < min) {
      parsedValue = min;
    }
    return parsedValue;
  };

  const getScaledRangeUnits = (units) => {
    const step = Math.floor(units / 1000);
    let multiplier = units % 1000 || 1;
    if (!step) {
      return units;
    }
    return Math.round(multiplier * Math.pow(1000, step));
  };

  const getRangeFromScaledUnits = (value) => {
    /**
     * Performs the inverse of getScaledRangeUnits returning the slider range value
     * needed to produce the matching scaled units amount (for correct slider positioning).
     */
    const step = Math.floor(Math.log10(value) / 3);
    if (!step) {
      return value;
    }
    const baseRangeValue = step * 1000;
    const rangeStep = value / Math.pow(1000, step);
    return baseRangeValue + rangeStep;
  };

  const isPricingRangeActive = (idx, units) => {
    const matchValue = PRICING_RANGE_THESHOLDS[idx] || 0;
    const upperValue = PRICING_RANGE_THESHOLDS[idx + 1] || 0;
    return (
      (!matchValue || units > matchValue) &&
      (!upperValue || units <= upperValue)
    );
  };

  const getActiveUnitPricingRange = (selectedPricing, scaledUnits) => {
    if (!selectedPricing) {
      return null;
    }
    const priceRange = selectedPricing.unitFees.find((_, idx) =>
      isPricingRangeActive(idx, scaledUnits)
    );
    return priceRange;
  };

  const getTiersFee = (units, pricing, lowerTiers = false) => {
    if (!units) {
      return 0;
    }
    let fee = 0;
    let unallocatedUnits = units;
    for (let i = 0; i < PRICING_RANGE_THESHOLDS.length; i++) {
      const rangeThreshold = PRICING_RANGE_THESHOLDS[i];
      const nextRangeThreshold = PRICING_RANGE_THESHOLDS[i + 1];

      if (!unallocatedUnits) {
        break;
      }
      const isActive = isPricingRangeActive(i, units);
      if (lowerTiers && isActive) {
        continue;
      } else if (!lowerTiers && !isActive) {
        continue;
      }

      if (isActive) {
        const priceUnits = units - rangeThreshold;
        unallocatedUnits -= priceUnits;
        const tierFee = priceUnits * pricing.unitFees[i].fee;
        fee += tierFee;
        continue;
      }

      if (nextRangeThreshold !== undefined && units > nextRangeThreshold) {
        const priceUnits = nextRangeThreshold - rangeThreshold;
        unallocatedUnits -= priceUnits;
        const tierFee = priceUnits * pricing.unitFees[i].fee;
        fee += tierFee;
        continue;
      }
    }
    return fee;
  };

  let selectedInstrument = INSTRUMENTS[0];
  let selectedPrecision = DECIMAL_PRECISION_OPTIONS[0].value;
  let selectedRangeValue = 0;
  $: selectedActionSize = numericInput(selectedActionSize, 1);
  let contractDuration = 1;
  $: selectedInstrumentPricing = selectedInstrument.pricing;
  $: scaledRangeUnits = getScaledRangeUnits(selectedRangeValue);
  $: {
    // Ensure the user cannot enter non-numbers manually.
    scaledRangeUnits = numericInput(scaledRangeUnits);
  }
  $: lowerTiersFee = getTiersFee(
    scaledRangeUnits,
    selectedInstrumentPricing,
    true
  );
  $: oneTimeIssuanceFee = getOneTimeIssuanceActionFee(
    selectedInstrumentPricing.actionFee,
    scaledRangeUnits,
    selectedActionSize
  );
  $: activeTierFee = getTiersFee(
    scaledRangeUnits,
    selectedInstrumentPricing,
    false
  );
  $: precisonFee = selectedInstrument.variablePrecision
    ? (activeTierFee + lowerTiersFee) *
      PRECISION_MULTIPLIER *
      (selectedPrecision - 2)
    : 0;

  $: totalFeesMonth =
    selectedInstrumentPricing.issuerFee +
    lowerTiersFee +
    activeTierFee +
    precisonFee;
  $: totalFeesYear = totalFeesMonth * 12;
  $: totalFeePerYearPerUnit = scaledRangeUnits
    ? totalFeesYear / scaledRangeUnits
    : 0;
  $: totalFeePerContractDuration = scaledRangeUnits
    ? totalFeesMonth * contractDuration
    : 0;
  $: totalFeePerCoupon = scaledRangeUnits
    ? totalFeePerContractDuration / scaledRangeUnits
    : 0;
  $: activeUnitPrice = getActiveUnitPricingRange(
    selectedInstrumentPricing,
    scaledRangeUnits
  );
  $: outstandingValue = scaledRangeUnits
    ? (totalFeesYear * 100) / scaledRangeUnits
    : 0;

  const getOneTimeIssuanceActionFee = (actionFee, units, actionSize) => {
    if (actionSize <= 0) {
      actionSize = 1;
    }
    return (actionFee * units) / actionSize;
  };

  const onScaledRangeUnitsChange = (e) => {
    const value = numericInput(e.target.value);
    if (!value) {
      selectedRangeValue = 0;
      return;
    }
    const descaledRange = getRangeFromScaledUnits(value);
    selectedRangeValue = descaledRange;
  };
</script>

<div class="fee-calculator">
  <div class="heading">
    <div class="title">Fee estimation calculator</div>
    <div class="notice">Prices in USD</div>
  </div>
  <div class="instrument-type">
    <div class="section-title">Instrument type</div>
    <div class="input-action">
      <Select options={INSTRUMENTS} bind:value={selectedInstrument} />
    </div>
  </div>
  <div class="contract-duration">
    <div class="section-title">Contract duration</div>
    <div class="input-action">
      <Select
        options={CONTRACT_DURATION_OPTIONS}
        bind:value={contractDuration}
      />
    </div>
  </div>
  {#if selectedInstrument.variablePrecision}
    <div class="token-precision">
      <div class="section-title">Decimals</div>
      <div class="input-action">
        <Select
          options={DECIMAL_PRECISION_OPTIONS}
          bind:value={selectedPrecision}
        />
      </div>
    </div>
  {/if}
  <div class="issue-action">
    <div class="section-title">Average size of issuance action</div>
    <div class="input-action">
      <Input
        bind:value={selectedActionSize}
        label={selectedInstrument.namePlural}
        getDisplayValue={formatInteger}
      />
    </div>
  </div>
  <div class="total-outstanding">
    <div class="section-title">
      Total {selectedInstrument.namePlural} outstanding
    </div>
    <div class="input-action">
      <Input
        bind:value={scaledRangeUnits}
        on:change={onScaledRangeUnitsChange}
        label={selectedInstrument.namePlural}
        getDisplayValue={selectedInstrument.variablePrecision
          ? formatDecimalGetter(selectedPrecision)
          : formatInteger}
      />
    </div>
  </div>
  <div class="slider">
    <input
      type="range"
      min="0"
      max={PRICING_RANGE_THESHOLDS.length * 1000}
      step={10}
      bind:value={selectedRangeValue}
      class="slider-range"
    />
    <div class="pricing-marks">
      <span>0</span>
      <span>1k</span>
      <span>1M</span>
      <span>1B</span>
      <span>1T</span>
      <span>1Q</span>
    </div>
    <div class="pricing-range-column">
      <div class="pricing-ranges">
        {#each selectedInstrumentPricing.unitFees as priceRange, idx}
          {@const active = isPricingRangeActive(idx, scaledRangeUnits)}
          {@const activeRight = isPricingRangeActive(idx + 1, scaledRangeUnits)}
          {#if !priceRange.hidden}
            <div class="range-block" class:active>
              <span
                class="lines"
                class:first={idx === 0}
                class:active
                class:active-right={activeRight}
              />
              <span
                class="lines"
                class:first={idx === 0}
                class:active
                class:active-right={activeRight}
              />
            </div>
          {/if}
        {/each}
      </div>
      {#if activeUnitPrice}
        <div class="fees">
          <div>{activeUnitPrice.feeLabel}</div>
          <div>per {selectedInstrument.nameSingular}</div>
        </div>
      {/if}
    </div>
  </div>

  <div class="section-title">Estimated fees</div>

  <div class="row baseFee">
    <span class="row-title"> Base issuer operations fee</span>
    <span class="row-value"
      >${formatDecimal(selectedInstrumentPricing.issuerFee)} / mo.</span
    >
  </div>
  <div class="row">
    <span class="row-title">Units outstanding fee, lower tiers</span>
    <span class="row-value">${formatDecimal(lowerTiersFee)} / mo.</span>
  </div>
  <div class="row">
    <span class="row-title"> Units outstanding fee, active tier</span>
    <span class="row-value">${formatDecimal(activeTierFee)} / mo.</span>
  </div>
  {#if selectedInstrument.variablePrecision}
    <div class="row">
      <span class="row-title"> Precision surcharge</span>
      <span class="row-value">${formatDecimal(precisonFee)} / mo.</span>
    </div>
  {/if}
  <div class="divider" />
  <div class="row oneTimeFees">
    <span class="row-title"> Total one-time issuance action fees, in BSV</span>
    <span class="row-value">${formatDecimal(oneTimeIssuanceFee)}</span>
  </div>
  {#if contractDuration === 12}
    <div class="row totalFeesMonth">
      <span class="row-title"
        >Total operations fees per year / units outstanding (%)</span
      >
      <span class="row-value">{outstandingValue.toFixed(4)}%</span>
    </div>
  {/if}
  <div class="row totalFeesMonth">
    {#if contractDuration === 12}
      <span class="row-title">Total operations fees per year</span>
      <span class="row-value">${formatDecimal(totalFeesYear)}</span>
    {:else}
      <span class="row-title">Total issuer operations fees</span>
      <span class="row-value"
        >${formatDecimal(totalFeePerContractDuration)}</span
      >
    {/if}
  </div>
  <div class="row feePerUnit">
    {#if contractDuration === 12}
      <span class="row-title">
        Total operations fees per {selectedInstrument.nameSingular} per year
      </span>
      <span class="row-value">${formatDecimal(totalFeePerYearPerUnit)}</span>
    {:else}
      <span class="row-title">
        Total issuer operations fees per {selectedInstrument.nameSingular}
      </span>
      <span class="row-value">${formatDecimal(totalFeePerCoupon)}</span>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";
  .fee-calculator {
    margin-left: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;
    background-color: $white;
    box-shadow: 0px 13px 27px -5px rgba(63, 73, 89, 0.2),
      0px 8px 16px -8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 16px 28px;
    font-family: "Lato", sans-serif;
    .slider-range {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      ::-webkit-slider-runnable-track {
        width: 100%;
        background: $N20;
        height: 4px;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: 14px;
        height: 14px;
        background: $white;
        border-radius: 50%;
        border: 4px solid $B55;
        cursor: pointer;
      }
    }

    .heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 6px 0px 18px 0px;
      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        color: $N95;
      }
      .notice {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: $N55;
        text-align: end;
      }
    }
    .dropdown {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      width: 100%;
      border-radius: 4px;
      height: 36px;
      font-size: 15px;
      border-color: $N20;
      border-radius: 4px;
    }
    .input-action {
      width: 220px;
      flex-shrink: 0;
    }
    .section-title {
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
      color: $I70;
      text-transform: uppercase;
      min-width: 40px;
    }
    .instrument-type {
      margin-bottom: 24px;
      input {
        margin-top: 12px;
      }
    }
    .instrument-type,
    .contract-duration,
    .issue-action,
    .total-outstanding,
    .token-precision {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      input {
        text-align: right;
        margin-top: 4px;
      }
    }
    .slider {
      margin-bottom: 28px;

      .pricing-marks {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $N45;
        position: relative;
        top: -10px;
        z-index: 0;
        pointer-events: none;
        margin-top: 9px;
      }
      .pricing-marks span {
        font-size: 13px;
        line-height: 16px;
        width: 10px;
      }
      .pricing-marks span::before {
        content: "|";
        display: block;
        position: relative;
        top: 10px;
        left: 5px;
        margin-bottom: 10px;
        transform: scaleY(0.5);
      }
      .pricing-marks span:first-child::before {
        left: 2px;
      }

      .pricing-range-column {
        display: flex;
        flex-direction: column;
      }
      .pricing-ranges {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $N45;
        position: relative;
        top: -4px;
        padding: 8px 2px;
      }
      .range-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        color: $N65;
        font-size: 13px;
        line-height: 18px;
        max-width: 20%;
      }
      .range-block:first-child {
        max-width: 40%;
      }
      .active {
        color: $B65;
      }
      .lines {
        width: 100%;
        height: 4px;
        border-right: 1px solid var(--color-N20);
      }
      .lines.first {
        border-left: 1px solid var(--color-N20);
      }
      .lines.first.active {
        border-left: 1px solid var(--color-B55);
      }
      .lines.active,
      .lines.active-right {
        border-right: 1px solid var(--color-B55);
      }
      .lines + .lines {
        border-top: 1px solid var(--color-N20);
      }
      .lines + .lines.active {
        border-top: 1px solid var(--color-B55);
      }
      .fees {
        width: 100%;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $B65;
        font-size: 13px;
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0px;
      margin: 4px 0px;
      font-size: 15px;
      line-height: 20px;
      .row-title {
        font-weight: 400;
        color: $N85;
      }
      .row-value {
        font-weight: 600;
        color: $N95;
      }
      &.feePerUnit span {
        font-size: 17px;
        line-height: 24px;
        font-weight: 900;
      }
    }
    .baseFee {
      margin-top: 12px;
    }

    .divider {
      border-bottom: 1px solid $N20;
      width: 100%;
      height: 1px;
      margin: 8px 0px;
    }
  }

  @media only screen and (max-width: $size-phablet-max) {
    .fee-calculator {
      max-width: none;
      margin-left: 0px;
    }
  }

  @media only screen and (max-width: $size-phone-min) {
    .fee-calculator {
      padding: 16px 16px;
    }
    .instrument-type,
    .contract-duration,
    .issue-action,
    .total-outstanding,
    .token-precision {
      gap: 12px;
    }
    input {
      width: 100% !important;
    }
    .row {
      gap: 12px;
    }
    .row-value {
      white-space: nowrap;
    }
    .section-title {
      flex-shrink: 3;
    }
    .input-action {
      flex-shrink: 0;
    }
  }

  @media only screen and (max-width: 360px) {
    .input-action {
      flex-shrink: 1;
    }
  }
</style>
