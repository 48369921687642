<script>
  import PageLayout from "components/PageLayout.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import {
    COLOR_ATTENTION,
    COLOR_PRIMARY,
    COLOR_SECONDARY,
  } from "buttonStyles";
</script>

<PageLayout>
  <div class="container">
    <div class="light">
      <div class="row">
        <TextButton
          noFill
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          noFill
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          noFill
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          noFill
          color={COLOR_SECONDARY}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          noFill
          active
          color={COLOR_SECONDARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          noFill
          disabled
          color={COLOR_SECONDARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Primary</TextButton
        >
        <TextButton
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Attention</TextButton
        >
        <TextButton
          active
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          disabled
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>

      <!-- large light -->
      <div class="size-separator" />
      <div class="row">
        <TextButton
          large
          noFill
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          large
          noFill
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          large
          noFill
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          large
          noFill
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          large
          noFill
          active
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          large
          noFill
          disabled
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          large
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Primary</TextButton
        >
        <TextButton
          large
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          large
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          large
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Attention</TextButton
        >
        <TextButton
          large
          active
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          large
          disabled
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <!-- large light end -->
    </div>
    <div class="dark">
      <div class="row">
        <TextButton
          dark
          noFill
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          dark
          noFill
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          dark
          noFill
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          dark
          noFill
          color={COLOR_SECONDARY}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          dark
          noFill
          active
          color={COLOR_SECONDARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          dark
          noFill
          disabled
          color={COLOR_SECONDARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          dark
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Primary</TextButton
        >
        <TextButton
          dark
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          dark
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          dark
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Attention</TextButton
        >
        <TextButton
          dark
          active
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          dark
          disabled
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>

      <!-- large dark -->
      <div class="size-separator" />
      <div class="row">
        <TextButton
          large
          dark
          noFill
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          large
          dark
          noFill
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          large
          dark
          noFill
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          large
          dark
          noFill
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Link</TextButton
        >
        <TextButton
          large
          dark
          noFill
          active
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >
        <TextButton
          large
          dark
          noFill
          disabled
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          large
          dark
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Attention</TextButton
        >
        <TextButton
          large
          dark
          active
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          large
          dark
          disabled
          color={COLOR_PRIMARY}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <div class="row">
        <TextButton
          large
          dark
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Attention</TextButton
        >
        <TextButton
          large
          dark
          active
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Active</TextButton
        >

        <TextButton
          large
          dark
          disabled
          color={COLOR_ATTENTION}
          iconLeft="plus"
          iconRight="chevron-down">Disabled</TextButton
        >
      </div>
      <!-- large dark -->
    </div>
  </div>
</PageLayout>

<style lang="scss">
  @import "../../../styles/global.scss";

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .size-separator {
      width: 100%;
      height: 100px;
    }

    .light,
    .dark {
      padding: 20px;
      width: 600px;
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .dark {
      background-color: $B90;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
</style>
