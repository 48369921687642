<script>
  import TeamPhoto from "components/elements/TeamPhoto.svelte";
  import TeamText from "components/elements/headings/TeamText.svelte";
  export let src = false;
  export let dark = "";
  export let alt = "";
  export let name = false;
  export let position = false;
</script>

<div class="team-member">
  <TeamPhoto {src} {alt} />
  <TeamText {dark} {name} {position}>
    <slot />
  </TeamText>
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .team-member {
    display: flex;
    flex-direction: column;
    margin: 36px 0px 96px 16px;
  }
</style>
