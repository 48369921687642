<script context="module">
  export const ICONS = {
    // Static icons
    "logo-default": LogoDefaultSVG,
    "logo-dark": LogoDarkSVG,

    // Customizable icons (Svelte components)
    plus: PlusSVG,
    "chevron-down": ChevronDownSVG,
    "chevron-up": ChevronUpSVG,
    "chevron-right": ChevronRightSVG,
    "play-circle": PlayCircleSVG,
    "certificate-of-honor": CertificateOfHonorSVG,
    "source-of-truth": SourceOfTruthSVG,
    engineering: EngineeringSVG,
    "id-scan": IDScanSVG,
    ledger: LedgerSVG,
    ai: AISVG,
    "check-circle": CheckCircleSVG,
    interaction: InteractionSVG,
    permission: PermissionSVG,
    coins: CoinsSVG,
    investment: InvestmentSVG,
    exchange: ExchangeSVG,
    technology: TechnologySVG,
    twitter: TwitterSVG,
    linkedin: LinkedInSVG,
    github: GitHubSVG,
    discourse: DiscourseSVG,
    telegram: TelegramSVG,
    duties: DutiesSVG,
    "cash-payment": CashPaymentSVG,
    repair: RepairSVG,
    inspiration: InspirationSVG,
    wallet: WalletSVG,
    settings: SettingsSVG,
    items: ItemsSVG,
    application: ApplicationSVG,
    code: CodeSVG,
    tool: ToolSVG,
    "book-open": BookOpenSVG,
    info: InfoSVG,
    document: DocumentSVG,
    mail: MailSVG,
    menu: MenuSVG,
    "arrow-right": ArrowRightSVG,
    winners: WinnersSVG,
    "trusted-security": TrustedSecuritySVG,
    "security-padlock": SecurityPadlockSVG,
    collaboration: CollaborationSVG,
    signature: SignatureSVG,
    "access-key": AccessKeySVG,
    content: ContentSVG,
    contract: ContractSVG,
    microscheme: MicroschemeSVG,
    unit: UnitSVG,
    checkbook: CheckbookSVG,
    feature: FeatureSVG,
    idea: IdeaSVG,
    taxes: TaxesSVG,
    "skill-set": SkillSetSVG,
    vault: VaultSVG,
    judgement: JudgementSVG,
    x: XSVG,
    link: LinkSVG,
    download: DownloadSVG,
    tag: TagSVG,
  };
</script>

<script>
  import LogoDefaultSVG from "components/elements/svg/LogoDefault.svelte";
  import LogoDarkSVG from "components/elements/svg/LogoDark.svelte";
  import PlusSVG from "components/elements/svg/Plus.svelte";
  import ChevronDownSVG from "components/elements/svg/ChevronDown.svelte";
  import ChevronUpSVG from "components/elements/svg/ChevronUp.svelte";
  import ChevronRightSVG from "components/elements/svg/ChevronRight.svelte";
  import PlayCircleSVG from "components/elements/svg/PlayCircle.svelte";
  import CertificateOfHonorSVG from "components/elements/svg/CertificateOfHonor.svelte";
  import SourceOfTruthSVG from "components/elements/svg/SourceOfTruth.svelte";
  import EngineeringSVG from "components/elements/svg/Engineering.svelte";
  import IDScanSVG from "components/elements/svg/IDScan.svelte";
  import LedgerSVG from "components/elements/svg/Ledger.svelte";
  import AISVG from "components/elements/svg/ArtificialIntelligence.svelte";
  import CheckCircleSVG from "components/elements/svg/CheckCircle.svelte";
  import InteractionSVG from "components/elements/svg/Interaction.svelte";
  import PermissionSVG from "components/elements/svg/Permission.svelte";
  import CoinsSVG from "components/elements/svg/Coins.svelte";
  import InvestmentSVG from "components/elements/svg/Investment.svelte";
  import ExchangeSVG from "components/elements/svg/Exchange.svelte";
  import TechnologySVG from "components/elements/svg/Technology.svelte";
  import TwitterSVG from "components/elements/svg/Twitter.svelte";
  import LinkedInSVG from "components/elements/svg/LinkedIn.svelte";
  import GitHubSVG from "components/elements/svg/GitHub.svelte";
  import DiscourseSVG from "components/elements/svg/Discourse.svelte";
  import TelegramSVG from "components/elements/svg/Telegram.svelte";
  import DutiesSVG from "components/elements/svg/Duties.svelte";
  import CashPaymentSVG from "components/elements/svg/CashPayment.svelte";
  import RepairSVG from "components/elements/svg/Repair.svelte";
  import InspirationSVG from "components/elements/svg/Inspiration.svelte";
  import WalletSVG from "components/elements/svg/Wallet.svelte";
  import SettingsSVG from "components/elements/svg/Settings.svelte";
  import ItemsSVG from "components/elements/svg/Items.svelte";
  import ApplicationSVG from "components/elements/svg/Application.svelte";
  import CodeSVG from "components/elements/svg/Code.svelte";
  import ToolSVG from "components/elements/svg/Tool.svelte";
  import BookOpenSVG from "components/elements/svg/BookOpen.svelte";
  import InfoSVG from "components/elements/svg/Info.svelte";
  import DocumentSVG from "components/elements/svg/Document.svelte";
  import MailSVG from "components/elements/svg/Mail.svelte";
  import MenuSVG from "components/elements/svg/Menu.svelte";
  import ArrowRightSVG from "components/elements/svg/ArrowRight.svelte";
  import WinnersSVG from "components/elements/svg/Winners.svelte";
  import TrustedSecuritySVG from "components/elements/svg/TrustedSecurity.svelte";
  import SecurityPadlockSVG from "components/elements/svg/SecurityPadlock.svelte";
  import CollaborationSVG from "components/elements/svg/Collaboration.svelte";
  import SignatureSVG from "components/elements/svg/Signature.svelte";
  import AccessKeySVG from "components/elements/svg/AccessKey.svelte";
  import ContentSVG from "components/elements/svg/Content.svelte";
  import ContractSVG from "components/elements/svg/Contract.svelte";
  import MicroschemeSVG from "components/elements/svg/Microscheme.svelte";
  import UnitSVG from "components/elements/svg/Unit.svelte";
  import XSVG from "components/elements/svg/X.svelte";
  import CheckbookSVG from "components/elements/svg/Checkbook.svelte";
  import FeatureSVG from "components/elements/svg/Feature.svelte";
  import IdeaSVG from "components/elements/svg/Idea.svelte";
  import TaxesSVG from "components/elements/svg/Taxes.svelte";
  import SkillSetSVG from "components/elements/svg/SkillSet.svelte";
  import VaultSVG from "components/elements/svg/Vault.svelte";
  import JudgementSVG from "components/elements/svg/Judgement.svelte";
  import LinkSVG from "components/elements/svg/Link.svelte";
  import DownloadSVG from "components/elements/svg/Download.svelte";
  import TagSVG from "components/elements/svg/Tag.svelte";

  // Props
  export let name;
  export let width = undefined;
  export let height = undefined;
  export let color = undefined;
  // Name of the color from the design library, (e.g. B95)
  export let colorName = undefined;
  export let ref = undefined;

  let IconComponent;
  // Reactively replace icon if name changes for the same component instance
  $: IconComponent = ICONS[name];
</script>

<svelte:component
  this={IconComponent}
  {width}
  {height}
  {ref}
  color={!!colorName ? `var(--color-${colorName})` : color}
/>
