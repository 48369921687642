<script>
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import IconColumn from "components/widgets/IconColumn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
</script>

<PageSection shaded>
  <div class="smart-contracting">
    <div class="columns">
      <div class="column right">
        <Eyebrow>Smart Contracts</Eyebrow>
        <Heading3>Strengthening trust and reducing friction</Heading3>
        <Body>
          A digital asset, or token, is simply one component of a smart
          contract, and is inextricably linked to the details of the contract.
          Our solution embraces this insight and aims to improve the value
          proposition of digital assets through a digital transformation of the
          underlying contracts, and for all parties involved in the contracting
          process.
          <br /><br />
          Our platform also appreciates the fact that contracting is a multiplayer
          activity that requires many people acting in various capacities, and across
          different entities, to work together to draft, review, negotiate, form,
          execute, trade, and administer the rights, duties, and obligations of the
          parties over the contract’s lifecycle.
          <br /><br />
          With those considerations in mind, the Tokenized Platform leverages DLT,
          a digital transformation of contracts, and cutting-edge software to deliver
          a more effective contracting experience, and therefore, more valuable digital
          assets.
        </Body>
      </div>
      <div class=" column left image">
        <ImageFrame
          width={560}
          shaded={false}
          src="/images/connected-icons.svg"
        />
      </div>
    </div>
    <div class="icon-row">
      <div class="icon-column">
        <IconColumn icon="engineering" heading="Programmable contracts">
          Smart contracts can be configured to automate many administrative and
          performance-related tasks. This approach allows for significant cost
          and time savings, as well as the reduction of errors.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="source-of-truth" heading="A single source of truth">
          Every record of consequence is stored immutably on-chain to allow for
          a highly interoperable database that eliminates data silos, errors,
          duplication, and manual handling.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn
          icon="certificate-of-honor"
          heading="Security and utility tokens"
        >
          Support for all types of tokens including the following: complex
          derivative contracts, ordinary shares, debt instruments, carbon
          credits, and reward points.
        </IconColumn>
      </div>
      <div class="icon-column">
        <IconColumn icon="skill-set" heading="Compliance features">
          Every contract has all the features necessary for regulated entities
          to perform regulated functions. This allows for full compliance with
          any laws and regulations, while also enabling more automation.
        </IconColumn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .smart-contracting {
    @extend .generic-section;

    .columns {
      flex-direction: row-reverse;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: column;
      }
    }
  }
</style>
