<script>
  import PageLayout from "components/PageLayout.svelte";
  import SectionHero from "./Sections/Hero.svelte";
  import SmartContracting from "./Sections/SmartContracting.svelte";
  import TokenIssuers from "./Sections/TokenIssuers.svelte";
  import InstantTransfers from "./Sections/InstantTransfers.svelte";
  import MiddleSummary from "./Sections/MiddleSummary.svelte";
  import TreasuryManagement from "./Sections/TreasuryManagement.svelte";
  import RealTimeInsights from "./Sections/RealTimeInsights.svelte";
  import PlatformTour from "../Sections/PlatformTour.svelte";
  import TakeStep from "../Sections/TakeStep.svelte";
</script>

<PageLayout dark={true}>
  <SectionHero />
  <SmartContracting />
  <TokenIssuers />
  <InstantTransfers />
  <MiddleSummary />
  <TreasuryManagement />
  <RealTimeInsights />
  <PlatformTour />
  <TakeStep />
</PageLayout>
