<script>
  import Router from "svelte-spa-router";
  import { PRODUCTION_HOSTNAME } from "./utils/menu";
  import Home from "./components/pages/Home/Home.svelte";
  import Wallet from "./components/pages/Wallet/Wallet.svelte";
  import Contracts from "./components/pages/Contracts/Contracts.svelte";
  import Tokenization from "./components/pages/Tokenization/Tokenization.svelte";
  import About from "./components/pages/About/About.svelte";
  import Compliance from "./components/pages/Compliance/Compliance.svelte";
  import Protocol from "./components/pages/Documents/Protocol.svelte";
  import Legal from "./components/pages/Documents/Legal.svelte";
  import Downloads from "./components/pages/Downloads/Downloads.svelte";
  import DownloadsLBC from "./components/pages/Downloads/DownloadsLBC.svelte";
  import Pricing from "./components/pages/Pricing/Pricing.svelte";

  // Showcase / demo pages
  import ShowcaseButtons from "./components/pages/Showcase/Buttons.svelte";
  import ShowcaseHeadings from "./components/pages/Showcase/Headings.svelte";
  import ShowcaseIcons from "./components/pages/Showcase/Icons.svelte";
  import ShowcaseLinks from "./components/pages/Showcase/Links.svelte";

  // Only render GA tags in production
  const renderAnalytics =
    isProduction && window.location?.hostname === PRODUCTION_HOSTNAME;

  const routes = {
    "/": Home,
    "/platform/wallet/": Wallet,
    "/platform/contracts/": Contracts,
    "/platform/about/": About,
    "/platform/tokenization/": Tokenization,
    "/platform/compliance/": Compliance,
    "/platform/pricing/": Pricing,
    "/download/": Downloads,
    "/download/lbc2023": DownloadsLBC,
    "/download/lbc2023/*": DownloadsLBC,
    "/download/email-link": DownloadsLBC,
    "/protocol/docs/*": Protocol,
    "/legal/*": Legal,

    // Showcase / demo pages
    "/showcase/buttons/": ShowcaseButtons,
    "/showcase/headings/": ShowcaseHeadings,
    "/showcase/links/": ShowcaseLinks,
    "/showcase/icons/": ShowcaseIcons,

    // Default fallback
    "*": Home,
  };
</script>

<div class="root">
  {#if renderAnalytics}
    <!-- Google Tag Manager -->
    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-56M7XFB");
    </script>
    <!-- End Google Tag Manager -->
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-140782849-1"
    ></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-140782849-1");
    </script>

    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-56M7XFB"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      /></noscript
    >
    <!-- End Google Tag Manager (noscript) -->
  {/if}

  <section>
    <div class="content">
      <Router {routes} />
    </div>
  </section>
</div>

<style lang="scss">
  @import "./styles/global.scss";

  .root {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

    > section > div.content {
      display: flex;
      flex-direction: column;
    }
  }
</style>
