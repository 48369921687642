<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
  export let color = undefined;
  color; // Suppress unused color warning; this SVG is pre-colored
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M63.0002 34.4994C63.0002 49.6875 50.6868 61.9995 35.5001 61.9995C20.3127 61.9995 8 49.6875 8 34.4994C8 19.3119 20.3119 7 35.5001 7C50.6875 7 63.0002 19.3119 63.0002 34.4994Z"
    fill="#EEF0F2"
  />
  <path d="M7 13.9999V49.9997H54.9998V13.9999H7Z" fill="white" />
  <path
    d="M39.0001 13.9999H54.9998V49.9997H7V13.9999H39.0001Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M1 47.9995V55.9997H8.99946M60.9997 15.9995V8H52.9994M60.9997 48.9993V56.9995H52.9994M1 16.9999V8.99975H8.99946"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path d="M30.9999 42.9993H13V22.9999H30.9999V42.9993Z" fill="#EEF0F2" />
  <path
    d="M30.9999 42.9993H13V22.9999H30.9999V42.9993Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M21.9999 42.9992H30.9999V38.1273C30.5754 37.7935 26.1774 35.9995 21.9999 35.9995C17.821 35.9995 13.4237 37.7935 13 38.1273V42.9992H21.9999Z"
    fill="#B2D8FA"
  />
  <path
    d="M21.9999 42.9992H30.9999V38.1273C30.5754 37.7935 26.1774 35.9995 21.9999 35.9995C17.821 35.9995 13.4237 37.7935 13 38.1273V42.9992H21.9999Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M22 25.9999C19.7995 25.9999 17.9995 27.8749 17.9995 30.1676V32.8324C17.9995 35.1243 19.7995 36.9993 22 36.9993C24.1997 36.9993 25.9997 35.1243 25.9997 32.8324V30.1676C25.9997 27.8749 24.1997 25.9999 22 25.9999Z"
    fill="white"
  />
  <path
    d="M22 25.9999C19.7995 25.9999 17.9995 27.8749 17.9995 30.1676V32.8324C17.9995 35.1243 19.7995 36.9993 22 36.9993C24.1997 36.9993 25.9997 35.1243 25.9997 32.8324V30.1676C25.9997 27.8749 24.1997 25.9999 22 25.9999Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M36 26.9996H48.9997M36 31.9999H48.9997M36 36.9993H43.9994"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
