<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M57.9981 27.4971C57.9981 41.8555 46.3585 53.4961 31.999 53.4961C17.6406 53.4961 6 41.8555 6 27.4971C6 13.1386 17.6406 1.49805 31.999 1.49805C46.3585 1.49805 57.9981 13.1386 57.9981 27.4971Z"
    fill="#EEF0F2"
  />
  <path
    d="M31.502 10.0781C21.8844 9.80208 14.0027 17.7878 14.0027 27.6784C14.0027 32.7682 16.0926 37.3471 19.4305 40.5589C22.3644 43.3798 25.0023 45.8527 25.0023 49.9966H38.0018C38.0018 45.8527 40.6407 43.3798 43.5736 40.5589C46.9125 37.3471 49.0014 32.7682 49.0014 27.6784C49.0014 17.7878 41.1207 9.80308 31.502 10.0781Z"
    fill="white"
  />
  <path
    d="M38.0018 49.9966C38.0018 45.8527 40.6407 43.3808 43.5736 40.5589C46.9125 37.3471 49.0014 32.7682 49.0014 27.6784C49.0014 17.7878 41.1207 9.80208 31.502 10.0781C21.8844 9.80208 14.0027 17.7878 14.0027 27.6784C14.0027 32.7682 16.0926 37.3471 19.4305 40.5589C22.3644 43.3808 25.0023 45.8527 25.0023 49.9966"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M44.0015 29.997V24.9971H40.6586C40.4456 23.9972 40.1396 23.4482 39.7457 22.7612L42.1085 20.3843L38.5737 16.8424L36.2108 19.2014C35.5162 18.8038 34.774 18.4961 34.0019 18.2854V14.9975H29.0021V18.2854C28.2221 18.4984 27.4811 18.8044 26.7941 19.1974L24.4312 16.8355L20.8964 20.3703L23.2593 22.7612C22.8653 23.4482 22.5593 23.9972 22.3463 24.9971H19.0024V29.997H22.3463C22.5593 30.9969 22.8653 31.4909 23.2593 32.1779L20.8964 34.5258L24.4312 38.0547L26.7941 35.6888C27.4811 36.0817 28.2221 36.3847 29.0021 36.5977V39.9966H34.0019V36.5977C34.7828 36.3847 35.5238 36.0777 36.2108 35.6848L38.5737 38.0477L42.1085 34.5118L39.7457 32.1779C40.1396 31.4909 40.4456 30.9969 40.6586 29.997H44.0015Z"
    fill="#B2D8FA"
  />
  <path
    d="M44.0015 29.997V24.9971H40.6586C40.4456 23.9972 40.1396 23.4482 39.7457 22.7612L42.1085 20.3843L38.5737 16.8424L36.2108 19.2014C35.5162 18.8038 34.774 18.4961 34.0019 18.2854V14.9975H29.0021V18.2854C28.2221 18.4984 27.4811 18.8044 26.7941 19.1974L24.4312 16.8355L20.8964 20.3703L23.2593 22.7612C22.8653 23.4482 22.5593 23.9972 22.3463 24.9971H19.0024V29.997H22.3463C22.5593 30.9969 22.8653 31.4909 23.2593 32.1779L20.8964 34.5258L24.4312 38.0547L26.7941 35.6888C27.4811 36.0817 28.2221 36.3847 29.0021 36.5977V39.9966H34.0019V36.5977C34.7828 36.3847 35.5238 36.0777 36.2108 35.6848L38.5737 38.0477L42.1085 34.5118L39.7457 32.1779C40.1396 31.4909 40.4456 30.9969 40.6586 29.997H44.0015Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M35.0019 27.4972C35.0019 29.4301 33.435 30.9971 31.5021 30.9971C29.5691 30.9971 28.0022 29.4301 28.0022 27.4972C28.0022 25.5643 29.5691 23.9973 31.5021 23.9973C33.435 23.9973 35.0019 25.5643 35.0019 27.4972Z"
    fill="white"
  />
  <path
    d="M37.002 60.9962H35.002L33.0021 62.9961H30.0022L28.0023 60.9962H26.0024M24.0024 52.9965H39.0019M24.0024 56.9963H39.0019M35.002 27.4974C35.002 29.4303 33.4351 30.9973 31.5022 30.9973C29.5692 30.9973 28.0023 29.4303 28.0023 27.4974C28.0023 25.5645 29.5692 23.9975 31.5022 23.9975C33.4351 23.9975 35.002 25.5645 35.002 27.4974Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M51.7603 7.82313L47.1095 12.474M4.36011 35.1901L10.7129 33.4872L4.36011 35.1901ZM11.6928 47.8906L16.3437 43.2398L11.6928 47.8906ZM24.3934 0.490416L26.0963 6.84316L24.3934 0.490416ZM11.6928 7.82313L16.3437 12.474L11.6928 7.82313ZM4.36011 20.5237L10.7129 22.2266L4.36011 20.5237ZM59.093 20.5237L52.7403 22.2266L59.093 20.5237ZM59.093 35.1901L52.7403 33.4872L59.093 35.1901ZM51.7603 47.8906L47.1095 43.2398L51.7603 47.8906ZM39.0598 0.490416L37.3569 6.84316L39.0598 0.490416Z"
    stroke="#A7B0BF"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
