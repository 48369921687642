<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Heading1 from "components/elements/aliases/Heading1.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import ImageFrame from "components/elements/ImageFrame.svelte";
  import { COLOR_PRIMARY, COLOR_ATTENTION } from "buttonStyles";
  import { DOWNLOADS_URL, CONTACT_URL } from "utils/menu";
</script>

<PageSection dark>
  <div class="hero">
    <div class="columns">
      <div class="column">
        <Eyebrow dark>A Smart Contracting Platform</Eyebrow>
        <Heading1 dark>Issue, manage, and trade digital assets</Heading1>
        <Body dark>
          An award-winning all-in-one tokenization solution for institutions,
          government agencies, enterprises, and individuals.
        </Body>
        <div class="buttons">
          <TextButton
            dark
            color={COLOR_ATTENTION}
            class="action-button"
            href={DOWNLOADS_URL}
          >
            Download the app
          </TextButton>
          <TextButton
            dark
            noFill
            color={COLOR_PRIMARY}
            class="action-button"
            href={CONTACT_URL}
          >
            Contact us
          </TextButton>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column image">
        <ImageFrame
          width={1264}
          src="/images/main-ui.webp"
          alt="Activity List"
        />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .hero {
    @extend .generic-section;
    gap: 128px;

    .column {
      width: 100%;
    }

    .buttons {
      gap: 24px;
    }

    @media only screen and (max-width: $size-phablet-max) {
      gap: 80px;
    }
  }
</style>
