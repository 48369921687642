<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M47.9997 25.782C47.9997 39.0374 37.2553 49.7819 23.9999 49.7819C10.7452 49.7819 0 39.0374 0 25.782C0 12.5281 10.7452 1.7821 23.9999 1.7821C37.2553 1.7821 47.9997 12.5281 47.9997 25.782Z"
    fill="#EEF0F2"
  />
  <path
    d="M55.0001 9.78259L62.9995 17.7828V61.7821H26V9.78259H55.0001Z"
    fill="white"
  />
  <path
    d="M40.0004 56.7826H38.9999V54.7824C38.9997 54.2521 38.789 53.7435 38.4141 53.3685C38.0392 52.9934 37.5307 52.7825 37.0004 52.7821H23.0002C22.4698 52.7823 21.9611 52.9931 21.5861 53.3682C21.211 53.7433 21.0002 54.2519 21 54.7824V56.7826H20.0002C19.4699 56.7828 18.9614 56.9935 18.5863 57.3684C18.2113 57.7433 18.0004 58.2518 18 58.7821V61.7821H41.9999V58.7821C41.9995 58.2519 41.7887 57.7436 41.4138 57.3687C41.0389 56.9938 40.5306 56.783 40.0004 56.7826ZM62.9997 18.7825H53.9998V9.78259H55.0003L62.9997 17.7828V18.7825Z"
    fill="#B2D8FA"
  />
  <path
    d="M26 22.7823V9.78259H55.0001L62.9995 17.7828V61.7821H46.0001"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M59.0002 18.7823H54V13.7821"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M33.9422 38.1045L32.5773 36.7823H30.7368L22.9998 29.07V27.2303L21.6896 25.8511C21.5722 25.7306 21.4319 25.6349 21.2769 25.5696C21.122 25.5042 20.9555 25.4706 20.7873 25.4706C20.6192 25.4706 20.4527 25.5042 20.2977 25.5696C20.1428 25.6349 20.0025 25.7306 19.8851 25.8511L13.3841 32.418C12.8756 32.9265 12.8936 33.7995 13.4006 34.308L14.7806 35.7833H16.6196L18.4556 37.6252L5.51965 50.5612C5.27545 50.3177 4.94466 50.1809 4.5998 50.1809C4.25493 50.1809 3.92413 50.3177 3.67992 50.5612L2.21968 52.0214C1.97585 52.2653 1.83887 52.596 1.83887 52.9409C1.83887 53.2858 1.97585 53.6165 2.21968 53.8604L5.97865 57.6201C6.09936 57.741 6.24271 57.8368 6.40048 57.9022C6.55825 57.9676 6.72737 58.0013 6.89816 58.0013C7.06894 58.0013 7.23805 57.9676 7.39582 57.9022C7.5536 57.8368 7.69694 57.741 7.81765 57.6201L9.27788 56.1599C9.39882 56.0392 9.49477 55.8958 9.56023 55.738C9.62569 55.5801 9.65938 55.4109 9.65938 55.24C9.65938 55.0691 9.62569 54.9 9.56023 54.7421C9.49477 54.5843 9.39882 54.4409 9.27788 54.3202L22.2086 41.3902L23.9995 43.1872V45.0262L25.489 46.4062C25.9968 46.9139 26.875 46.9139 27.3828 46.4062L33.9287 39.8887C34.4365 39.3802 34.4507 38.6137 33.943 38.1052"
    fill="#B2D8FA"
  />
  <path
    d="M18.4556 37.6244L5.51966 50.5603C5.27555 50.3166 4.94472 50.1798 4.5998 50.1798C4.25487 50.1798 3.92403 50.3166 3.67993 50.5603L2.21968 52.0206C1.97585 52.2645 1.83887 52.5952 1.83887 52.9401C1.83887 53.2849 1.97585 53.6157 2.21968 53.8596L5.97865 57.6193C6.09936 57.7401 6.24271 57.8359 6.40048 57.9013C6.55825 57.9667 6.72737 58.0004 6.89816 58.0004C7.06895 58.0004 7.23805 57.9667 7.39582 57.9013C7.5536 57.8359 7.69694 57.7401 7.81765 57.6193L9.27789 56.1591C9.39891 56.0384 9.49494 55.895 9.56046 55.7372C9.62598 55.5793 9.6597 55.4101 9.6597 55.2392C9.6597 55.0683 9.62598 54.899 9.56046 54.7412C9.49494 54.5833 9.39891 54.44 9.27789 54.3193L22.2086 41.3894"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M47.9999 53.7825H44.9999M13.4011 34.3066L14.7811 35.7826H16.6216L24.0001 43.1866V45.0255L25.4896 46.4056C25.9973 46.9133 26.8755 46.9133 27.3833 46.4056L33.9293 39.8881C34.437 39.3796 34.4513 38.6131 33.9435 38.1046L32.577 36.7824H30.7373L23.0003 29.0701V27.2304L21.6901 25.8504C21.5726 25.7302 21.4323 25.6346 21.2773 25.5694C21.1224 25.5042 20.956 25.4706 20.7878 25.4706C20.6197 25.4706 20.4533 25.5042 20.2984 25.5694C20.1434 25.6346 20.0031 25.7302 19.8856 25.8504L13.3846 32.4181C12.8761 32.9266 12.8941 33.7981 13.4011 34.3081V34.3066ZM5.50293 50.5448L6.88292 51.9248L5.50293 50.5448ZM23.0003 29.0701L21.1613 30.9076L23.0003 29.0701ZM30.7373 36.7824L28.8983 38.6228L30.7373 36.7824ZM18.0001 34.4026L16.6216 35.7826L18.0001 34.4026ZM25.3793 41.8066L24.0001 43.1866L25.3793 41.8066ZM21.0001 56.7825V54.7823C21.0003 54.2518 21.2111 53.7432 21.5862 53.3681C21.9612 52.993 22.4699 52.7822 23.0003 52.782H37.0005C37.5308 52.7824 38.0393 52.9933 38.4142 53.3684C38.7891 53.7434 38.9998 54.252 39 54.7823V56.7825H21.0001ZM35.0003 56.7825H40.0005C40.5307 56.7829 41.039 56.9937 41.4139 57.3686C41.7888 57.7435 41.9996 58.2518 42 58.782V61.782H18.0001V58.782C18.0005 58.2517 18.2114 57.7432 18.5864 57.3683C18.9615 56.9934 19.47 56.7827 20.0003 56.7825H30H35.0003ZM38.0002 43.7821V46.7821V43.7821ZM43.0005 45.1223L41.5005 47.7203L43.0005 45.1223ZM46.6604 48.7823L44.0624 50.2823L46.6604 48.7823Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
  />
</svg>
