<script>
  import { tick } from "svelte";

  export let label = "";
  export let value;
  export let change = () => {};
  export let className = "";
  export let getDisplayValue = (val) => val;

  let inputElement = undefined;
  let displayInputElement = undefined;
  let focused = false;

  let displayFontSize = 15;
  let displayInputPadding = "6px 8px 6px 12px";
  $: displayValue = getDisplayValue(value);
  $: {
    onDisplayValueChanged(displayValue);
  }

  const focusInput = () => {
    if (inputElement) {
      inputElement.focus();
    }
  };
  const moveCursorToEnd = () => {
    if (!inputElement) {
      return;
    }
    const length = inputElement.value.length;
    inputElement.setSelectionRange(length, length);
  };
  const onFocus = async () => {
    focused = true;
    await tick(); // Let inputs rerender
    focusInput();
    setTimeout(() => {
      moveCursorToEnd();
    }, 10);
  };
  const onBlur = () => {
    focused = false;
  };
  const onLabelClick = () => {
    focusInput();
  };
  const init = (node) => {
    inputElement = node;
  };
  const initDisplay = (node) => {
    displayInputElement = node;
  };
  const onDisplayValueChanged = (value) => {
    const length = String(value).length;
    if (length >= 21) {
      displayFontSize = 9;
      displayInputPadding = "12px 8px 6px 12px";
    } else if (length > 15) {
      displayFontSize = 10;
      displayInputPadding = "11px 8px 6px 12px";
    } else if (length > 11) {
      displayFontSize = 12;
      displayInputPadding = "10px 8px 6px 12px";
    } else {
      displayFontSize = 15;
      displayInputPadding = "6px 8px 6px 12px";
    }
  };
</script>

<div class="input-field">
  {#if focused}
    <input
      type="text"
      class={className}
      bind:value
      on:change
      on:blur={onBlur}
      use:init
    />
  {/if}
  {#if !focused}
    <input
      type="text"
      class={className}
      bind:value={displayValue}
      on:focus={onFocus}
      use:init={initDisplay}
      style={`font-size: ${displayFontSize}px; padding: ${displayInputPadding};`}
    />
    <span on:click={onLabelClick}>{label}</span>
  {/if}
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .input-field {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 36px;
    border: solid 1px $N20;
    border-radius: 4px;
    font-size: 15px;
    gap: 0px;
    span {
      display: flex;
      align-items: center;
      color: $N65;
      font-size: 15px;
      line-height: 24px;
      height: 100%;
      padding-right: 12px;
      cursor: pointer;
    }
    input[type="text"] {
      display: flex;
      border: none;
      background: transparent;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      height: 100%;
      width: 100%;
      text-align: right;
    }
  }
</style>
