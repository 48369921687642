<script>
  export let width = 64;
  export let height = 64;
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M62 28.5003C62 43.6878 49.688 56.0005 34.5005 56.0005C19.3123 56.0005 7.00024 43.6885 7.00024 28.5003C7.00024 13.3128 19.3123 1 34.5005 1C49.688 1 62 13.312 62 28.5003Z"
    fill="#EEF0F2"
  />
  <path d="M57.0005 42.0005H2V12.0005H57.0005V42.0005Z" fill="white" />
  <path
    d="M37.0002 17.001H10.0002V17.0295C10.0002 18.6847 8.65774 20.055 7.00024 20.055V34.02C9.00049 34.02 10.0002 35.3467 10.0002 37.0005H37.0002V17.001Z"
    fill="#B2D8FA"
  />
  <path
    d="M38 42.0005H2V12.0005H37.0002M43.0002 12.0005H57.0005V42.0005H53M14 27.0005H17M44 27.0005H47"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M24.0005 27.0026C24.002 23.6891 26.4665 21.0041 29.504 21.0063C32.5415 21.0071 35.0022 23.6944 34.9992 27.0071C34.997 30.3214 32.5332 33.0056 29.4965 33.0041C26.4582 33.0026 23.9982 30.3154 24.0005 27.0026Z"
    fill="white"
  />
  <path
    d="M24.0005 27.0026C24.002 23.6891 26.4665 21.0041 29.504 21.0063C32.5415 21.0071 35.0022 23.6944 34.9992 27.0071C34.997 30.3214 32.5332 33.0056 29.4965 33.0041C26.4582 33.0026 23.9982 30.3154 24.0005 27.0026V27.0026Z"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M37.0002 17.0007H10.0002C10.0002 17.7964 9.68418 18.5594 9.12157 19.1221C8.55896 19.6847 7.79589 20.0007 7.00024 20.0007V34.0445C9.00049 34.0445 10.0002 35.372 10.0002 37.0295L37.0002 37.0002M43.0002 17.0007H49.0002C49.0002 19.0002 50.3427 20.0592 52.0002 20.0592V27.0005M40.0002 6.00049V10.0002M40.0002 13.0002V17.0007M40.0002 20.0007V24.0005M40.0002 27.0005V31.0002M40.0002 34.0002V38.0007M39.0005 52.0003L48.5 61.5005C48.8283 61.8289 49.218 62.0895 49.647 62.2672C50.076 62.445 50.5358 62.5365 51.0001 62.5365C51.4645 62.5365 51.9243 62.445 52.3532 62.2672C52.7822 62.0895 53.172 61.8289 53.5002 61.5005L54.5 60.5007C54.8284 60.1725 55.089 59.7827 55.2667 59.3537C55.4445 58.9248 55.536 58.465 55.536 58.0006C55.536 57.5363 55.4445 57.0765 55.2667 56.6475C55.089 56.2185 54.8284 55.8288 54.5 55.5005C54.1718 55.1722 53.782 54.9117 53.3531 54.734C52.9242 54.5563 52.4645 54.4649 52.0002 54.4649C51.536 54.4649 51.0763 54.5563 50.6474 54.734C50.2184 54.9117 49.8287 55.1722 49.5005 55.5005L49.0002 56.0007L44 51.0005M36.0005 49.0003L29 56.0007L28.5005 55.5005C28.1722 55.1721 27.7825 54.9115 27.3535 54.7338C26.9245 54.556 26.4647 54.4645 26.0004 54.4645C25.536 54.4645 25.0762 54.556 24.6472 54.7338C24.2183 54.9115 23.8285 55.1721 23.5002 55.5005C23.1718 55.8288 22.9113 56.2185 22.7335 56.6475C22.5558 57.0765 22.4643 57.5363 22.4643 58.0006C22.4643 58.465 22.5558 58.9248 22.7335 59.3537C22.9113 59.7827 23.1718 60.1725 23.5002 60.5007L24.5 61.5005C24.8283 61.8289 25.218 62.0895 25.647 62.2672C26.076 62.445 26.5358 62.5365 27.0001 62.5365C27.4645 62.5365 27.9243 62.445 28.3532 62.2672C28.7822 62.0895 29.172 61.8289 29.5002 61.5005L48.9612 42.0005C50.9322 40.0295 52.0002 37.3565 52.0002 34.5695V30.9222L40.961 42.0005M39.0912 44.9097L36.179 41.9982M34.0002 48.0005L28.0002 42.0005"
    stroke="#1F4A89"
    stroke-width="2"
    stroke-miterlimit="10"
  />
</svg>
