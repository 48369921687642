<script>
  import TokenizedLogo from "components/elements/TokenizedLogo.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import Icon from "components/elements/Icon";
  import MenuContents from "components/widgets/MenuContents.svelte";
  import { COLOR_PRIMARY, COLOR_SECONDARY } from "buttonStyles";
  import { BUTTON_DATA, DOWNLOADS_URL } from "utils/menu";

  export let onMenuClick;

  // References to TextButton objects
  let buttons = [];
  let activeButtonIdx = null;

  function onMenuButtonClick(idx) {
    if (activeButtonIdx === idx) {
      activeButtonIdx = null;
    } else {
      activeButtonIdx = idx;
    }
  }
</script>

<div class="mobile-menu">
  <div class="header columns">
    <div class="logo">
      <TokenizedLogo dark />
    </div>
    <div class="menu-close" on:click={onMenuClick}>
      <Icon name="x" />
    </div>
  </div>
  <div class="content columns">
    <div class="column">
      <div class="top-button">
        <TextButton large dark color={COLOR_PRIMARY} href={DOWNLOADS_URL}>
          Download the app
        </TextButton>
      </div>
      {#each BUTTON_DATA as buttonData, i}
        <div class="menu-button">
          <TextButton
            dark
            noFill
            color={COLOR_SECONDARY}
            onClick={() => onMenuButtonClick(i)}
            bind:this={buttons[i]}
          >
            {buttonData.label}
          </TextButton>
        </div>
        {#if i === activeButtonIdx}
          <div class="expanded-menu">
            <MenuContents {activeButtonIdx} />
          </div>
        {/if}
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .mobile-menu {
    @extend .generic-section;
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    top: 0px;
    left: 0px;
    color: $B95;
    background-color: $I85;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1000;
    cursor: auto;
    overflow-y: auto;

    .header {
      height: 72px;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: row;
      }

      .logo {
        padding-left: 16px;
      }

      .menu-close {
        margin-right: 8px;
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .column {
      padding: 0 8px 0 16px;
      width: 100%;

      .menu-button {
        height: 52px;
        display: flex;
        align-items: center;
        width: 100%;
        :global(.button) {
          height: 100%;
          width: 100%;
          color: $B10;
        }
      }
      .expanded-menu {
        display: flex;
        background-color: $white;
        border-radius: 8px;
        width: 100%;
      }
      .top-button {
        margin-bottom: 24px;
        display: flex;
      }
    }
  }
</style>
