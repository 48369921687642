<script>
  export let width = 24;
  export let height = 24;
  export let color = "#191C1F";
  export let ref = undefined;
</script>

<svg
  {ref}
  {width}
  {height}
  viewBox="0 0 {width} {height}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
    stroke={color}
    stroke-width="1.667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
